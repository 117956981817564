import StudentApi from '@/services/Index';
import AuthApi from '@/services/Auth';

const login = async (user) => {

    return AuthApi.post('/auth/login', user);
}
const loginTwoFactor = async (user) => {

    return AuthApi.post('/auth/twofactor', user);
}

const loginTwoFactorWithAuthenticator = async (user) => {

    return AuthApi.post('/auth/two-factor-with-authenticator', user);
}

const passwordReset = async (formData) => {
    return StudentApi.post('/auth/password/reset/info', formData);
}

const passwordResetWithMobileNumberSms = async (formData) => {
    const form = {
        mobile_tel: formData.mobileNumber,
        email: formData.email,
        identity: formData.identity,
        g_recaptcha_token: formData.g_recaptcha_token
    };
    return StudentApi.post('/auth/password/reset/sms', form);
}
const passwordResetWithMobileNumberSmsVerify = async (formData) => {
    const form = {
        token: formData.token,
        sms_code: formData.sms
    };
    return StudentApi.post('/auth/password/reset/verifysms', form);
}
const passwordResetNewPassword = async (formData) => {
    const form = {
        token: formData.token,
        password: formData.password,
        password_confirmation: formData.passwordConfirmation
    };
    return StudentApi.post('/auth/password', form);
}

const changePassword = async (formData) => {

    return AuthApi.put('/auth/password/change', formData);
}

const getTranslation = async () => {
    return StudentApi.get('/language/lines');
}

const setDefaultRole = async (data) => {

    return AuthApi.post('/auth/set-default-role', data);
}

const refreshToken = async (data) => {

    return AuthApi.post('/auth/refresh', data);
}

const changeTwoFactorStatus = async (data) => {

    return AuthApi.post('/auth/change-two-factor-status', data);
}
const twoFactorStatus = async () => {

    return AuthApi.get('/auth/two-factor-status');
}
const createTwoFactor = async () => {

    return AuthApi.get('/auth/create-two-factor');
}
const confirmTwoFactor = async (data) => {

    return AuthApi.post('/auth/confirm-two-factor', data);
}
const disableTwoFactor = async (data) => {

    return AuthApi.put('/auth/disable-two-factor', data);
}

export default {
    login,
    loginTwoFactor,
    loginTwoFactorWithAuthenticator,
    passwordReset,
    passwordResetWithMobileNumberSms,
    passwordResetWithMobileNumberSmsVerify,
    passwordResetNewPassword,
    changePassword,
    getTranslation,
    setDefaultRole,
    refreshToken,
    changeTwoFactorStatus,
    twoFactorStatus,
    createTwoFactor,
    confirmTwoFactor,
    disableTwoFactor
}
