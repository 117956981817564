const translations = {
    tr: "Türkçe",
    en: "English",
    // A
    admin: "Admin",
    added: "Added",
    archive: "Archive",
    abroad_application: "International Application",
    abroad: "Abroad",
    academic_season_term_applied: "Academic Season-Term Applied",
    absent: "Absent",
    absence_report: "Absence Report",
    academic_unit_office: "Academic Unit Office",
    academic_year: "Academic Year",
    acceptance_data: "Acceptance Data",
    acceptance_date: "Acceptance Date",
    accepttance_not_accepted: "Not Accepted",
    accepttance_status: " Accepttance Status",
    account_code: "Account Code",
    account_holder: "Account Holder",
    account_information: "Account Information",
    account_number: "Account id",
    action: "Action",
    active: "Active",
    active_semester: "Active Semester",
    adaptation_request: "Adaptation Request",
    approve_1: "Approval",
    attendance_detail: "Attendance Detail",
    available_roles: "Available Roles",
    add: "Add",
    add_exam_score: "Add Exam Score",
    add_bank_credit: "Add Student Credit (Deposit) Bank Account",
    add_course: "Add course",
    add_course_transfer: "Add Course Transfer",
    add_discount: "Add Discount",
    add_education_credit: "Add Education Credit",
    add_module: "Add Module",
    add_new_course: "Add New Course",
    add_offset: "Add Offset",
    add_offset_payment: "Add Offset Payment",
    add_option: "Add Option",
    add_payment: "Add Payment",
    add_payment_plan: "Create Payment Plan",
    add_pdf: "Add Pdf",
    add_preference: "Add Preference",
    add_preference1: "Add Preference",
    add_refund: "Add Refund",
    add_scholarship: "Add Scholarship",
    add_slot: "Add Slot",
    added_by: "Added By",
    added_course_transfer: "Added Course Transfer",
    added_date: "Added Date",
    additional_lecturer: "Additional Instructor",
    additional_lecturers: "Additional Instructors",
    address: "Address",
    address_2: "Full Address",
    address_en: "Address (EN)",
    address_information: "Address Information",
    address_update: "Update your Address",
    advance_payment: "Advance Payment",
    advisor: "Advisor",
    advisor_id: "Advisor ID",
    advisor_name: "Advisor's Name",
    advisor_of_project: "Project Advisor",
    advisor_of_thesis: "Thesis Advisor",
    advisor_surname: "Advisor's Surname",
    advisored_of_students: "Advisored Of Students",
    affiliated_curriculum: "Affiliated Curriculum",
    already_available: "Already Available",
    all: "All",
    all_exam_scores: "All Exam Scores",
    allow_classroom_overlap: "Allow Classroom Overlapping",
    allow_instructor_overlap: "Allow Instructor Overlapping",
    allow: "Allow",
    alumni: "Alumni",
    amount: "Amount",
    amount_paid: "Amount paid",
    amount_tl: "Amount (TL)",
    amount_to_be_paid: "Amount to be Paid",
    and: "and",
    api_config: "Api Config",
    application_conditions: "Application Conditions",
    application_completion_status: "Application Completion Status",
    application_date: "Date of Application",
    application_follow_link: "Application Follow Link",
    application_information: "Application Information",
    application_new: "Application New",
    application_period: "Period of Application ",
    application_rules: "Application Rules",
    application_status: "Application Status",
    application_status_update: "Application Status Update",
    application_status_reset_are_you_sure: "Are You Sure You Want to Reset Application Status??",
    application_delete: "Application Delete",
    application_approve: "Application Approve",
    application_send: "Application Send",
    application_send_waiting: "Waiting for You to Submit Application",
    application_success: "Application Success",
    application_success_info: "Application Success İnfo",
    application_summary: "Application Summary",
    acceptance_letter_id: "Acceptance Letter Id ",
    application_system: "Application System",
    application_type: "Application Type",
    application_types: "Application Types",
    application_update_success: "Application Update Success",
    application_withdraw_display: "Application Withdraw Display",
    applications: "Applications",
    application_status_reset: "Reset Application",
    application: "Application",
    application_result: {
        placed: "Placed",
        other_preference_placed: "Other Preference Placed",
        not_placed: "Not Placed",
    },
    applied_faculty: "Applied Faculty",
    applied_program: "Applied Program",
    apply: "Apply",
    apply_cash_discount: "Apply Cash Discount",
    apply_scholarship: "Apply for Scholarship",
    approval_phase: "Approval Phase",
    approval_procedures: "Approval Procedures",
    approval_registration_succes_msg:
        "Online registration procedure successfully completed.",
    approval_status: "Approval Status",
    approve: "Approve",
    approve_or_reject_confirm: "Approve or Reject (Confirmation Modal)?",
    approve_step: "Approve Step",
    approve_step_error: "You must enter a approve step",
    approval_template: "Approval Step Template",
    approved: "Approved",
    approved_go: "Send for approvel",
    approved_by: "Approved by",
    approved_date: "Approved Date",
    approvers: "Approvers",
    approver: "Approver",
    are_you_sure_to_apply: "Are you sure to apply",
    are_you_sure_to_cancel: "Are you sure you want to cancel?",
    are_you_sure_to_restore_preference: "Are you sure you want to reset preference?",
    are_you_sure_to_create_a_new_proforma_invoice:
        "Are you sure to create a new proforma invoice",
    are_you_sure_to_delete: "Are you sure you want to delete?",
    are_you_sure_to_give_exceptional_payment_approval:
        "Are you sure to give exceptional payment approval?",
    are_you_sure_to_move: "Are you sure to move",
    are_you_sure_to_remove_exceptional_payment_approval:
        "Are you sure to remove exceptional payment approval?",
    are_you_sure_to_send: "Are you sure to send",
    are_you_sure_to_withdraw:
        "Are you sure that you want to withdraw from the course?",
    are_you_sure_want_save: "Are you sure want save",
    are_you_sure_you_want_to_take_the_x_course:
        "Are you sure you want to take the course '{course_name}'-'{course_code}'?",
    are_you_sure_you_want_to_update_the_approval_status:
        "Are you sure you want to update your approval status?",
    are_you_sure_want_course_language: "Are you sure you want to take the course language {0}",
    asal: "Department of Recruitment",
    asal_delay_reasons: "Asal Delay Reasons",
    asal_maximum_time_reasons: "Asal Maximum Time Reasons",
    asal_type: "Asal Type",
    asynchronous: "Asynchronous",
    at_which_unit: "At Which Unit",
    attendance_hours: "Attendance Hours",
    attendance_date: "The Date Attendance is Taken",
    attendance_list: "Attendance List",
    attendance_report: "Attendance Report",
    attendees: "Attendees",
    attention: "Attention",
    attention_for_thesis_and_project:
        "During the Course Registration Period you must register for the course. Thesis 1-Seminar/Project/Proficiency displayed on the Course Selection Webpage",
    authorizations: "Authorizations",
    are_you_sure_make_application: "Are you sure you want to submit your application??",
    autumn: "Fall/Autumn",
    available_hours: "Available Hours",
    average_system_100: "Average System 100",
    average_system_4: "Average System 4",
    attended: "Present",
    application_images: "Application Images",

    // B
    back: "Back",
    bahcesehir_university: "Bahcesehir University",
    bahcesehir_accommodation_and_tourism: "Bahcesehir Accommodation and Tourism Investments Inc.",
    balance: "Balance",
    balance_overflow: "Insufficient balance",
    bank: "Bank",
    bank_additional_installment: "Bank Additional Installment",
    bank_credit: "Bank Credit",
    bank_credit_cash: "Bank Credit Cash",
    bank_id: "Bank Name",
    bank_name: "Name of Bank",
    batch_gno_calculation:
        "Calculation of Institutional Cumulative Grade Point Average",
    batch_update_due_date: "Batch Update Due Date ",
    bau_grade_conversion: "Bau Grade Conversion",
    bau_grades: "Bau Grades",
    bau_language_exam_info: "Bau Language Exam İnfo",
    bau_language_exam_question: "Bau Language Exam Questions",
    before_leave_of_absence: "Have you taken leave of absence at school?",
    birthdate: "Birthdate",
    birthplace: "Birthplace",
    board_decision: "Board Decision",
    board_decisions: "Board Decisions",
    board_members: "Board Members",
    board_types: "Board Types",
    bonus_course_credit_limit: "Bonus Course Credit Limit",
    branch_name: "Branch Name",
    branch_type: "Branch Type",
    browse: "Browse",
    building: "Buildings",
    building_id: "Building Id",
    bulk_document_creation: "Bulk Document Creation",
    bulk_graduation: "Bulk Graduation",

    // C
    calculate: "Calculate",
    calculated_cgpa: "Calculated Cgpa",
    calculated_gpa: "Calculated Gpa",
    campus: "Campuses",
    campus_address: "Campus Address",
    campus_id: "Campus Id",
    campus_name: "Campus Name",
    can_approvers: "Authorized Approvers",
    can_be_repeated: "Can it be repeated?",
    can_take_same_semester: "Can be taken in the same semester",
    can_not_empty_classrooms: "Classroom list can not be empty.",
    cancel: "Cancel",
    cancel_application: "Cancel Application",
    cancel_to_move: "Cancel To Move",
    cancellation: "Cancellation",
    cancellation_amount: "Cancellation_Amount",
    cancelled: "Cancelled",
    cannot_overflow_balance: "Cannot Overflow Balance",
    capacity: "Capacity",
    card_holder_address: "Cardholder Address",
    card_holder_name: "Cardholder Name",
    card_holder_phone: "Cardholder Phone",
    card_name: "Card Name",
    card_name_en: "Card Name (EN)",
    card_no: "Card Number",
    card_number: "Card Number",
    card_owner: "Card Owner",
    card_status: "Card Status",
    card_type: "Card Type",
    cash: "Cash",
    cash_collection_report: "Cash Collection Report",
    cash_discount_rate: "Cash Discount Rate",
    cash_in_advance: "Cash in Advance",
    cash_or_installed: "Cash / Installed",
    cash_payment: "Cash Payment",
    cash_payment_type: "Cash Payment Type",
    category: "Category",
    cert_type: "Certificate Type",
    certificate: "Certificate",
    certificate_id: "Certificate İd",
    certificate_upload: "Please upload the digital copy of your certificate below",
    certificate_information: "Certificate İnformation",
    certificate_name: "Certificate Name",
    certificate_name_en: "Certificate Name En",
    certificateshow: "Certificateshow",
    cgpa: "CGPA",
    gpa_control_report: "GNO Control Report",
    change: "Change",
    change_your_password: "Change Your Password",
    change_price: "Change Price",
    change_section: "Change Section",
    change_student_group_radio: "Change Student Group",
    changes_will_be_saved:
        "The changes will be saved. Do you confirm the Procees?",
    check: "Check",
    check_drawer: "Check Drawer",
    check_it: "Verify",
    check_it_title: "Check İt Title",
    check_no: "Check No",
    check_payment_approval: "Verify Payment Approval",
    choose_student_type_confirm_text: "Select New Student Registeration",
    chose_your_double_major: "Chose Your Double Major",
    cities: "Cities",
    city: "City",
    city_id: "City",
    city_name: "City Name",
    clarification_text: "Clarification Text",
    class: "Class",
    classrate: "Percentile in Class",
    class_applied: "Class Applied",
    class_settings: "Class Settings",
    class_x: "%{class}st Class",
    classroom: "Classroom",
    classroom_list: "Classroom List",
    classroom_: "Classroom_",
    classroom_id: "Classroom ID",
    classroom_name: "Classroom Name",
    classroom_programs: "Classroom Programs",
    classroom_types: "Classroom Types",
    classrooms: "Classrooms",
    classroom_not_selected: "Classroom Not Selected",
    clear: "Clear",
    click_to_choose: "Click To Choose",
    close: "Close",
    close_edit_at: "Close Edit At",
    close_lessons_only: "Close Courses Only",
    code: "Code",
    coefficient: "BAU Grade Coefficient",
    collection_bank: "Collection Bank",
    collection_report: "Collection Report",
    collection_screen: "Collection Screen",
    collective_consultant_appointment: "Collective Consultant Appointment",
    columns: "Columns",
    comment: "Comment",
    comment_questions: "Comment Questions",
    comment_answers: "Comment Answers",
    communication_address: "Contact Address",
    compass_dates: "Compass Dates",
    company_name: "Company Name",
    company_name_en: "Company Name (EN)",
    complaint_petition: "Complaint Petition",
    complete_registration: "Complete your Registration",
    completed_akts_credit: "Completed ECTS Credits",
    completed_credit: "Completed Credits",
    completed_ects_credit: "Completed Ects Credit",
    completed_slots: "Completed Slots",
    completed_required_course_count: "Completed Required Course Count",
    completed_required_ects_credit: "Completed Required Ects Credit",
    completed_required_credit: "Completed Required Credit",
    completed_gep_course_count: "Completed GEP Course Count",
    completed_gep_ects_credit: "Completed GEP Ects Count",
    completed_gep_credit: "Completed GEP Credit",
    completed_dep_course_count: "Completed DEP Course Count",
    completed_dep_ects_credit: "Completed DEP Ects Count",
    completed_dep_credit: "Completed DEP Course Credit",
    completed_non_dep_course_count: "Completed NON DEP Course Count",
    completed_non_dep_ects_credit: "Completed NON DEP Ects Count",
    completed_non_dep_credit: "Completed NON DEP Course Credit",
    confirm: "Confirm",
    confirmation: "Confirmation",
    conflict_informations: "Conflict İnformation",
    constants: "Constants",
    contact: "Contact",
    contact_address_information: "Contact Address Information",
    contact_information: "Contact Information",
    contact_information_reports: "Contact İnformation Reports",
    contact_number: "Contact Number",
    contact_support: "Contact Support",
    course_drop_process: "Course Drop Process",
    contents: "Contents",
    continue: "Continue",
    contract_type: "Contract Type",
    coopcertificate: "Coopcertificate",
    coopstudentcertificate: "Coopstudentcertificate",
    coop_and_public_scholarship: "COOP and Public Scholarship",
    coop_scholarship: "COOP Scholarship",
    course_selection_credit_limit: "Course Selection Credit Limit",
    count: "Count",
    count_number: "Sequence Number",

    countries: "Countries",
    country: "Country",
    country_code: "Country Code",
    country_id: "Country",
    course: "Course",
    course_import_text:
        "Code, Name, Name EN,Name TR, Credit, Practical Credit, Teoric Credit, Lab Credit, Ects Credit, Type, Language, Faculty Code, Department Code, Program Code,Active Status,Grading Scale",
    course_merge_section: "Course Merge",
    course_code: "Course Code",
    course_coordinator: "Course Coordinator",
    course_count_to_take: "Course Count To Take",
    course_detail_title: "Course Information",
    course_field_is_required: "Course Field Is Required",
    course_grade_entry_report: "Course Based Grade Entry Report",
    course_id: "Course Id",
    course_language: "Language of Instruction",
    course_name: "Course Name",
    course_pool_err: "The Course is available in the Elective Pool",
    course_price: "Course Price",
    course_registration: "Course Registration",
    course_registration_semester: "Course Registration Period",
    course_schedule: "Course Schedule",
    course_schedule_format: "Course Code",
    course_schedule_from_draft: "Course_Schedule_From_Draft",
    course_schedule_will_be_created_from_draft:
        "Course Schedule Will Be Created From Draft",
    course_status: "Course Status",
    course_transfer: "Course Transfer",
    course_transfer_guide: "Course Transfer Guide",
    course_transfer_evaluation: "Course Transfer Evaluation",
    course_transfer_success_text: "Your Course Transfer Request  Success Text",
    course_transfer_verify_text: "Course Transfer Verify Text",
    course_transfers_form: "Application Form for Course Transfer",
    course_type: "Course Type",
    courseprerequisites: "Course prerequisites",
    courses: "Courses",
    courses_you_have_taken_so_for: "Courses You Have Taken So For",
    course_and_slot_logs: "Course and Slot Logs",
    create: "Create",
    create_classes: "Create Classes",
    create_document: "Create_Document",
    create_pilotage_fee: "Create Pilotage Fee",
    create_proforma_invoice: "Create Proforma İnvoice",
    created_at: "Created At",
    created_by: "Created By",
    creating_fykk_template: "Creating FYKK Template",
    credit: "Credit",
    course_code1: "Course Code",
    credit_card: "Credit Card",
    credit_card_info: "Credit Card Info",
    credit_card_name_surname: "Name on Credit Card",
    credit_card_number: "Credit Card Number",
    credit_limit: "Credit Limit",
    credit_price: "Credit Price",
    credit_x: "{credit} Credit(s)",
    credits_attempted: "Credits Attempted",
    credits_earned: "Credits Earned",
    current_password: "Current Password",
    currency: "(Unit of) Currency",
    currency_rate: "Currency_Rate",
    current_section: "Current Section",
    curriculum: "Curriculum",
    curriculums: "Curriculums",
    curriculum_credits: "Curriculum Credits",
    curriculum_ects_credit: "Curriculum Ects Credit",
    customer_group: "Customer Group",
    customer_type: "Customer Type",
    cut_rate: "Cut Rate",

    // D
    dormitory_application_form: "Dormitory Application Form",
    documents_approved: "Documents Approved",
    documents_waiting: "Documents Waiting",
    document_upload: "Document Upload",
    document_select: "Document Select",
    dashboard: "Dashboard",
    data: "Data",
    data_from_system_control: "Data From System Control",
    date_info: "Date Information",
    date: "Date",
    date_between: "Date Range",
    time_between: "Time Range",
    date_of_birth: "Date of Birth",
    day: "Day",
    day_interval: "Day Interval",
    dean: "Dean",
    dean_name: "Name of Dean",
    dean_title: "Title of Dean",
    dean_title_en: "Title of Dean",
    debt: "Debt",
    decision: "Decision",
    decision_date: "Decision Date",
    decision_number: "Decision Number",
    decline: "Reject",
    decline_reason: " Reason for Rejection",
    declined: "Declined",
    definitive_registration_information: "Final Registration Information",
    degree_of_proximity: "Degree of Proximity",
    delay: "Delay",
    delay_1: "Delay",
    delete: "Delete",
    delete_all: "Tümünü sil",
    delete_all_students: "Delete All Students",
    default_role_changed: "Default role changed",
    dep_course_count: "Dep Course Count",
    dep_course_credit: "Dep Course Credit",
    dep_course_ects_credit: "Dep Course Ects Credit",
    department: "Department",
    department_code: "Department Code",
    department_codes: "Department_Codes",
    department_name: "Department Name",
    departments: "Departments",
    detail: "Detail",
    details: "Details",
    Diploma: "Diploma",
    diploma_annexes: "Diploma Eki",
    diploma_date: "Diploma Date",
    diploma_information: "Diploma Information",
    diploma_name: "Diploma Name",
    diploma_name_en: "Diploma Name (EN)",
    diploma_number: "Diploma Number",
    diploma_number_from_system: "Get Diploma Number from System",
    diploma_point: "Diploma Grade",
    diploma_status: "Diploma Status",
    diploma_supplement: "Diploma Supplement",
    diploma_title: "Diploma Title",
    diploma_title_en: "Diploma Title (EN)",
    disability_rate: "Disability Rate",
    disability_type: "Type of Disability",
    disciplinary_committee_decision: "Disciplinary Committe Decision",
    discipline_follow: "Discipline Follow",
    discipline_process: "Discipline Process",
    discipline_registry: "Discipline Registry Document",
    disciplinary_action: "Student has disciplinary action.",
    discount: "Discount",
    discount_amount: "Discount Amount",
    discount_rate: "Discount Rate",
    dispatch_postponement_certificate: "Dispatch Postponement Certificate",
    display_systems: "Display Systems",
    distance_sales_agreement: "Distance Sales Agreement",
    district: "District",
    district_code: "District Code",
    district_name: "District Name",
    districts: "Districts",
    dgs: "DGS",
    dgs_e: "DGS EK",
    make_application: "Make an Application",
    do_cancel: "Cancel",
    do_payment: "Do_Payment",
    do_withdraw: "Withdraw",
    do_you_have_language_proficiency:
        "Do you have language proficiency certificate?",
    do_you_have_language_proficiency_missing:
        "Request Exemption from English Preparatory Program",
    do_you_verify_email: "Would you like to update your e-mail?",
    do_you_verify_mobile_no:
        "Would you like to update your mobile phone number?",
    do_you_want_to_close_the_form_with_the_selected_interview_result:
        "Do you want to close the form with the Selected Interview Result?",
    do_you_want_to_complete_registration:
        "Do you want to complete registration?",
    do_you_want_to_print_receipt: "Do you want to print the receipt?",
    document: "Document",
    document_add: "Add Document",
    document_duae_date: "Date to Bring the Document",
    document_due_date: "Document Due Date",
    document_edit: "Edit Document",
    document_is_deleted: "Document is Deleted",
    document_number: "Document Number",
    document_request: "Document Request",
    document_request_cancel_statement:
        "Your Document Request Will Be Canceled! Do you confirm?",
    document_request_title: "Document Request Title",
    document_requests_title: "Document Requests Title",
    document_settings: "Document Settings",
    document_show: "Show Document",
    document_status: "Document Status",
    document_type: "Document Type",
    document_type_id: "ID",
    document_type_name: "Document Name",
    documents: "Documents",
    documents_are_required: "Documents are required!",
    documents_upload_pending: "You can continue your application by uploading documents",
    documents_university: "The university to which the documents belong",
    domestic_application: "Domestic Application",
    double_major_application: "Double Major Application",
    double_major_application_quotas: "Double Major Application Quotas",
    double_major_applications: "Double Major Applications",
    double_major_application_start: "Double Major Application Start",
    download: "Download",
    download_data: "Download Data",
    download_photos: "Download Photos",
    download_report: "Download Report",
    download_result_file: "Download Result File",
    draft_info: "Draft Information",
    drop_file: "Drop File Here",
    due_date: "Due Date",
    due_date_end: "Due Date End",
    due_date_start: "Due Date Start",
    duration: "Duration",
    document_not_found: "Document Not Found",
    delete_file_upload_again: "Delete the file and upload it again",
    document_images: {
        favicon: " Application Icon",
        "document-logo": "Document Logo",
        background: "Login Page Background",
        logo: "Login Page Logo",
        "menu-logo": "Menu Logo",
    },

    // E
    except_roles: "Except Roles",
    enable_editing: "Enable editing",
    close_editing: "Close Edit",
    exempt: "Exempt",
    earned_degree: "Earned Degree",
    e_registration_system: "Online Registration System",
    e_registration_system_info: "* If you have a Leave Of Absence request, your request will be created when you complete your registration. You can check your request status from the 'Leave Of Absence' tab in the 'My Requests' application by logging into UMIS with your user name and password.",
    early_discount_rate: "Early Discount Rate",
    ebys_send: "EBYS Send",
    ebys_send_statement:
        "The request will be sent to the EBYS system! Do you confirm the transaction?",
    ebys_type_id: "EBYS Type Document ID",
    ects: "ECTS",
    ects_credit: "ECTS Credit",
    ects_credit_of_uncompleted_slots: "ECTS Credit of Uncompleted Slots",
    ects_credits_attempted: "ECTS Credits Attempted",
    ects_credits_earned: "ECTS Credits Earned",
    edit: "Edit",
    edit_classrooms: "Edit Classrooms",
    edit_due_date: "Edit Due Date",
    edit_grade_conversion: "Edit Grade Conversiom",
    edit_option: "Edit Option",
    edit_payment: "Edit Payment",
    edit_preference: "Edit Preference",
    edit_question: "Edit Question",
    edit_section: "Edit Section",
    edit_slot: "Edit Slot",
    education: "Education",
    exam_report: "Exam Report",
    exam_report_all_section: "Exam Report (All Section)",
    exam_report_list: "Exam Report List",
    education_and_exam_info: "Education And Exam İnfo",
    education_and_exam_reqired: "Education And Exam Regired",
    education_credit: "Education Credit",
    education_delete_info:
        "You are about to delete the selected Education Information. Do you want to continue processing?",
    education_info: "Education Information",
    education_information: "Education Information",
    education_line_cancel: "Click to remove from Education Information.",
    education_status: "Education Status",
    educator_report: "Educator Report",
    effect_gpa: " Does it Effect the CGPA?",
    effect_ratio: "Effect Ratio",
    elective_pool: "Elective Pool",
    elective_pools_course_delete_confirm_text:
        "Elective Pools Course Delete Confirm_Text",
    elective_pools_delete_confirm_text: "Elective Pools Delete Confirm Text",
    elective_pools_form: "(Form for) Adding Elective Pool Courses ",
    elective_pools_info: "Elective Pool Information",
    elective_pools_report: "Elective Pools Report",
    elective_pools_store_confirm_text: "Elective Pools Store Confirm Text",
    elective_pools_title: "Elective Pool",
    elective_pools_update: "Elective Pools Update",
    email: "E-Mail",
    email_address: "E-Mail Address",
    email_of_advisor: "E-mail Of Advisor",
    email_placeholder: "Enter Your E-Mail Address",
    email_update: "Update E-Mail",
    email_verify_not: "Verify your E-Mail Address!",
    email_send: "E-Mail Send",
    emergency_contact_txt: "Person to Contact in Emergency",
    emergency_person: "Emergency Contact Person's Name",
    emergency_person_phone: "Emergency Contact Person's Phone Number",
    empty_classrooms: "Empty Classrooms",
    empty_data_on_table: "Fill the empty fields in the Table!",
    end: "End",
    end_date: "End Date",
    end_time: "End Time",
    endorse: "Endorse",
    english: "English",
    enter_attendance: "Enter Attendance",
    enter_explanation: "Enter Explanation",
    enter_value: "Enter Value",
    entry_document: "Entry Document",
    entry_score: "Entry Score",
    entry_section: "Entry Section",
    epayment: "Online payment",
    epayments_report: "Online Payments Report",
    online_payment_agreement: "Online Payment Agreement",
    error: "Error",
    error_min_0_max_100: "The value you enter must be between 0-100.",
    equal_weight_score: "Equally Weighted",
    equivalence_status: "Equivalence Status",
    evaluation: "Evaluation",
    evaluation_type: "Exam ",
    event_owner: "Etkinlik Sahibi",
    event_type: "Etkinlik Türü",
    exam: "Sınav",
    exam_application_start_date: "Application Start Date for Exam",
    exam_application_to_date: "Application Deadline for Exam",
    exam_averages: "Exam Averages",
    exam_capacity: "Exam Capacity",
    exam_date: "Exam Date",
    exam_delete_info:
        "You are about to delete the selected exam Information. Do you want to continue processing?",
    exam_effect_ratio: "Exam Effect Ratio",
    exam_info: "Exam İnfo",
    exam_line_cancel: "Click to remove from Exam Information.",
    exam_method: "Exam Method",
    exam_name: "Exam Name",
    exam_parts: "Parts of the Exam",
    exam_schedule1: "Exam Schedule",
    exam_schedule: "Exam Schedule",
    exam_schedule_id: "Exam Schedule ID",
    exam_schedule_format: "Course Code",
    exam_schedule_available_time:
        "Finding Available Time for the Exam Schedule",
    exam_score: "Exam Score",
    exam_status_report: "Exam_Status_Report",
    exam_type: "Exam Type",
    exam_time: "Exam Time",
    exams_to_take: "Exams to take",
    exams: "Exams",
    excel: "Excel",
    excel_file: "Excel File",
    excel_format: "Excel Format",
    excluded_program_codes: "Excluded Program Codes",
    excluded_registration_types: "Excluded Registration Types",
    excuse_file: "Excuse File",
    excuse_letter: "Excuse_Letter",
    expand_all: "Expand All",
    expiry_month: "Expiry_Month",
    expiry_year: "Expiry_Year",
    explanation: "Explanation",
    explanation_en: "Explanation En ",
    extend: "Extended",
    external_application: "Interagency",
    extra_installment: "Extra Installment",
    extra_time: "Extra Time",
    exam_overlap_report: "Sınav Çakışma Raporu",
    error_student_status_not_contiune:
        "You cannot log in to this page because student status is not ongoing.",

    // F
    first_password_get: "Get Your First Password",
    faculties: "Faculties",
    faculty: "Faculty",
    faculty_grade_entry_report: "Faculty Based Grade Entry Report",
    faculty_course_grade_entry_report:
        "Faculty Course Based Grade Entry Report",
    faculty_code: "Faculty Code",
    faculty_code_name: "Faculty Code / Name",
    faculty_name: "Faculty Name",
    faculty_name_en: "Faculty Name (EN)",
    faculty_of_choice: "Faculty Of Choice",
    faculty_rank: "Faculty Ranking",
    failed_data_count: "Failed Data Count",
    fall: "Fall",
    fall_and_spring: "Fall and Spring",
    fall_credit: "Fall Credit",
    false: "False",
    family: "Family",
    family_info: "Family Information",
    family_information: "Family Information",
    fast_transactions: "Fast Transactions",
    father: "Father",
    father_gsm: "Father's Gsm",
    father_job: "Father's Job",
    father_name: "Father Name",
    father_name_placeholder: "Enter father's name",
    fathers_education: "Father's Educational Status",
    fathers_gsm: "Father's Gsm",
    fathers_job: "Father's Job",
    fees_and_installments: "Fees and Installments",
    female: "Female",
    fetch_document: "Fetch Document",
    fields_are_reqired: "Fill out all Fields marked (as) Required",
    file: "File",
    file_name_course_schedule: "Course Schedule",
    foreign_student_community_internship_assignment: "Foreign Student Community Internship Assignment",
    file_name_dispatch_postponemen: "dispatch-postponemen",
    file_name_diploma: "Diploma File",
    file_name_diploma_annexes: "Diploma Annexes",
    file_name_english_placement_exam: "english-placement-exam",
    file_name_exam_schedule: "exam_-chedule",
    file_name_language_proficiency: "language-proficiency",
    file_name_military_status: "military-status",
    file_name_new_password: "new-password",
    file_name_prep_transcript: "prep-transcript",
    file_name_school_certificate: "school-certificate",
    file_name_student_certificate: "student-certificate",
    file_name_student_information_system: "student-information-system",
    file_name_transcript: "transcript",
    file_name_user_guide: "user-guide",
    file_name_welcome_letter: "welcome-letter",
    file_upload_not_1: "Send English Proficiency Certificates to",
    file_upload_not_2: "Students who wish to freeze registration?",

    file_upload_not_3:
        "<a>ekayit@bau.edu.tr</a> adresinden iletişime geçebilirsiniz.",
    files: "Files",
    fill_at_least_one_field: "Fill at least one field",
    filter1: "Filter",
    filter: "Filter",
    filter_type: "Filter Type",
    filter_value: "Filter Value",
    final_letter_grade: "Final Letter Grade",
    finalize_the_application: "Finalize Application",
    finance_offset: "Finance Offset",
    finance_reports: "Finance Reports",
    find: "Find",
    find_student: "Find Student",
    finished_class: "Finished Class",
    first_installment: "First İnstallment",
    first_student_count: "Number of First-Time / New Students",
    force_education_price: "Force Education Price",
    foreign_document_number: "Foreign Identification Number",
    foreign_document_type: "Foreign Document Type",
    foreign_status: "Foreign Status",
    foreign_student: "International Student",
    foreign_student_credentials: "International Student Credentials",
    foreign_student_identity: "International Student ID",
    forgot_my_password: "I Forgot My Password",
    friday: "Friday",
    from_score: "From Score",
    from_email: "From E-Mail",
    full_name: "Full Name",
    full_scholarship_students_enroll: "Full Scholarship Students Enroll",
    future_payment: "Postponing Payment Due Date",
    fykk_template: "Fykk_Template",

    // G
    gateway: "Gateway",
    gender: "Gender",
    general: "General",
    general_information: "General Information",
    gep_course_count: "Gep Course Count",
    gep_course_credit: "Gep Course Credit",
    gep_course_ects_credit: "Gep Course Ects Credit",
    get_report: "Get Report",
    give_exception_payment_approval: "Give Exceptional Payment Approval",
    giveable_roles: "Giveable Roles",
    gna_not_range: "CGPA Grade Range",
    gno: "CGPA",
    gno_simulation: "GNO Simulation",
    gno_carculate: "Calculate CGPA",
    gpa: "GPA",
    go: "Go",
    grade: "Grade",
    grade_conversion_define_tabel: "Grade Conversion Define Tabel",
    grade_conversion_define_title: "Grade Conversion Define Title",
    grade_conversion_id: "Grade Conversion Id",
    grade_conversion_info: "Grade Conversion Info",
    grade_conversion_name: "Grade Conversion Name",
    grade_conversion_range_end: "Grade Conversion Range End",
    grade_conversion_range_start: "Grade Conversion Range Start",
    grade_conversion_table_title: "Grade Conversion Table Title",
    grade_conversion_update_title: "Grade Conversion Update Title",
    grade_conversion_update_verify_text: "Grade Conversion Update Verify Text",
    grade_conversion_verify_text: "Grade Conversion Verify Text",
    grade_conversion_verify_text_2: "Grade Conversion Verify Text 2",
    grade_entry: "Grade Entry",
    grade_entry_confirmation_modal: "Grade Entry Confirmation Modal",
    grade_id: "Grade İd",
    grade_is_deleted_text: "Grade Is Deleted Text",
    grade_scala: "Grading Scale",
    grade_table_is_empty: "Grade Table Is Empty",
    grade_up_lessons: "Courses to Up Grade CGPA",
    grades: "Grades",
    grading_system: "Grading System",
    grading_system_id: "Grading System ID",
    graduate: "Graduate",
    graduate_application_after_verify_info:
        "Başvurunuzun herhangi bir sebeple yarım kalması halinde tarafınıza gönderilen SMS Kodu ile <a href=login>Başvuru Güncelleme</a> adresinden başvurunuza devam edebilirsiniz.",
    graduate_application_form: "Graduate Application Form",
    graduate_application_form_update_btn:
        "Graduate Application Form Update Btn",
    graduate_application_yok_statement:
        "(Candidates who graduated from universities abroad can upload their YÖK Equivalency Certificates from the other documents section.)",
    graduate_applications: "Graduate Applications",
    graduate_date: "Graduate Date",
    graduate_semester: "Graduate Semester",
    graduate_detail_cpga: "Detailed CGPA",
    graduation_academic_year: "Graduation (Academic) Year",
    graduation_average: "Graduation Average",
    graduation_date: "Graduation Date",
    graduation_date_between: "Graduation Date Range",
    graduation_decision_date: "Graduation Decision Date",
    graduation_decision_number: "Graduation Decision Number",
    graduation_degree: "Graduation Degree",
    graduation_information: "Graduation Information",
    graduation_semester: "Graduation Semester",
    graduation_status: "Graduation Status Check",
    graduation_university: "Graduation University",
    graduation_year: "Graduation Year",
    gross_price: "Gross Price",
    gsm_no: "Gsm No",
    guarantee1: "Guarantee",
    guarantee1_address: "Guarantee Adress",
    guarantee1_national_id: "TC Identity Number of Guarantee",
    guarantee1_phone: "Guarantee Phone",
    guarantee2: "Guarantee",
    guarantee2_address: "Guarantee Adres",
    guarantee2_national_id: "TC Identity Number of Guarantee",
    guarantee2_phone: "Guarantee Phone",
    guarantee: "Guarantee",
    guarantee_address: " Address of Guarantee",
    guarantee_national_id: "TC Identity Number of Guarantee",
    guarantee_phone: "Kefil Phone",
    guide_code: "Guide Code",
    guide: "Guide",

    // H
    have_student_program:
        "There is a student registration. Do not process here.",
    has_english_cert:
        "Will you apply for exemption from english preparatory program?",
    have_payment_approval: "Payment Approval",
    have_you_leave_of_absence_before: "Taken leave of absence before?",
    head_of_department: "Head of Department",
    head_of_program: "Coordinator",
    health: "Health",
    health_information: "Health Information",
    hello: "Hello",
    help: "Help",
    help_videos: "Help Videos",
    hes_code: "HES Code",
    home_address: "Home Address",
    home_number: "Home Phone Number",
    home_tel: "Home Phone",
    horizontal_or_vertical_attended_university:
        "Lateral / Vertical Transfer from Previously Attended University",
    horizontal_or_vertical_attended_university_1:
        "Lateral / Vertical Transfer from Previously Attended University",
    horizontal_or_vertical_coming_from_university:
        "Lateral / Vertical Transfer to the University",
    horizontal_or_vertical_coming_from_university_1:
        "Lateral / Vertical Transfer to the University",
    hour: "Hour",
    hours: "Hours",
    hybrid: "Hybrid",
    high_honor: "High Honor Process",
    high_honor_status: "Honor Status",


    insufficient_limit: "Insufficient limit",
    iban: "IBAN number",
    id: "ID",
    identity: "Identity",
    identify: "TC or Passport Number",
    identity_information: "Identity Information",
    image_upload: "Image Upload",
    import: "Import",
    import_button: "Import Button",
    import_course_schedule: "Import Course Schedule",
    import_draft: "Import Draft",
    import_exam_schedule: "Import Exam Schedule",
    import_photos: "Import Photos",
    in_class: "Face-to-Face / In Class",
    included_registration_types: "Included Registration Types",
    indefinite: "Indefinite",
    info_file: "Information File",
    info_link: "Information Link",
    info_text: "Info Text",
    information: "Information",
    input_type: "Input Type",
    installment: "Installment",
    installment_amount: "Installment Amount",
    installment_count: "Number of Installments",
    installment_options: "Installment Options",
    installment_payment: "Installment Payment",
    installment_payment_type: "Installment Payment Type",
    installment_start_date: "Installment Start Date",
    installments: "Installments",
    installments_total: "Sum of Total Installments",
    instructor: "Instructor",
    instructor_can_use: "Can be used by Instrutors",
    instructor_courses_title: "Instructor Courses Title",
    instructor_name: "Name of Instructor",
    instructor_surname: "Surname of Instructor",
    instructors: "Instructors",
    instructor_letter_grade_excel_export:
        "Instructor Letter Grade Excel Report",
    intership_information: "Intership Information",
    invalid_course_coordinator: "Course Coordinator not found",
    invalid_student: "Student not found",
    invalid_value: "Invalid Value",
    invalid_values: "Invalid Values",
    invalid_national_id: "Invalid national id",
    invert: "Invert",
    investigation_report: "Investigation Report",
    invitation_to_the_defense: "Invitation To The Defense",
    invoice1: "Invoice 1",
    invoice2: "Invoice 2",
    invoice3: "Invoice 3",
    invoice4: "Invoice 4",
    invoices: "Invoices",
    irregular: "Irregular",
    irregular_status: "Irregular Status",
    is_before_undergraduate_transfer:
        "Have you made a horizontal transfer before?",
    is_before_undergraduate_transfer_type:
        "What type of horizontal transition did you do?",
    is_default: "Is it default?",
    is_graduate: "Is it graduate?",
    is_numeric: "Is it numeric?",
    is_paid: "Has it been Paid?",
    is_passing: "Is Passing",
    is_rate: "Is it Rate",
    is_required: "Is it Required?",
    is_revert: "Is it revert ?",
    is_there_a_header: "Is there a Header/Heading",
    is_take_exam: "Is Take Exam",
    is_exam_time_lesson: "Is Exam Time Lesson",
    is_additional_classroom: "Is Additional Classroom",

    // J
    job: "Job",
    job_address: "Job Address",
    job_and_contact_info: "Job Contact İnfo",
    job_corporation: "Job Corporation",
    job_department: "Job department",
    job_experience: "Job experience",
    job_experience_desc:
        "(Varsa önceki iş deneyiminiz ile birlikte toplam süre olarak belirtiniz.)",
    job_info: "Job İnfo ",
    job_other: "Job Other",
    job_sector: "Job Sector",
    job_tel: "Job Phone",
    jobs: "Jobs",
    joining_place: "Joining Place",

    // K
    key: "Key",
    kvkk_modal_title: 'Clarification Text Regarding Processing of Personal Data',
    kyk_information: "KYK Information",
    kvkk_data_approve: "I have read, understood and <strong> accepted the text of the protection of personal data </strong> and express consent.",
    kvkk_content_text: `The purpose of this text is to enlighten you as to how the Data Controller,  ‘Bahçeşehir Accommodation and Tourism Investments Incorporation’/’Bahçeşehir Konaklama ve Turizm Yatırımları Anonim Şirketi’ (“Bahçeşehir Konaklama” or "Şirket")/(“Bahçeşehir Accommodation” or " Company/Incorporation ") processes your personal data.  Subjects regarding the processing of your Personal Data are listed below, under the relevant headings.

    For what purposes and on what legal grounds is my personal data being processed?
    Articles 5 and 6 of the Personal Data Protection Law  (“Kanun”/ “the Law”) establish the legal grounds  for processing of Personal Data. Below we have stated for what purposes we  are processing your personal data and the respective legal grounds for the processes:<br>
    Your personal data are processed in view of the purposes stated below, on the legal grounds that processing personal data is mandatory, in order for the Company to perform its legal obligations as explicitly foreseen in the Laws<br>
    •	Performance of our Company’s legal obligations, upon the request of authorized institutions or organizations or due to our obligations to make the necessary notifications to such institutions (for example, fulfilling the obligation for identity notification regarding the students staying at the Dormitory, within the context of the  Identity Notification Law)
    •	Performance of legal obligations, including invoicing,<br>
    Your personal data are processed for the following purposes, on the legal grounds that processing of personal data belonging to the parties of a contract is necessary, provided that it is directly related to the conclusion or fulfilment of that contract.<br>
    •	Execution of the assignment and admittance procedures of students to the Dormitory,
    •	Establishment of your record for the delivery of accommodation services and meeting our liabilities     arising from the service delivery agreement.,
    •	Collection of dormitory accommodation service  fees<br>
    Your personal data are processed for the following purposes, on the legal grounds that personal data processing is mandatory for the establishment, exercise or protection of any rights.<br>
    •	Protection and exercise of our rights in case of any legal disputes, and execution of legal and disciplinary  procedures.<br>
    Your personal data are processed for the following purposes, on the legal grounds  that  it is mandatory for the legitimate interests of the Company, provided that such processing shall not violate the fundamental rights and freedoms of the data subject.<br>
    •	Implementation of the communication processes; receiving and resolving requests and complaints
    •	Maintaining the security of our dormitories and services; preventing unauthorized  entry and exits,
    •	Ensuring compliance with Company policies and procedures; execution of auditing and management activities.<br>
    Your personal data are processed for the purposes stated below, on condition that you have given explicit consent:<br>
    •	Information regarding personal criminal record and personal health status;
    •	Realizing health and safety controls of students utilizing the accommodation services, administering the assignment and admission procedures of students to the Dormitories and informing the authorized persons, institutions and  organizations.<br>
    Which of my Personal Data are Processed?<br>
    In line with the foregoing purposes, we as ‘Bahçeşehir Accommodation’, process your personal information regarding your identity, communication channels , educational background, accommodation, financial status, information regarding your family, friends and persons to contact in case of emergency situations,  information regarding their residence permits for international students, as well as personal health and criminal record data.
    To whom and for what purposes are my personal data being transferred?<br>
    We share your personal data with our business partners/associates, suppliers and authorized persons, institutions or organizations, in order to realize the purposes under the heading ,“For what purposes and on what legal grounds are my personal data being processed?”, on the legal grounds stated in Articles 5 and 6 of the Law, within the context of the of the rules specified in Articles 8 and 9 of the Law, regarding transfer of personal data.
    How and on what legal grounds are my personal data being collected?<br>
    We collect your personal data by means the following channels and methods<br>
    <strong>Channels for collecting your personal data:</strong> Filling out forms or sharing your personal information with us verbally, in writing or on digital media; your personal data are gathered from the relevant  channels, when you contact us via your phone, e-mail or social media accounts.<br>
    <strong>Methods for collecting your personal data:</strong>  Your personal data are collected partly by  automated means; automated or non-automated means provided that they are part of the data  recording system.

    We are gathering your Personal Data on the basis of the terms specified in Articles 5 and 6 of the Law,  regarding the processing of personal data; on the legal grounds that processing of personal data belonging to the parties of a contract is necessary, provided that it is directly related to the conclusion or fulfilment of that contract; on the legal grounds that personal data processing is mandatory for the establishment, exercise or protection of any rights; on the legal grounds  that  it is mandatory for the legitimate interests of the Company, provided that such processing shall not violate the fundamental rights and freedoms of the data subject; on the legal grounds that processing personal data is mandatory, in order for the Company to perform its legal obligations as explicitly foreseen in the Laws, and we have your explicit consent on condition that you have given explicit consent.


    As the Personal Data Subject, How Can I Exercise my rights?
    Article 11 of the Law establishes the rights of the natural persons whose personal data are being processed. Pursuant to Article 11 of the Law, you are entitled to the following rights:<br>
    •	To learn whether or not your personal data is being processed,
    •	To request further information regarding your personal data that is being processed,
    •	To learn for what purpose your personal data is being processed and whether or not your personal data is being used in line with its intended purpose,
    •	To know the third parties in your country or abroad to whom your personal data has been transferred,
    •	To request rectification of any incomplete or inaccurate processing of your personal data, and to request notification of the third parties to whom your personal data has been transferred, about the corrective actions taken within this context.
    •	To request erasure or destruction of your processed Personal Data upon disappearance of the reasons which require the process, despite being processed under the provisions of the Law; and to request notification of the operations carried out in compliance with this to the third parties to whom your personal data has been transferred.
    •	To object to the occurrence of an unfavourable consequence on your part, due to analysis of your processed personal data by means of exclusively automatic systems,
    •	To request compensation for losses incurred due to unlawful processing of your  personal data<br>
    To communicate your requests regarding your rights, you may apply to the Data Controller by sending a mail to the following address: Abbasağa Mah. Ihlamur Yıldız Cad. No. 8/1A Beşiktaş/İstanbul. You may also use the methods referred to in the “Communiqué on Principles and Procedures for Application to Data Controller 2018”


    Explicit Consent Text Regarding Processing of Personal Data<br>
    In the event that any one of the legal reasons referred to in the “Law” for  processing of your Personal Data does not exist, we as Bahçeşehir Accommodation and Tourism Investments Incorporation (“Bahçeşehir Accommodation), are asking whether we have your explicit consent for processing your personal data or not.
    If you wish to express your Explicit Consent so that we can execute the following personal data  processing procedures, please sign the respective spaces below the texts.  You can withdraw the Explicit Consent you have given at any time, by getting in contact with us.<br>
    <strong>1.</strong>	I hereby express my consent to the processing of my personal health data by Bahçeşehir Accommodation and sharing it with authorized institutions and organizations, for the purposes of realizing health and safety controls of students utilizing the accommodation services, administering the assignment and admission procedures of students to the Dormitories and informing  authorized persons, institutions and  organizations.

    <strong>2.</strong>	I hereby express my consent to the processing of my personal health data by Bahçeşehir Accommodation and sharing it with authorized institutions and organizations, for the purposes of realizing health and safety controls of students utilizing the accommodation services, administering the assignment and admission procedures of students to the Dormitories and informing  authorized persons, institutions and  organizations.`,
    // L
    login_details: "Login Details",
    last_message: "Last message",
    lab: "Lab",
    lab_credit: "Laboratory  (Credits)",
    last_publication_date: "Last Publication Date",
    lab_section: "Lab Section",
    language: "Language",
    language_exam_score: "Language Exam Score",
    language_proficiency_certificate_en: "English Language Proficiency Certificate",
    language_proficiency_certificate_tr: "Turkish Language Proficiency Certificate",
    last_approve_date: "Last Approve Date",
    last_course: "Course Previously Taken",
    last_course_registration_semester: "Last Course Registration Semester",
    last_course_registration_year: "Last Course Registration Year",
    last_education_status: "Education Status",
    last_lesson_registration_year: "Last Lesson Registration Year",
    last_process_date: "Last Process Date",
    last_school: "Last School",
    last_school_date_of_graduation: "Last School Date Of Graduation",
    last_school_diploma_number: "Last School Diploma Number",
    last_school_diploma_point: "Last School Diploma Point",
    last_school_registration_date: "Last School Registration Date",
    last_university: "Last University",
    last_update_date: "Last Day for Applications",
    leave_absences: "Leave of Absence",
    leave_of_absence: "Leave Of Absence",
    leave_of_absence_count: "Number of Semesters with Leave of Absence ",
    leave_of_absence_fall: "Leave of Absence  in Fall Semester",
    leave_of_absence_period: "Leave Of Absence Period",
    leave_of_absence_reason: "Reason for Leave of Absence",
    leave_of_absence_description: "Description for Leave of Absence",
    leave_of_absence_semesters: "  Leave Of Absence Semesters",
    leave_of_absence_settings_title: "Leave of Absence Settings",
    leave_of_absence_spring: "Leave Of Absence Spring",
    leave_of_absence_title: "Leave of Absence Request Form",
    leave_of_absence_verify_text: "Leave Of Absence Verify Text",
    leave_of_absence_verify_text_graduate:
        "Leave Of Absence Verify Text for Graduate",
    leave_with_consent: "Leave With Consent",
    leave_with_consent_form: "Leave With Consent Form",
    leave_with_consent_reason: "Leave With Consent Reason",
    leave_with_consent_statement1: "Leave With Consent Statement",
    leave_with_consent_statement1_content: "????",
    leave_of_city: "The city where you currently live",
    leave_of_district: "The district where you currently live",
    line_number: "{number}. line: ",
    language_text: "Have you taken a Turkish Language Certificate before?",

    leave_of_absence_statement:
        "Do you want to close the form with the selected interview result?",
    leave_with_consent_verify_text: "Leave With Consent Verify Text",
    leaving_academic_year: "Leaving Academic Year",
    leaving_date: "Leaving Date",
    leaving_date_between: "Leaving Date Between",
    leaving_information: "Leaving Information",
    leaving_semester: "Leaving Semester",
    lecturer_report: "Lecturer Report",
    lesson_pool: "Elective Course Pool",
    lesson_time: "Class Hours",
    lesson_timetable: "Course Schedule",
    lessons_only_and_delete_students: "Close Courses and Delete Students",
    lessons_only_and_delete_students_and_sms_send:
        "Close Courses Delete Students and Send SMS",
    lessons_we_have_not_taken_before:
        "Courses (You Have ) Not Taken Previously",
    lessons_you_have_taken: "Courses (You Have) Not Completed Successfully",
    letter_grade: "Letter Grade",
    level: "Level",
    list: "List",
    list_of_student_taking_the_course: "List of Students Taking the Course",
    loading: "Loading",
    log_detail: "Log File Details",
    log_id: "Log_İd",
    log_message: "Log File Message",
    log_name: "Log File Name",
    log_status: "Log Status",
    log_time: "Log Time",
    log_type: "Log Type",
    login: "Login",
    logout: "Logout",
    language_key: {
        tr: "Turkish",
        en: "English"
    },

    // M
    money_order: "Money order",
    mail_order: "Mail Order",
    major_type: "Major Type",
    major_types: "Types of Major",
    make_payment: "Make Payment",
    midterm: "Midterm Exam",
    final: "Final Exam",
    makeup: "MakeUp Exam",
    make_up_exam: "MakeUp Exams",
    make_up_exam_entry: "MakeUp Exam Enrty",
    make_up_additional_student_list: "List of Additional Students to Take Resit Exams",
    male: "Male",
    mass_course_closure: "Mass Course Closure",
    mass_absence_update: "Mass Absence Update",
    mass_absence_chose: 'Mass Polling Selection',
    major_major_dismissal_report: "Major Major Dismissal Report",
    max_capacity: "Max Capacity",
    max_duration: "Maximum Duration",
    medicine_group_code: "Medicine Group Code",
    medicine_groups_title: "Faculty of Medicine Exhange Student Groups",
    medicinegroup: "Medical Groups",
    medicines: "Medicine Taken Regularly if any",
    meeting_duration: "Duration of Meeting",
    meeting_number: "Meeting Number",
    meeting_place: "Meeting Place",
    meetings: "Meetings",
    member_type: "Member Type",
    menu: "Menu",
    menu_items: "Menu Items",
    message: "Message",
    merge_section_deleted: "Merge Section Deleted",
    midterm_and_final_data_collection: "Midterm And Final Data Collection",
    military: "Military Service",
    military_branch: "Military Service Branch Office",
    military_branch_number: "Military Service Branch Office Number",
    military_delay_date: "Military Service Postponement Date",
    military_information: "Military Service Information",
    military_office: "Military Service Recruiting Office",
    military_service_city: "City Code / Name",
    military_service_city_code: " City Code",
    military_status: "Military Service Status",
    military_status_document: "Military Service Status Document",
    min_pass_count: "Minimum Pass Count",
    min_point: "Minimum Score",
    min_rank: "Minimum Rank",
    minor_application: "Minor Application",
    minor_branch_dismissal_report_for_not_registering_for_courses: "minor branch dismissal report for not registering for courses",
    major_graduate_minor_branch_dismissal_report: "Major graduate minor branch dismissal report",
    minor_application_start: "Start your Minor Application",
    minor_application_quotas: "Minor Application Quotas",
    minor_applications: "Minor Applications",
    minor_programs: "",
    minor_certificate: "Minor Certificate",
    minor_certificate_ebys_send: "Yandal Sertifika EBYS Send",
    minute: "Minute",
    missing_document: "Missing Document",
    missing_documents: "Missing Documents",
    missing_documents_report: "Missing Documents Report",
    mobile_number: "Mobile Number",
    mobile_number_update: "Mobile Number Update",
    mobile_placeholder: "Enter Mobile Phone",
    mobile_verify_not: "Please Verify Mobile Number",
    mode_of_study: "Mode of Study",
    module: "Module",
    module_id: "Module (ID)",
    monday: "Monday",
    month: "Month",
    mother: "Mother",
    mother_gsm: "Mother's Gsm",
    mother_job: "Mother's Job",
    mother_name: "Mother Name",
    mothers_education: "Mother's Educational Status",
    mothers_gsm: "Mother's Gsm",
    mothers_job: "Mother's Job",
    move: "Move",
    move_here: "Move Here",
    move_payment: "Move Payment",
    move_payment_plan: "Move Payment Plan",
    multiple_choice: "Multiple Choice",
    multiselect_deselect: "Press enter to remove",
    multiselect_select: "Press enter to select",
    multiselect_selected: "Selected",
    my_course_schedule: "My Course Schedule",
    my_jobs: "My Jobs",
    my_make_up_exams: "My MakeUp Exams",
    my_requests: "My Requests",
    my_courses: "My Courses",
    my_invoices: "My Invoices",
    message_send: "Message Send",
    will_take_make_up_exams: "Will Take MakeUp Exams",

    // N
    not_verified: "Could not be verified",
    not_placed: "Not Placed",
    not_completed_undergraduate: "You were not considered because you did not complete your application.",
    nationality_type: "Nationality Type",
    number_characters_entered: "Number of characters you entered",
    n_options_selected: "{n} options selected",
    name: "Name",
    name_en: "Name (EN)",
    name_placeholder: "Enter Your Name",
    name_surname: "Name Surname",
    national_credit: "National Credit",
    national_credit_of_uncompleted_slots:
        "National Credit of Uncompleted Slots",
    national_id: "National ID",
    national_id_passport_no: "National ID",
    national_id_placeholder: "Enter Your National ID",
    national_number: "TC ID Number",
    nationality: "Nationality",
    nationality_option: "Nationality Option",
    necessity: "Necessity",
    neighborhood: "Neighborhood",
    new: "New",
    new_draft: "New Draft",
    new_exam: "New Exam",
    new_option: "New Option",
    new_password: "New Password",
    new_password_repeat: "Repeat- New Password",
    new_payment_collection: "New Payment Collection",
    new_request: "New Request",
    new_question: "New Question",
    new_register: "New Student Registeration",
    new_section: "New Section",
    new_status_radio: "Assign New Status ",
    new_window: "New Window",
    next: "Next",
    no: "No",
    no_lessons_to_be_added: "No Lessons To Be Added",
    no_course: "No Course",
    no_grades_entered: "Grades Not Entered",
    no_installment_message: "No Installment Message",
    no_options: "Option not found.",
    no_payment: "Please Make the Payment!",
    no_result: "No record found.",
    no_result_1: "Not Found",
    no_result_2: "Record not found",
    no_info_found: "No Information Found",
    no_theoretical_course_selection_has_been_made:
        "No theoretical course selection has been made",
    no_practical_course_selection_has_been_made:
        "No practical course selection has been made",
    non_dep_course_count: "Non Dep Course Count",
    non_dep_course_credit: "Non Dep Course Credit",
    non_dep_course_ects_credit: "Non Dep Course Ects Credit",
    none: "None",
    not_allow_attendance_updates: "Not allow attendence Updates",
    not_have_payment_approval: "Payment Not Approved",
    not_include_calculation:
        "Number of Exams not included in (GPA) Calculations",
    not_required: "Not Required",
    not_required_documents: "The documents indicated with (*) must be uploaded.",
    not_taken: "Not Taken",
    not_uploaded: "Yüklenmedi",
    not_selected: "Not Selected",
    note: "Grade",
    note_for_document: "Note",
    note_you_want_to_add: "Note you want to add",
    notes: "Grades",
    notexcredit: "Grade x Credit",
    note_area: "Not Area",
    not_attended: "Absent",
    now_job: "Now Job",
    number: "Number",
    number_of_courses_taken: "Number of Courses Taken",
    number_of_done_exams: "Number of Exams Taken",
    number_of_exams: "Number of Exams",
    number_of_installments: "Number of Installments",
    number_of_leave_of_absence:
        "Number of Semesters Leave of Absence was taken",
    number_of_observers: "Number of Exam Observers / Invigilators",
    number_of_promissory: "Number of Promissory Notes",
    number_of_records_updated: "Number of Records Updated",
    number_of_registered_courses: "Number of Registered Courses",
    number_of_remaining_semesters: "Number of Remaining Semesters",
    number_of_semesters:
        "Number of Semesters the student was enrolled in the program",
    number_of_semesters_studied: "Number of Semesters Studied",
    number_of_students_report: "Number of Students Report",
    number_of_students: "Number of Students",
    number_of_students_by_status_report: "Number of Students Report by Status",
    numerical_score: "Numerical Score",
    new_advisor_staff: "New Advisor Staff",

    // O
    operation: "Operation",
    online_payment_read_and_approve: "I have read the <strong> online payment agreement</strong>, I understand, I accept.",
    objectives: "Objectives",
    oauth_confirmation_text: "{client} wants to login with your UMIS account.",
    observers: " Exam Observers",
    office: "Office",
    offset_amount: "Offset_Amount",
    offset_date: "Offset Date",
    offset_total: "Offset Total",
    offsets_detail: "Offsets Detail",
    ok: "Ok",
    old_name: "Old Name",
    old_name_if_exists: "Old Name if Any",
    ombudsman: "Ombudsman",
    one_time_payment: "One Time Payment",
    online: "Online",
    online_credit_card: "Online Credit Card",
    online_reg_not_1: "Applied only to students placed with OSYM Exam score.",
    online_reg_not_2: "In case of a technical problem during online registration",
    online_reg_not_3: "contact via e-mail",
    online_reg_system: "Online Registration System",
    online_payment_text: `The dormitory fees are charged over a period of 9 months starting from October and ending in June, entailing the fall-spring semesters:

    1)	To those students who prefer to quit the dormitory before the starting date of the courses as announced in the Academic Calender of the University, 10 % of the dormitory fee is deducted and the rest is refunded.

    2)	To those students who started their first year after attaining a passing grade from the proficiency examination of the School of Foreign Languages of the University, a full refund is provided in case of their registering to the dormitory prior to the  examination.

    3)	In the event that students who have successfully passed the English Proficiency Exam given by the University’s School of Foreign Languages in the interim period wish to move out of the Dormitory, they  shall be charged the sum of the monthly accommodation service fees for the services delivered in the preceding months (including the current month) plus 30% of the monthly accommodation service fees for the rest of the contracted months; that is they shall be refunded 70% of the monthly accommodation service fees for the rest of the contracted months. The Service Delivery Contract is terminated.

    4)	For those who leave after the starting date of the courses; a refund is made available  after deducting the total fee of the months for which accommodation service is delivered, the full amount of the current month and 30% of the annual accommodation fee for the remaining months.

    5)	Students who will attend the 5th Module (Summer School) at the School of Foreign Languages will be charged an additional fee of 1 (one) month if they prefer to stay in the dormitory in July.

    6)	Fee refunds are deposited to the bank account that the student has declared within one month from the date of submitting his/her petition regarding the refund request. Approval of the Dormitory Management is sought by applying to the Dormitory Management with the approved Dormitory Dismissal Form and the petition regarding the refund request.`,
    open_parameter: "Properties of Parameters",
    option: "Option",
    order: "Order",
    ordered: "Ordered",
    unordered: "Unordered",
    order_of_preference: "Order of Preference",
    osymDocCode: "????",
    osymExamCode: "????",
    osym_address_information: "OSYM Address Information",
    osym_code: "OSYM Code",
    osym_information: "OSYM Information",
    osym_placement_document: "OSYM Placement Document",
    osym_result_document: "OSYM Result Document",
    osym_scholarship: "OSYM Scholarship",
    osym_score_information: "OSYM Score Information",
    osym_data: "OSYM Data",
    osymquota: "OSYM Quota",
    osym: "OSYM",
    other: "Other",
    other_identies: "Other Identies",
    other_information: "Other Information",
    other_payments: "Other Payments",
    other_programs: "Other Programs",
    other_university: "Other Universty",
    outputs: "Outputs",

    // Q
    question: "Question",
    questioning: "Questioning",
    questions: "Questions",
    quota: "Quota",
    quota_management: "Quota Management",
    quota_type: "Quota Type",
    quota_type2: "Quota Type",

    // P
    pathname: "Pathname",
    preference: "Preference",
    preference_delete: "Preference deleted",
    preference_add: "Preference add",
    price_edit: "Edit Prices",
    price_add: "Add Price",
    placed: "Placed",
    place_student: "Place Student",
    place_student_are_you_sure: "Are you sure you want to pre-register the student?",
    proficiency_exams: "Proficiency Exams",
    page: "Page",
    page_not_found: "Page Not Found!",
    paid: "Paid",
    parameter: "Parameter",
    parameter_id: "Parameter Id",
    parameter_items: "Parameter Items",
    parent_id: "Parent Id",
    parts: "Parts",
    passive: "Passive",
    passport_country: "Passport_Country",
    passport: "Pasaport",
    passport_number: "Passport Number",
    password: "Password",
    payment_waiting: "Payment Waiting",
    payment_success: "Payment Success",
    password_contains_eight_characters_message:
        "Password Contains Eight Characters Message",
    password_contains_number_message: "Password Contains Number Message",
    password_contains_uppercase_message: "Password Contains Uppercase Message",
    password_contains_lowercase_message: "Password Contains Lowercase Message",
    password_contains_special_character_message:
        "Password Contains Special Character Message",
    password_send_success: "Your password has been sent successfully.",
    password_equals_message: "Password Equals Message",
    comment_question: "Comment Answer",
    pay: "Pay",
    pay_type: "Payment Type",
    payment_type1: "Payment Type",
    payer: "Payer",
    payer_address: "Address of Payer",
    payer_national_id: "TC Identity Number of Payer",
    payer_phone: "Payer Phone",
    payment: "Payment",
    payment_amount: "Payment Amount",
    payment_approval: "Payment Approval",
    payment_approval_exceptions: "Payment Approval Exceptions",
    payment_approval_failed: "Payment Approval Failed",
    payment_approval_successful: "Payment Approval Successful",
    payment_approvals: "Payment Approvals",
    payment_date: "Date of Payment",
    payment_date_start: "Payment Date Start",
    payment_date_end: "Payment Date End",
    payment_info: "Payment Information",
    payment_info_update: "Update Payment Information",
    payment_method: "Payment Method",
    payment_plan: "Payment Plan",
    payment_plan_create_btn: "Create Payment Plan",
    payment_plan_title: "Payment Plans ",
    payment_plan_types: "Payment Plan Type",
    payment_plans: "Payment Plans",
    payment_plans_report: "Payment Plans Report",
    payment_scholarship_information: "Payment / Scholarship Information",
    payment_status: "Payment Status",
    payment_status_E: "There is",
    payment_status_H: "There is no",
    payment_total: "Amount to be Paid",
    payment_type: "Payment Type",
    payment_types: "Payment Types",
    payment_type_group: "Payment Type Group",
    payments_saved: "Payments Saved",
    payment_types_obj: {
        money_order: "Money order",
        credit_card: "Credit card",
    },
    payments_report: "Payments Report",
    pending: "Pending",
    period: "Period",
    period_code: "Period Code",
    period_id: "Period Name",
    period_status: "Period/Semester Status",
    period_x: "(Fall / Spring) Semester",
    periods: "Periods",
    permission: "Permission",
    permission_id: "Process (id)",
    permission_information: "Permission Information",
    personal_email: "Personal E-Mail",
    personal_info: "Personal Details",
    personal_information: "Personal Information",
    ph_programs_info: "(*) For Doctoral Programs.",
    photo: "Photo",
    pilotage_collection_report: "Pilotage Collection Report",
    pilotage_invoices: "Pilotage Invoices",
    pin_code: "Pin Code",
    pin: "Pin",
    place_added: "Place Added",
    placement_exam: "Placement ",
    placement_exam_classroom_delete_confirm_text:
        "Placement Exam Classroom Delete Confirm Text",
    placement_exam_classroom_store_confirm_text:
        "Placement Exam Classroom Store Confirm Text",
    placement_exam_classrooms_define: "Placement Exam Classrooms Define",
    placement_exam_classrooms_info: "Placement Exam Classrooms Info",
    placement_exam_classrooms_ranking: "Placement Exam Classrooms Ranking",
    placement_exam_classrooms_ranking_success:
        "Placement Exam Classrooms Ranking Success",
    placement_exam_classrooms_title: "Placement Exam Classrooms",
    placement_exam_part_classroom_delete_confirm_text:
        "Placement Exam Part Classroom Delete Confirm Text",
    placement_exam_part_classroom_store_confirm_text:
        "Placement Exam Part Classroom Store Confirm Text",
    placement_exam_part_classrooms_define:
        " Classroom Assignment for Placement Exam Part",
    placement_exam_part_classrooms_ranking:
        "Classroom Rankings for Placement Exam Part",
    placement_exam_part_classrooms_ranking_success:
        "Placement Exam Part Classrooms Ranking Success",
    placement_exam_part_classrooms_title:
        "Placement_Exam_Part_Classrooms_Title",
    placement_exam_part_classrooms_update:
        "Placement Exam Part Classrooms Update",
    placement_exam_part_classrooms_update_info:
        "Placement Exam Part Classrooms Update Info",
    placement_exam_score_upload_info: "Placement Exam Score Upload Info",
    placement_exam_score_upload_title: "Placement Exam Score Upload Title",
    placement_exams: "Placement  Exams",
    placement_rank: "Placement Ranking",
    placement_student: "Placement Student",
    program_accepted: "You have been accepted into the program",
    program_spare: "Spare",
    program_other_placed: "You settled on your other preference",
    placement_student_result: "Yerleştirme Sonuçları",
    placement_score: "Placement_Score",
    placement_success_order: "Placement with respect to Success Rankings",
    please_try_again: "Please Try Again",
    point: "Grade Weight",
    point_type: "Type of Score",
    points: "Points",
    pool: "Pool",
    pool_name: "Pool Name",
    pool_name_en: "Pool Name (EN)",
    pool_type: "Pool Type",
    postal_code: "Postal Code",
    practical: "Practical",
    practical_credit: "Practical Credit",
    precondition: "Prerequisite",
    preference_information: "Preference Information",
    preference_procedures: "Preference Procedures",
    preference_restore_success: "Preference Reset",
    preference_please_add: "Add your preferences",
    preferences: "Preference",
    preferences_delete: "Delete Preference",
    preferences_edit: "Preferences Edit",
    preference_restore: "Preference Restore",
    preferences_order_change: "Change order of preference",
    preferred_program: "Preferred Program",
    prefers: "Prefers",
    preliminary_information_form: "Preliminary Information Form",
    prep_academic_year: "Academic Year of the English Prep Program",
    prep_attendance: "Prep Attendance Entry",
    prep_attendance_confirmation_modal: "Prep Attendance Confirmation Modal",
    prep_attendance_publish_modal: "Prep Attendance Publish Modal",
    prep_classes_define_form: "Prep Classes Define Form",
    prep_classes_delete_confirm_text: "Prep Classes Delete Confirm Text",
    prep_classes_store_confirm_text: "Prep Classes Store Confirm Text",
    prep_classes_title: "Prep Classes",
    prep_classes_update_confirm_text:
        "You are about to update the Prep Class registration, do you confirm the action??",
    prep_classes_update_form: "Prep Classes Update Form",
    prep_classes_update_info: "Prep Classes Information",
    prep_exam_confirmation_modal: "Prep Exam Confirmation Modal",
    prep_exam_definitions: "Prep Exam Definitions",
    prep_exam_publish_modal: "Prep Exam Publish Modal",
    prep_grade_entry: "Prep Program Grade Entry",
    prep_levels: "Prep Levels",
    prep_modules: "Prep Program Modules",
    prep_reports: "Prep Program Reports",
    prep_schedule: "Prep Program Schedule",
    prep_schedule_draft_delete_confirm_text:
        "Prep_Schedule_Draft_Delete_Confirm_Text",
    prep_schedule_draft_store_confirm_text:
        "Prep_Schedule_Draft_Store_Confirm_Text",
    prep_schedule_draft_title: "Prep Program Schedule Drafts",
    prep_schedule_import_data_excel_format:
        "Classroom Name, Level, Class, Day, Start Time, End Time, Start Time, End Time, Start Time, End Time, Start Time, End Time, Start Time, End Time, Instructor ID, Instructor Overlap, Class Overlap",
    prep_school: "Prep School",
    prep_school_approval: "Prep School Approval?",
    prep_status: "Prep Program Status",
    prep_students: "Prep Students",
    prep_students_import_excel_format: "Student Number, Level, Class(Section)",
    prep_attendance_status: "Prep Attendance Status",
    prep_exam_status: "Prep Exam Status",
    preparation_note_entry: "Preparation Note Entry",
    preparatory_information: "Preparatory Information",
    preparatory_notes: "Prep Report Card",
    prerequisites: "Prerequisites",
    present: "Present",
    preview: "Preview",
    preview_btn_info:
        "Click the save button below to complete the application!",
    preview_save_btn_info:
        "You can save your application without uploading your documents. After saving you will get a follow link. You can upload your documents by logging in from the link below.",
    price: "Fee",
    price_type: "Price Type",
    priceless: "Priceless",
    prices: "Fees",
    print: "Print",
    placement_exam_scores_excel: "Student Number,Point,Program Code",
    print_mail_order_form: "Print Mail Order Form",
    print_promissory_note: "Print Promissory Note",
    print_receipt: "Print Receipt",
    print_roster: "Attendance List",
    print_weekly_roste: "Weekly Attendance List",
    proccess_success: "Proccess Successful",
    proccess_will_be_started:
        "The proccess will be started. Do you confirm the Procees? ",
    process: "Process",
    process_select: "Process Select",
    process_type: "Process Type",
    processes: "Processes",
    processing: "Processing",
    processing_information: "Information Processing",
    processing_model: "Information Processing Model",
    product: "Product",
    product_code: "Product Code",
    products: "Products",
    profession_job: "Profession / Job",
    proficiency_certificate_type: "Proficiency Certificate Type",
    proficiency_document: "Proficiency Certificate",
    proficiency_exam: "Proficiency ",
    proficiency_requests: "English Proficiency Requests",
    proficiency_status: "Proficieny Status",
    proforma_invoice: "Proforma Invoice",
    proforma_invoices: "Proforma Invoices",
    program: "Program",
    program_code: "Program Code",
    program_code_name: "Program Code / Name",
    program_codes: "Program Codes",
    program_coordinator: "Program Coordinator",
    program_days: "Program Days",
    program_decrease: "Click to remove from selected programs.",
    program_delete_info:
        "You are about to delete the selected Program. Do you want to continue processing?",
    program_info: "Program Information",
    program_information: "Select Program",
    program_language: "Language of Instruction for the Program",
    program_level: "Program Level",
    program_level_1: "Education Level",
    program_level_d: "Doctorate",
    program_level_l: "Undergraduate Degree Program",
    program_level_ol: "Associate Degree Program",
    program_level_yl: "Master's Degree Program",
    program_name: "Program Name",
    program_name_en: "Program Name (EN)",
    program_select: "Program Selection",
    program_select_min: "Please choose at least one program!",
    program_selected: "The program has already been selected.",
    programs: "Programs",
    programs_applied: "Programs Applied",
    programs_selected: "Programs Selected",
    programs_to_choose: "Programs to Choose",
    programs_you_are_applying: "Programs You Are Applying",
    program_groups: "Program Groups",
    project_advisory: "Project Advisory",
    promissory_no: "Promissory No",
    promissory_note: "Promissory Note",
    promissory_payment: "Promissory Payment",
    provision: "Provision",
    public_scholarship: "Public Scholarship",
    publish: "Publish",
    publish_at: "Publish At",
    published: "Published",
    punishment: "Punishment",
    punishment_number: "Punishment Number",
    punishment_end_date: "Punishment End Date",
    punishment_start_date: "Punishment Start Date",
    product_prices_import_format:
        "Faculty Code, Program Code, Registration Academic Year, Student Group, Price, Currency, Due Date, Number of Installments, Start Date, End Date",
    proficiency_point: "Proficiency point",
    program_groups_lte: "Graduate Program Groups",
    please_upload_image: "Please upload your photograph below.",

    // R
    record_type: {
        manual: "Manual",
        online: "Online",
    },
    readonly: "Readonly",
    requirements: "Requirements",
    random: "Random",
    rank: "Rank",
    ranking: "Ranking",
    read_and_accept: "I have read and I approve",
    reason: "Reason",
    reason_for_leaving: "Reason for Leaving",
    reason_for_return: "Refund Reason",
    reason_to_increase_max_duration: "REASON TO INCREASE MAX TIME",
    receipt_number: "Receipt Number",
    receipt_number_format: "Receipt Number Format",
    receipt_number_not_created: "Failed to generate receipt number",
    receipt_search: "Receipt_Search",
    receipts: "Receipts",
    refund: "Refund",
    refund_amount: "Refund_Amount",
    refund_date: "Refund Date",
    refund_form: "Refund Form",
    refund_reason: "Reason for Refund",
    refund_request: "Refund Request",
    refund_request_list: "Refund Request List",
    refund_type: "Types of Refund",
    registration: "Registration",
    registration_academic_year: "Registration Academic Year",
    registration_data: "Registration Data",
    registration_date: "Registration Date",
    registration_date_between: "Registration Date Range",
    registration_delete_text:
        "You are about to delete the record. Do you approve the transaction?",
    registration_document_types: "Registration Document Types",
    registration_guide: "Registration Guide",
    registration_hour: "Registration Hour",
    registration_information: "Registration Information",
    registration_number: "Registration Number",
    registration_season: "Registration Semester",
    registration_semester: "Registration Period",
    registration_statement: "Registration Statement",
    registration_status: "Registration Status",
    registration_type: "Registration Type",
    registration_type_1: "Registration Type",
    registration_type_2: "Registration Type",
    registration_type_registration: "Registration",
    registration_type_student: "Student",
    registration_types: "Registration Types",
    registration_year: "Year of Registration",
    registrations: "Registrations",
    rejected: "Rejected",
    reject: "Reject",
    remaining_amount: "Remaining Amount",
    remaining_credit: "Remaining Credits",
    remaining_payment_amount: "Remaining Payment Amount",
    remaining_quota: "Remaining Quota",
    remaining_students_radio: "Remaining Students",
    remove_exception_payment_approval: "Remove Exception Payment Approval",
    remove: "Remove",
    remind_password: "Parola Hatırlat",
    repeat: "Repeat",
    repeat_count: "Repeat Count",
    repeat_student_count: "Number of Repeat Students",
    repeat_type: "Repeat Type",
    repetition_status: "Repetition Status",
    report: "Report",
    report_type: "Report Type",
    reporter_analysis_report: "Reporter Analysis Report",
    reporter_assignment_letter: "Reporter Assignment Letter",
    request_data_count: "Request_Data_Count",
    request_date: "Request Date",
    request_information: "Request Information",
    request_number: "Request Number",
    request_reason: "Request Reason",
    request_type: "Request Type",
    requests_info: "Requests",
    requests_of_leave_with_consent: "Requests Of Leave With Consent",
    required: "Required",
    required_course_count: "Required Course Count",
    required_course_credit: "Required Course Credit",
    required_course_ects_credit: "Required Course Ects Credit",
    reservation_add: "Reservation Add",
    reservation_system: "Reservation System",
    reservation_type: "Reservation Type",
    reservations: "reservations",
    reset: "Reset",
    residence_address: "Residence Address",
    residence_city: "Residence City",
    residence_permit: "Residence Permit",
    residence_permit_end_date: "Residence Permit End Date",
    residence_permit_no: "Residence Permit No",
    residence_permit_start_date: "Residence Permit Start Date",
    residence_permit_validity_dates: "Residence Permit Validity Dates",
    residence_permit_validity_end_date: "Validity End Date",
    residence_permit_validity_start_date: "Validity Start Date",
    result: "Result",
    results: "Results",
    role: "Role",
    role_permissions: "Role Permission",
    roles: "Roles",
    role_filter: "Role Filtering",
    room: "Room",
    registration_numbers_by_faculty_report:
        "Fakülte Bazlı Kayıt Sayıları Raporu",

    // S
    select_image_upload: "Select the image you want to upload",
    merged_sections: "Merged Sections",
    search_section: "Section Search",
    student_transcript: "Transcript",
    student_english_language_proficiency_document: "Language Proficiency Certificate",
    surveyReportJob: "Survey Report Job",
    s_grade_conversion: "S Grade Conversion",
    saturday: "Saturday",
    save: "Save",
    save_and_continue: "Save and Continue",
    save_preferences: "Save Preferences",
    send_preferences: "Send Preferences",
    saved: "Saved",
    scala_id: "Scale ID",
    scala_name: "Scale Name",
    scholarship_detail: "Scholarship Detail",
    scholarship_rate: "Scholarship Rate",
    scholarship_status: "Scholarship Status",
    scholarship_type: "Scholarship Type",
    scholarships: "Scholarships",
    scholarshiptypes: "Type of Scholarship",
    scholor_ship_rate_ration: "Without Scholarships",
    school: "School",
    school_name: "Name of School",
    school_certificate: "School Certificate",
    scientific_preparation: "Scientific Preparation",
    scientific_preparation_courses: "Bilimsel Hazırlık Dersleri",
    score: "Score",
    score_information: "Score Information",
    score_type: "Type of Score",
    score_with_the_selected_program: "Score With The Selected Program",
    search: "Search",
    search_course: "Search Course",
    seating_types: "Seating Types",
    section: "Section",
    section_message: "please choose a section",
    section_detail: "Section Detail",
    section_not_found: "Section Not Found",
    section_count: "Number of Sections",
    section_id: "section Id",
    section_information: "Section Information",
    section_operations: "SECTION Operations",
    section_type: "Section Type",
    sections: "Sections",
    section_import_excel:
        "Course Code, Section, Instructor Id, Quota , Type, Section Language",
    section_detail_import_excel:
        "Course Code, Section, Detail Type, Detail Code, Detail, Invert",
    section_update_success:
        "{name} {surname} student's section has been updated to {section}.",
    section_update_unsuccess:
        "{name} {surname} student's section could not be updated to {section}.",
    select: "Select",
    select_btn: "Select",
    select_date: "Select Date",
    select_file: "Select File",
    select_is_cash_or_installment: "Peşin mi taksitli mi olduğunu seçiniz",
    select_it: "Select",
    select_program: "Select Program",
    selected_field_is_invalid: "Selected %{numberof} field invalid",
    select_report_type: "Select Report Type",
    semester: "Semester",
    semester_1: "Academic Year/Semester",
    semester_count: "Number of Semesters",
    semester_registration_information: "Semester / Registration Information",
    semester_settings: "Semester Settings",
    semester_status: "Semester Status",
    semester_text: "Semester",
    semester_text_en: "Semester (EN)",
    semester_title: "Semester",
    semester_x: "Semester {semester}",
    semesterdate: "Semester Dates",
    semesters: "Semesters",
    semester_info: "Semester Information",
    send: "Send",
    semester_add: "Add Semester",
    send_again: "Send Again",
    send_again_btn: "Send Again (button)",
    send_again_not: "Click on the Send Again button and try again",
    send_approve: "Send Approve",
    send_password: "Send Password",
    send_request: "Send Request",
    send_sms: "Send SMS",
    send_verification_code: "Send Verification Code",
    send_yoksis_transcript: "Send Yoksis Transkrip",
    services: "Services",
    session: "Session",
    settlement_scholarship: "Settlement Scholarship",
    short_name: "Short Name",
    short_name_en: "Short Name (EN)",
    show: "Show",
    show_application: "Show/View Application",
    signature: "Signature",
    signature_name: "Signature Name",
    signature_type: "Signature Type",
    section_status: "Section Durumu",
    signer: "Signer",
    signer_name_surname: "Signatory",
    signer_national_id: "Signer ID Number",
    signer_title: "Signer Title",
    signer_title_en: "Signer Title(EN)",
    slot: "Slot",
    slot_type: "Slot Type",
    slot_type_code: "Slot Type Code",
    slot_types: "Slot Types",
    slots: "Slots",
    something_went_wrong: "An Error Has Occurred ",
    sorry_we_could_not_find_the_page_you_were_looking_for:
        "Sorry we could not find the page you were looking for",
    sort_by_graduation_grade_point_average:
        "Sort by Graduation Grade Point Average",
    sorting_by_semester_grade_point_average:
        "Sorting by Semester Grade Point Average",
    sound_systems: "Sound Systems",
    speaking_exam: "Speaking Exam",
    spring: "Spring",
    spring_credit: "Spring Credit",
    staff: "Staff",
    staff_information: "Staff Information",
    start: "Start",
    start_date: "Start Date",
    start_proccess: "Start",
    start_time: "Start Time",
    statement1_error:
        "Verify the authenticity of the information entered on the record monitor/website",
    statement2_error:
        "Confirm the ClarificationText Regarding Processing of Personal Data",
    statement3_error:
        "Confirm the Explicit Consent Text Regarding Processing of Personal Data",
    statement4_error:
        "Confirm the online payment agreement!",
    statement5_error: "You cannot pay with the installment option by wire transfer.",
    statement_1:
        "I declare that the informatiıon I entered the record monitor is true and correct.",
    statement_2:
        "I confirm that I have read and understood the <a href=#>ClarificationText</a>' regarding Precessing of Personal Data.",
    statement_3: "Explicit Consent Text Regarding Processing of Personal Data",
    statement_4:
        "Explicit Consent Text Regarding Processing of Personal Data(Pilotage)",
    status: "Status",
    stay_of_execution_decision: "Stay Of Execution Decision",
    step_name: "Step Name",
    status_approval_awaited: "Approval is Awaited",
    status_approved: "Status Approved",
    status_declined: "Status Declined",
    status_update: "Status Update",
    status_waiting_payment: "Waiting Payment",
    status_uploaded: "Status Uploaded",
    status_waiting: "Status Waiting",
    status_waiting_publish: "Status Waiting Publish",
    street: "Street",
    street_and_no: "Street No",
    student: "Student",
    student_unihall_info: "To be registered for the period {0}",
    student_unihall_info1: "Residing outside of Istanbul",
    student_unihall_info2: "Being a BAU preparatory class student",
    student_bea_bau: "Be a BAU Student",
    student_document_create1: "Student Document Create",
    student_affairs: "Student Affairs",
    student_certificate: "Student Certificate",
    student_collection_report: "Student Collection Report",
    student_count: "Number of Students",
    student_courses_title: "Course Analysis Report",
    student_document_create: "Create Student Document",
    student_email: "Student E-Mail",
    student_gno_simulation: "Student CGPA Simulation",
    student_gno_report: "Student GNO Report",
    student_graduation_requirement_report:
        "Student Graduation Requirement Report",
    student_group: "Student Group",
    student_grade_entry_failed:
        "Grade entry  failed for student number {number} and name {name}",
    student_information: "Student Information",
    student_information_system: "Student Information System",
    student_list: "Student List",
    student_list_report: "Student List Report",
    student_list_with_photo: "Student List (with Photo)",
    student_mobile_number: "Student Mobile Number",
    student_name: "Student Name",
    student_number: "Student No",
    student_number_format: "Student Number Format",
    student_number_long: "Student Number",
    student_numbers: "Student Numbers",
    student_notes_report: "Student Notes Report",
    student_program_class_success_title: "Class Success Rank",
    student_program_graduation_success_title: "Graduation Success Rank",
    student_report: "Student Report",
    student_reports: "Student Reports",
    student_section_change: "Student Section Change",
    student_sections: "Student Sections",
    student_semester_count: "Student Semester Count",
    student_semester_report: "Semester Status Report",
    student_slots: "Student Slots",
    student_status: "Student Status",
    student_status_btn: "Student Status ",
    student_surname: "Student Surname",
    student_card: "Student Card",
    students: "Students",
    student_program_id: "Student Program ID",
    studied: "Studied",
    student_preference_reset_info: "Are you sure you are about to reset the student's preference?",
    student_preferences: "Student preferences",
    subject: "Subject",
    subject_en: "Subject EN",
    success: "Success",
    success_data_count: "Success_Data_Count",
    success_rate: "Success Rates",
    success_rate1: "Success Rate",
    success_status: "Success Status",
    success_status_report: "Success Status Report",
    successfully_added: "Successfully Added",
    successfully_changed: "Successfully Changed",
    successfully_deleted: "Successfully Deleted",
    sum_of_credits: "Sum of Credits",
    sum_of_ects_credits: "Sum of ECTS Credits",
    summary: "Summary",
    summary_of_project: "Summary of Project",
    summary_of_thesis: "Summary of Thesis",
    summary_statement_content: "Summary Statement Content",
    summer: "Summer",
    sunday: "Sunday",
    survey_result_download: "Survey Result Downland",
    surgery_illness_history: "History of any Surgery or Chronic Diseases",
    surgery_or_discomfort: "Surgery or Chronic Diseases if any ",
    surname: "Surname",
    surname_placeholder: "Enter your Surname",
    survey: "Survey",
    survey_questions: "Survey Questions",
    survey_result: "Survey Result",
    survey_result_show: "View Survey Result",
    surveys: "Surveys",
    switch_user: "Switch User",
    synchronous: "Synchronous",
    system_name: "System Name",
    speacial_student_error: "Speacial Students Can Not Register Courses",
    schedule_overlap_message:
        "The course you want to take overlaps with #course.",
    system_maintance:
        "If you are viewing this page, please follow the DNS information update steps in the instructions below.",
    system_maintance_2: "Thank you for your understanding.",
    select_theoretical_section: "Select theoretical section",
    survey_comment_question_select: "Select comment question.",
    select_practical_section: "Select practical section",
    survey_question_about_course: "Questions About the Course",
    survey_staff_avg: "Instructor Average",
    survey_faculty_avg: "Faculty Average",
    survey_university_avg: "University Average",
    sending_sms:"Sending SMS",
    sending_sms_student_search:"Sending SMS Student Search",
    sending_sms_with_mobile_phone_information:"Sending SMS with Mobile Phone Information",
    sending_sms_to_staff:"Sending SMS To Staff",
    send_sms_excel:"National Id,Student Number,Phone Number",
    survey_results: "Survey Results",
    survey_results_loading: "Survey Results Calculating",
    survey_instructor_results: "Instructor Survey Results",

    // T
    two_factor_authentication: "Two Factor",
    take_the_lesson: "Select Course",
    date_took_the_course: "Date Took the Course",
    taking_during_active_semester: "Taking During Active Semester",
    tc_no: "Turkish Identification Number",
    tc_or_passport: "TC Identity / Passport No",
    teaching_staff: "Instructor",
    teaching_staff_no: "Teaching Staff No",
    temporary_graduation: "Temporary Graduation Certificate",
    telephone: "Phone",
    terminal: "Terminal",
    the_class_finished_in_the_program_came: "Thesis Title",
    the_person_who_person: "The Person Who Person",
    the_values_you_entered_have_already_been_added:
        "The Values You Entered Have Already Been Added",
    theoretical: "Theoretical",
    theoretical_credit: "Theoretical Credit",
    theoric: "Theoretical",
    total_ects_range_completed: "Total ECTS Range Completed",
    total_credit_range_completed: "Total Credit Range Completed",
    theoric_section: "Teoric Section",
    there_are_no_records: "No record found",
    there_is: "There is",
    there_is_any_course_in_active_semester:
        "Course not found in the Active Semester",
    there_is_no_authorization: "There is no authorization",
    thesis_advisor: "Thesis Advisor",
    thesis_advisory: "Thesis Advisory",
    thesis_and_project: "Thesis and Project",
    turkmer_admin_page_title: "Turkmer Programs Application List",
    thesis_and_project_advisor_approve_title:
        " View Coordinator/ Advisor Applications",
    thesis_and_project_application: "Thesis & Project Application",
    thesis_and_project_coordinator_approve_title:
        "Thesis & Project Coordinator Approve Title",
    thesis_and_project_info: "Thesis & Project Information",
    thesis_and_projects_admin_title: "Thesis and Projects Admin",
    thesis_and_projects_admin_update: "Thesis and Projects Admin Update",
    thesis_students: "Thesis Students",
    transitions: "Translations",
    tr_name: "TR Name",
    en_name: "EN Name",
    thesis_title: "Thesis Title",
    this_field_is_required: "This field is required.",
    thursday: "Thursday",
    title1: "Ünvan",
    title: "Title",
    title_of_project: "Title of Project",
    title_of_thesis: "Title of Thesis",
    title_title: "Title",
    to_score: "To Score",
    to_email: "To E-Mail",
    toefl_password: "Toefl Password",
    toefl_username: "Toefl Username",
    total_advisory: "Total Advisory",
    total_amount: "Total Amount",
    total_course_count: "Total Course Count",
    total_credits: "Total Credits",
    total_credits_attempted: "Total Credits Attempted",
    total_credits_earned: "Total Credits Earned",
    total_debt: "Total Debt",
    total_discount: "Total Discount",
    total_discount_amount: "Total Discount Amount",
    total_discount_rate: "Total Discount Rate",
    total_ects_credits: "Total AKTS Credits",
    total_ects_credits_attempted: "Total ECTS Credits Attempted",
    total_ects_credits_earned: "Total ECTS Credits Earned",
    total_installment: "Total Installment",
    total_paid: "Total Paid",
    total_points: "Toplam Puan",
    total_refund: "Total Refund",
    total_slots: "Total Slots",
    total_student_count: "Total Number of Students",
    total_capacity: "Total Capacity",
    transaction_date: "Transaction Date",
    transaction_start_date: "Transaction Start Date",
    transaction_end_date: "Transaction End Date",
    transaction_reference: "Transaction Reference",
    transcript: "Transcript",
    true: "True",
    tuesday: "Tuesday",
    tuition: "Tuition Fee",
    turkey: "Turkey",
    turkish: "Turkish",
    turkmer_page_title: "Turkish Preparatory School Application Form",
    turn_back: "Go Back",
    type: "Type",
    type_detail: "Type Detail",
    type_of_delivery: "Type of Delivery",
    type_of_program_score: "Type of Program Score",
    tax_explanation: "VAT is not included in the above mentioned tuition fees. After choosing the payment method, you can see the fees, including VAT, on the screen you will be directed to. We would like to remind you that it is possible for the public authority to change tax rates during the service period. In this context, if the VAT rate is increased or decreased, it is possible that this change will be reflected in the tuition fee. VAT rate on tuition fees is 10%.",

    // U
    unread: "Unred",
    read: "Read",
    unihall_country_code: {
        TR: "Turkey",
        YU: "Abroad"
    },
    user: "User",
    unihall_approvet_payment: "Your payment has been confirmed",
    unihall_applications_title: "Dormitory Room Applications",
    unihall_payment_note: "Note: Student's Name and Surname / Room Type /T.C. should be entered in the 'Explanation' field when making the transfer. Identity Number must be written",
    unihall_payment_note1: "Dear students, Fall-Spring semester dormitory payments are made over 9 months (October-June). Incomplete payments will not be accepted as final registration and will be returned to you. If your money transfer payment is not sent to the system within 1 hour (60 minutes), your application will be cancelled.",
    unihall_quota: "Dorm Rooms",
    unihall_quotas_title: "Dormitory Room Capacities",
    unihall_quotas_price_title: "Dormitory Room Price",
    unihall_quota_select: "Room Selection",
    unihall_quota_type: "Room type",
    unihall_cash_price: "Advance Fee",
    unihall_installment_price: "Installment Fee",
    unihall_remaining_quota: "Remaining Quota",
    unihall_make_order_info_text: "Your application process has been sent to APPROVAL, if you pay the fee within 1 hour, your application & payment will be approved, your application will be canceled if the time expires.",
    unihall_approved_message: "Student Dormitory Accommodation Fee Payments are made annually for the Fall – Spring Semesters covering a 9-month period (September – June). Payments that are not made in full will not be accepted as final registration and will be refunded to you.<br><br>1) For Turkish citizens, a photocopy of the identity card ( for foreign nationals, a photocopy of their passports),<br>2) Document for the registration status of the student to the university ( for foreign nationals, certificate of educational permit),<br>3) Four passport photos,<br>4) Medical Health Report verifying that the student’s state of health is suited for living together with other people (obtained from a Health Care Center, State or Private Hospital),<br>5) Documentation of the police records and archives,<br>6) For students of foreign nationalities, documentation of residential permit to be obtained from security department.",
    unihall_quota_full: "The dorm quotas are full. Thank you for your interest. Students who want to apply for the reserve list can send their student name, surname, school number, contact information and residence address to <a href='mailto:info@unihall.org'>info@unihall.org</a>.",
    unihall_room_info_update: "Update Room Information",
    unauthorized_operation: "Unauthorized Operation",
    undergraduate_transfer: "Undergraduate Transfer",
    undergraduate_transfer_applications: "Undergraduate Transfer Applications",
    undergraduate_transfer_quotas: "Undergraduate Transfer Quotas",
    undergraduate_vertical: "LATERAL/VERTICAL Transition",
    undergraduate_vertical_transfer: "LATERAL/VERTICAL Transition",
    undergraduate_check_date_message: "You cannot apply because the applications are not active yet.",
    unit: "Unit",
    unit_id: "Unit İd",
    units: "Units",
    universities: "Universities",
    university: "University",
    university_not_list: "My University Is Not In The List",
    university_status: "Domestic / Abroad",
    university_courses: "University Courses",
    university_grade_scale_matching: "University Grading Scale Matching",
    university_informations: "University Information",
    university_rank: "University Ranking",
    university_type: "University Type",
    university_add: "University Add",
    university_where_the_course_was_taken:
        "University where the course was taken",
    unpublished: "Unpublished",
    update: "Update",
    update_classes_title: " Class Update Process",
    update_draft: "Update Draft",
    update_info_btn: "Update Contact Information",
    update_register: "Student Registration Update.",
    updated_at: "Updated At",
    updated: "Updated",
    upload: "Upload",
    upload_file: "Uploading File",
    upload_your_documents: "Download your documents",
    uploaded_files: "Uploaded Files",
    unsuccessful: "Unsuccessful",
    url: "URL",
    used_drugs: "Medicine you take regularly if any",
    user_quide: "User Guide",
    user_type: "User Type",
    username: "Username",
    user_transfer: "User Transfer",

    // V
    validity_dates: "Validity Period",
    validity_season_end: "Validity Season End",
    validity_season_start: "Validity Season Start",
    validity_date_start: "Validity Start Date",
    validity_date_end: "Validity End Date",
    value: "Value",
    verbal_score: "Verbal_Score",
    verification: "Verification",
    verification_code: "Verification Code",
    verification_method: "Verification Method",
    verification_method_not_found: "Verification Method Not Found",
    verification_personal_info: "Verification with Personal Info",
    verification_sms_code: "Verification with SMS Code",
    verified: "Verified",
    verify: "Verify",
    verify_code_again_not: "Verification Code Sent Again",
    vertical_transfer: "Vertical Transfer",
    view: "View",
    village: "Village",
    virman: "Virman",
    virtual_pos: "Virtual Pos",
    view_and_edit: "View And Edit",

    // W
    waiting_payment: "Waiting payment",
    waiting_prep_school: "Waiting Prep School",
    waiting_academic_unit_office: "Waiting Academic Unit Office",
    waiters_approved: "Approved Applications",
    waiters_for_approve: "Applications Pending Approval",
    waiters_rejected: "Applications Rejected",
    want_leave_of_absence:
        "Will There Be a Request to Apply for Leave of Absence?",
    want_leave_of_absence_missing: "Want_Leave_Of_Absence_Missing",
    want_optional_prep: "Want_Optional_Prep",
    want_optional_prep_missing: "Want_Optional_Prep_Missing",
    warning: "Warning",
    wednesday: "Wednesday",
    week: "Week",
    weekend_in: "Weekend In",
    weekend_out: "Weekend Out",
    welcome: "Welcome",
    welcome_letter: "Welcome Letter",
    welcome_to: "Welcome to",
    whole_price: "Whole Price",
    wire: "Wire / (EFT) Electronic Funds Transfer",
    wire_info: "Remittance Information is as Below",
    with_installment: "With Installment",
    with_mobile_number: "With Mobile Number",
    with_personal_information: "With Personal Information",
    with_thesis: "With Thesis",
    with_without_thesis: "With Without Thesis",
    withdraw_requests: "Withdraw Requests",
    without_thesis: "Without Thesis",
    witness_complainant_statement: "Witness Complainant Statement",
    working_end_date: "Ending Date of Employment",
    working_start_date: "Starting Date of Employment",
    working_status: "Attendance Status",
    write_verify_code: "Enter Verification Code sent to your mobile phone",
    written_and_oral_defense_report: "Written And Oral Defense Report",
    written_exam: "Written Exam",

    // Y
    you_will_directed: "You will be directed shortly",
    year: "Year",
    year_code: "Year Code",
    year_count: "Year Count",
    year_of_birth: "Year of Birth",
    yearly: "Yearly",
    yes: "Yes",
    yl_payment_plan: "YL Payment Plan",
    yoksis: "Yoksis",
    yoksis_code: "Yoksis Code",
    yoksis_import_title: "YOKSİS Import",
    yoksis_information_cancel: "Yoksis Information Cancel",
    yoksis_information_import: "Yoksis Information Import",
    yoksis_information_update: "Yoksis Information Update",
    yoksis_number_of_semesters: "Yoksis Number of Semesters",
    yoksis_unit_id: "Yoksis Unit Id",
    yoksis_student_id: "Yöksis Student ID",
    yoksis_units: "Yoksis Units",
    yok_registration_status_report: "YOK Registration Status Report",
    you_can_combine_up_to_five_lessons: "You can combine up to five lessons",
    you_are_not_authorized_for_this_operation:
        "You are not authorized for this operation.",
    you_can_try_again_after_1_minute: "You can try again after 1 minute.",
    you_dont_have_a_payment_plan: "You do not have a Payment Plan",
    you_have_to_upload_the_files_first: "You have to upload the files first!",
    you_have_tried_too_many: "You have tried too many.",
    you_must_fill_in_the_fields_indicated_in_red:
        "You are required to fill in the fields indicated in red",
    you_must_do_change: "You must make changes",
    your_chosen_courses: "Courses Selected",
    your_payment_transaction_has_been_successfully_completed:
        "Your payment transaction has been successfully completed",
    your_preferences: "Your Preferences",
    your_preferences_waiting: "Your preferences are awaited",
    your_programs: "Your Programs",
    your_selected_permission: "Your Selected Permission",
    your_selected_program: "Program You Selected",
    your_selected_classrooms: "Your Selected Classrooms",
    you_dont_have_any_invoice: "You don`t have any invoices",
    yks: "YKS",
    exam_schedules_double_course_control:
        "Double Course Control",
    double_course_control: "Double Course Control",
    exam_schedule_report: "Courses Not Included in the Exam Schedule",
    isThereExamSchedule: "Is there an exam schedule?",
    group_name: "Group Name",
    group_name_en: "Group Name (EN)",
    bulk_message: "Bulk Message",
    bulk_address_update: "Bulk Address Update",
    bulk_import: "Bulk import operations",
    // Z

    message_sent_student: "Message sent to {number} student",
    application_information_is_awaited: "Application information is awaited.",
    preference_information_is_awaited: "Preference information is awaited.",
    required_documents_is_awaited:
        "Required documents are expected to be sent.",
    required_documents_approval_is_awaited:
        "Required documents are awaited approval.",
    your_application_has_been_accepted: "Your application has been accepted.",
    your_application_has_been_rejected: "Your application has been rejected.",
    your_application_publish: "Your application has been finalized.",
    your_application_has_been_evaluated:
        "Your application has been evaluated..",
    undergraduate_transfer_start:
        "You can start your transfer application process by clicking the button below.",
    you_choose_courses_to_improve_your_grades_are_you_sure:
        "You choose courses to improve your grades. Are you sure?",

    minor_status_obj: {
        waiting: "Application are pending",
        waiting_preference: "Preferences are pending",
        waiting_prep_school: "Prep school awaits",
        waiting_academic_unit_office: "Academic unit expected",
    },

    short_name_desc: "Z-A Short",
    short_name_asc: "A-Z Short",
    short_number_desc: "0-9 Short",
    short_number_asc: "9-0 Short",
    verification_via_sms: "Verification Via Sms",
    verification_with_authenticator_app: "Verification with Authenticator App",
    two_factor_app_info: "Scan the QR code with the Authenticator application, write the code given by the application in the field below and click the Confirm button.",

};

export default translations
