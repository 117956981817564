const translations = {
    tr: "Türkçe",
    en: "English",

    // A
    admin: "Admin",
    a_z: "A-Z Sırala",
    added: "Eklendi",
    archive: "Arşiv",
    absent: "Yok",
    absence_success_updated: "Listede İşlemi Başarılı Olan Ya Da Olmayan Öğrencileri Görebilirsiniz",
    achievement_ranking_control: "Başarı Sıralama Kontrolu",
    attachment_cut_form: "İlişki Kesme Formu",
    attachment_cut_form_pdf: "İlişki-Kesme-Formu",
    absence_report: "Devamsızlık Raporu",
    abroad_application: "Yurt Dışı Başvuru",
    abroad: "Yurt Dışı",
    academic_unit_office: "Akademik Birim Ofisi",
    academic_year: "Akademik Yıl",
    academic_season_term_applied: "Başvurduğu Akademik Sezon-Dönem",
    acceptance_data: "Kabul Verileri",
    acceptance_date: "Kabul Tarihi",
    accepttance_status: "Kabul Durumu",
    accepttance_not_accepted: "Kabul Görmedi",
    account_code: "Cari Kodu",
    account_holder: "Hesap Sahibi",
    account_information: "Hesap Bilgileri",
    account_number: "Hesap No",
    action: "İşlem",
    active: "Aktif",
    active_semester: "Aktif Dönem",
    adaptation_request: "İntibak Talepleri",
    approve_1: "Onayı",
    attendance_detail: "Devamsızlık Detayı",
    available_roles: "Görebilecek Roller",
    available: "Var",
    add: "Ekle",
    add_all_student_without_payment_approval: "Ödeme onayı olmayan öğrencileri aktar",
    add_bank_credit: "KMH Ekle",
    add_course: "Ders Ekle",
    add_course_transfer: "Ders Transferi Ekle",
    add_discount: "İndirim Ekle",
    add_education_credit: "Eğitim Kredisi Ekle",
    add_exam_score: "Sınav Notu Ekle",
    add_module: "Modül Ekle",
    add_new_course: "Yeni Ders Ekle",
    add_offset: "Mahsup Ekle",
    add_offset_payment: "Mahsup Virman",
    add_option: "Seçenek Ekle",
    add_payment: "Ödeme Ekle",
    add_payment_plan: "Ödeme Planı Oluştur",
    add_pdf: "Pdf Ekle",
    add_preference: "Tercih Ekleme",
    add_preference1: "Tercih Ekle",
    add_refund: "İade Ekle",
    add_scholarship: "Burs Ekle",
    add_slot: "Slot Ekle",
    add_student_number: "Öğrenci numarası ekle",
    added_by: "Ekleyen Kişi",
    added_course_transfer: "İntibak İşlemleri",
    added_date: "Ekleme Tarihi",
    additional_lecturer: "Ek Öğretim Elemanı",
    additional_lecturers: "Ek Öğretim Elemanları",
    address: "Adres",
    address_2: "Açık Adres",
    address_en: "Adres (EN)",
    address_information: "Adres Bilgileri",
    address_update: "Adres Güncelle",
    advance_payment: "Peşin Ödeme",
    advance_amount: "Peşinat Tutarı",
    advisor: "Danışman",
    advisor_approved: " {advisor} Onayı",
    advisor_: "Danışman",
    advisor_id: "Danışman ID",
    advisor_name: "Danışman Adı",
    advisor_of_project: "Proje Danışmanı",
    advisor_of_thesis: "Tez Danışmanı",
    advisor_surname: "Danışman Soyadı",
    advisored_of_students: "Danışmanı Olduğum Öğrenciler",
    affiliated_curriculum: "Bağlı Olduğu Müfradat",
    already_available: "Zaten Mevcut",
    all: "Hepsi",
    all_exam_scores: "Tüm Sınav Notları",
    all_students: "Tüm Öğrenciler",
    allow_classroom_overlap: "Sınıf Çakışmasına İzin Ver",
    allow_instructor_overlap: "Eğitmen Çakışmasına İzin Ver",
    alumni: "Mezun",
    alumni_information_form: "Mezun Bilgi Formu",
    alumni_information_form_pdf: "Mezun-Bilgi-Formu",
    allow: "İzin ver",
    amount: "Tutar",
    acceptance_letter_id: "Kabul Mektubu Id",
    amount_paid: "Ödenen Tutar",
    amount_tl: "Tutar (TL)",
    amount_to_be_paid: "Ödenecek Tutar",
    and: "ve",
    announcement: "Duyurular",
    api_config: "API Yapılandırma",
    application_conditions: "Başvuru Koşulları",
    application_completion_status: "Tamamlanma Durumu",
    application_date: "Başvuru Tarihi",
    application_follow_link: "Başvuru Takip Linki",
    application_information: "Başvuru Bilgileri",
    application_new: "Yeni Başvuru",
    application_period: "Kayıt Dondurmayı Talep Ettiği Dönemler",
    application_rules: "Başvuru Kuralları",
    application_status: "Başvuru Durumu",
    application_status_reset_are_you_sure: "Başvuru Durumunu Sıfırlamak İstediğinize Emin Misiniz?",
    application_status_update: "Başvuru Durumunu Güncelle",
    application_delete: "Başvuruyu Sil",
    application_approve: "Başvuruyu Onayla",
    application_reject: "Başvuruyu Reddet",
    application_send: "Başvuruyu Gönder",
    application_send_waiting: "Başvuruyu Göndermeniz Bekleniyor",
    application_success: "Başvurunuz Tamamlanmıştır",
    application_success_info:
        'Başvurunuz tamamlanmıştır. Pin kodunuz: $$$$ Başvurunuzu takip etmek için verilen Pin kodu ile aşağıdaki "Başvuru Takip Linki\'ne tıklayınız"',
    application_summary: "Başvuru Özeti",
    application_system: "Başvuru Sistemi",
    application_type: "Başvuru Türü",
    application_types: "Başvuru Türleri",
    application_update_success: "Başvurunuz başarıyla güncellenmiştir.",
    application_withdraw_display: "Withdraw başvuru ekranı",
    applications: "Başvurular",
    application: "Başvuru",
    application_result: {
        placed: "Yerleşti",
        other_preference_placed: "Başka bir tercihine yerleşti",
        not_placed: "Yerleşemedi",
    },
    application_status_reset: "Başvuruyu Sıfırla",
    applied_faculty: "Başvurulan Fakülte",
    applied_program: "Başvurulan Program",
    apply: "Başvur",
    apply_cash_discount: "Nakit İndirimi Uygula",
    apply_scholarship: "Burs Uygula",
    approval_phase: "Onay Aşaması",
    approval_procedures: "Onay İşlemleri",
    approval_registration_succes_msg:
        "Online kayıt işlemi başarıyla tamamlanmıştır.",
    approval_status: "Onay Durumu",
    approve: "Onayla",
    approve1: "Onayı",
    approve_or_reject_confirm: "İşlemi onaylıyormusunuz?",
    approve_step: "Onay Adımı",
    approve_step_error: "Onay Adımı Girmelisiniz",
    approval_template: "Onay Adım Şablonu",
    approved: "Onaylandı",
    approved_by: "Onaylayan",
    approved_date: "Onaylama Tarihi",
    approved_go: "Onaya gönder",
    approvers: "Onaylayanlar",
    approver: "Onaylayan",
    apply_to: "Uygula",
    are_you_sure_to_apply:
        "Başvuru sürecini başlatmak istediğinize emin misiniz?",
    are_you_sure_to_cancel: "İptal etmek istediğinize emin misiniz?",
    are_you_sure_to_set_active: "Aktif adım olarak güncellemek istediğinize emin misiniz?",
    are_you_sure_to_status_approved: "Durumu Onaylamak istiyormusunuz ?",
    are_you_sure_to_restore_preference: "Tercihi sıfırlamak istediğinize emin misiniz?",
    are_you_sure_to_create_a_new_proforma_invoice:
        "Yeni bir proforma fatura oluşturmak istediğinize emin misiniz?",
    are_you_sure_to_copy: "Kopyalamak istediğinize emin misiniz?",
    are_you_sure_to_delete: "Silmek istediğinize emin misiniz?",
    are_you_sure_to_approve: "Onaylamak istediğinize emin misiniz?",
    are_you_sure_to_reject: "Reddetmek istediğinize emin misiniz?",
    are_you_sure_to_give_exceptional_payment_approval:
        "İstisna ödeme onayı vermek istediğinize emin misiniz?",
    are_you_sure_to_move: "Taşımak istediğinize emin misiniz?",
    are_you_sure_to_remove_exceptional_payment_approval:
        "İstisna ödeme onayını kaldırmak istediğinize emin misiniz?",
    are_you_sure_to_send: "Göndermek istediğinize emin misiniz?",
    are_you_sure_to_withdraw: "Dersi geri çekmek istediğinize emin misin?",
    are_you_sure_to_cancel_loa_discount_rate: "Kayıt dondurma indirimini iptal etmek istediğinize emin misiniz?",
    are_you_sure_want_save: "Kaydetmek istediğinize emin misiniz?",
    are_you_sure_you_want_to_take_the_x_course:
        "'{course_code}'-'{course_name}' isimli dersi almak istediğinize emin misiniz?",
    are_you_sure_you_want_to_update_the_approval_status:
        "Onay durumunu güncellemek istediğinize emin misiniz?",
    are_you_sure_you_want_to_complete_course_transfer:
        "İntibak işlemini tamamlamak istediğinize emin misiniz?",
    are_you_sure_want_course_language: "Ders dili {0} almak istediğinizden emin misiniz?",
    are_you_sure_to_take_on_this_application: "Başvuruyu üstlenmek istediğinize emin misiniz?",
    are_you_sure_to_release_this_application: "Başvuruyu bırakmak istediğinize emin misiniz?",
    asal: "ASAL Talep Listesi",
    asal_delay_reasons: "Talep Nedeni",
    asal_maximum_time_reasons: "Azami Süreyi Artıran Unsur",
    asal_type: "Talep Türü",
    asal_information: "ASAL Sorgulama",
    asynchronous: "Asenkron",
    at_which_unit: "Hangi Birimde",
    attendance_date: "Yoklama Tarihi",
    attendance_create: "Yoklama Ekle",
    attendance_status: "Yoklama Durumu",
    attendance_update: "Yoklama Güncelle",
    attendance_hours: "Devamsızlık Bilgisi",
    attendance_list: "Yoklama Listesi",
    attendees: "Katılımcılar",
    attention: "UYARI",
    attention_for_thesis_and_project:
        "Ders Seçim döneminde ders seçim sayfasından Tez 1-Seminer/Proje/Yeterlilik dersini almalısınız.",
    authorizations: "Yetkiler",
    autumn: "Güz",
    available_hours: "Müsait Saatler",
    average_system_100: "100'lük sistem",
    average_system_4: "4'lük sistem",
    add_description: "Açıklama Ekle",
    are_you_sure_to_start_process:
        "İşlemi başlatmak istediğinize emin misiniz?",
    are_you_sure_make_application: "Başvurunuzu göndermek istediğinize emin misiniz?",
    auto_course_registration: "Otomatik Ders Kaydı",
    auto_repeat_course_registration: "Otomatik Tekrar Ders Kaydı",
    auto_irregular_course_registration: "Otomatik Irregular Ders Kaydı",
    answerable: "Cevap Durumu",
    answer_force: "Yanıtla",
    answer: "Yanıt",
    active_semester_no: "Aktif Dönem No",
    assign_doesnt_have_payment_approval: "Kayıt Yenilemeyenleri Belirle",
    assign_doesnt_have_course_registration: "Ders Kaydı Yapmayanları Belirle",
    attended: "Geldi",
    approve_sms: "SMS Onayı",
    approve_email: "E-posta Onayı",
    approve_call: "Arama Onayı",
    action_title: "İşlem",
    application_images: "Uygulama Görselleri",

    // B
    back: "Geri",
    bahcesehir_university: "Bahçeşehir Üniversitesi",
    bahcesehir_accommodation_and_tourism:
        "Bahçesehir Konaklama ve Turizm Yatırımları A.Ş.",
    balance: "Bakiye",
    balance_overflow: "Bakiyeden fazla ödeme girdiniz",
    balance_report: "Bakiye Raporu",
    bank: "Banka",
    bank_additional_installment: "Banka Ek Taksit",
    bank_credit: "KHM",
    bank_credit_cash: "KHM Peşin",
    bank_id: "Banka Adı",
    bank_name: "Banka Adı",
    bank_receipts: "Dekontlar",
    bank_receipt: "Dekont",
    batch_gno_calculation: "Toplu Genel Not Ortalaması Hesaplama",
    batch_update_due_date: "Toplu Vade Tarihi Güncelleme",
    bau_grade_conversion: "BAU Not Karşılığı",
    bau_grades: "BAU Not Karşılığı",
    bau_language_exam_info:
        "(BAU İngilizce Yeterlilik Sınavı, doktora programları başvurularında kabul edilmemektedir.)",
    bau_language_exam_question:
        "BAU İngilizce Yeterlilik Sınavına Girecek misiniz?",
    bau_student: "BAU Öğrenci",
    bau_student_application: "BAU Öğrenci Başvurusu",
    before_leave_of_absence: "Daha Önce Kayıt Dondurdun Mu?",
    birthdate: "Doğum Tarihi",
    birthplace: "Doğum Yeri",
    board_decision: "Kurul Kararı",
    board_decisions: "Kurul Kararları",
    board_members: "Kurul Üyeleri",
    board_types: "Sıra Türü",
    bonus_course_credit_limit: "Bonus Ders Kredi Limiti",
    branch_name: "Şube Adı",
    branch_type: "Dal Türü",
    browse: "Gözat",
    building: "Bina",
    building_id: "Bina Kodu",
    bulk_document_creation: "Toplu Belge Oluşturma",
    bulk_graduation: "Toplu Mezuniyet İşlemleri",
    batch_score_entry: "Toplu Not Giriş Ekranı",
    batch_score_entry_note: "Toplu Not Giriş ",
    batch_score_entry_info: "Toplu Not Giriş Ekranı Tablosu kaydedilecektir. İşlemi onaylıyor musunuz?",
    birthdate_from: "Doğum Tarihi Başlangıç",
    birthdate_to: "Doğum Tarihi Bitiş",

    // C
    calculate: "Hesapla",
    calculated_cgpa: "Hesaplanan GNO",
    calculated_gpa: "Hesaplanan DNO",
    campus: "Kampüs",
    campus_address: "Kampüs Adresi",
    campus_id: "Kampüs Kodu",
    campus_name: "Kampüs Adı",
    can_approvers: "Onaylayabilenler",
    can_be_repeated: "Tekrar edilebilir mi ?",
    can_take_same_semester: "Aynı Dönem Alabilir",
    can_not_empty_classrooms: "Sınıf listesi boş gönderilemez.",
    cancel: "İptal",
    cancel_application: "Başvurudan vazgeç",
    cancel_to_move: "Taşımayı iptal et!",
    cancellation: "İptal",
    cancellation_amount: "İptal Tutarı",
    cancelled: "İptal Edildi",
    cannot_overflow_balance: "Borçtan fazla ödeme giremezsiniz",
    capacity: "Kapasite",
    card_holder_address: "Kart Sahibinin Adresi",
    card_holder_name: "Kart Sahibi",
    card_holder_phone: "Kart Sahibinin Telefonu",
    card_name: "Kart Adı",
    card_name_en: "Kart Adı (EN)",
    card_no: "Kart Numarası",
    card_number: "Kart Numarası",
    card_owner: "Kart Sahibi",
    card_status: "Kart Durumu",
    card_type: "Kart Tipi",
    cash: "Peşin",
    cash_price: "Peşin Ücret",
    cash_collection_report: "Nakit Bütçe Raporu",
    cash_discount_rate: "Nakit İskonto Oranı",
    cash_in_advance: "Nakit",
    cash_or_installed: "Peşin/Taksitli",
    cash_payment: "Nakit",
    cash_payment_type: "Peşin Ödeme Türü",
    category: "Kategori",
    cert_type: "Belge Tipi",
    certificate: "Sertifika",
    certificate_id: "Sertifika Adı",
    certificate_information: "Sertifika Bilgileri",
    certificate_name: "Sertifika Adı",
    certificate_name_en: "Sertifika Adı En",
    certificate_show: "Sertifika Görüntüleme",
    cgpa: "GNO",
    change: "Değiştir",
    change_your_password: "Şifreni Değiştir",
    change_price: "Ücret Değiştir",
    change_section: "Section Değiştir",
    search_section: "Section Arama",
    change_student_group_radio: "Öğrenci Grubu Değiştir",
    change_user: "Kullanıcı Değiştir",
    changes_will_be_saved:
        "Değişiklikler kaydedilecektir. İşlemi onaylıyormusunuz?",
    check: "Çek",
    check_drawer: "Keşideci",
    credit_limit_control_report: "Kredi Limit Kontrol Raporu",
    check_it: "Teyit Et",
    check_it_title: "TEYİT",
    check_no: "Çek No",
    check_payment_approval: "Ödeme Onayını Kontrol Et",
    choose_student_type_confirm_text: "Yeni kayıt seçimi:",
    chose_your_double_major: "Çift Anadal Terchinizi Seçiniz",
    cities: "İller",
    city: "İl",
    city_id: "İl",
    city_name: "Nüfus İl",
    clarification_text: "Aydınlatma Metni",
    class: "Sınıf",
    classrate: "Sınıftaki Yüzdelik Dilimi",
    class_applied: "Başvurduğu Sınıf",
    class_settings: "Sınıf Ayarları",
    class_x: "%{class}. Sınıf",
    classroom: "Derslik",
    classroom_list: "Derslik Listesi",
    classroom_: "Derslik",
    classroom_id: "Derslik ID",
    classroom_name: "Derslik Adı",
    classroom_programs: "Derslik Programı",
    classroom_types: "Derslik Tipi",
    classrooms: "Derslikler",
    classroom_not_selected: "Derslik Seçili Değil",
    clear: "Temizle",
    click_to_choose: "Seçmek için tıklayınız.",
    close: "Kapat",
    close_edit_at: "İşleme Kapatma Tarihi",
    close_lessons_only: "Sadece Dersleri Kapat",
    code: "Kod",
    coefficient: "BAU Not Katsayısı",
    collection: "Tahsilat",
    collection_bank: "Tahsilat Bankası",
    collection_report: "Ciro ve Tahsilat Raporu",
    collection_screen: "Tahsilat Ekranı",
    collective_consultant_appointment: "Toplu Danışman Atama",
    collection_and_numbers_report: "Günlük Kayıt İstatistiği",
    columns: "Kolonlar",
    do_copy: "Kopyala",
    password_contains_eight_characters_message:
        "Şifreniz en az 8 karakterden oluşmalıdır.",
    password_contains_number_message:
        "Şifrenizde 1 adet sayısal karakter içermelidir.",
    password_contains_uppercase_message:
        "Şifrenizde 1 adet büyük harf içermelidir.",
    password_contains_lowercase_message:
        "Şifrenizde 1 adet küçük harf içermelidir.",
    password_contains_special_character_message:
        "Şifrenizde 1 adet özel karakter(!@#$%&*...) içermelidir.",
    password_send_success: "Şifreniz başarılı bir şekilde gönderilmiştir.",
    password_equals_message: "Girmiş olduğunuz şifreler eşit olmalıdır.",
    comment: "Yorum",
    comment_questions: "Yorum Soruları",
    comment_answers: "Yorum Cevapları",
    communication_address: "Haberleşme Adresi",
    compass_dates: "Pusula Tarihleri",
    company_name: "Firma Adı",
    company_name_en: "Firma Adı (İngilizce)",
    complaint_petition: "Şikayet Dilekçesi",
    complete_registration: "Kaydını Tamamla",
    completed_akts_credit: "Tamamlanan AKTS Kredi",
    completed_credit: "Tamamlanan Kredi",
    class_property: "Sınıf ",
    completed_dep_course_count: "Tamamlanan DEP Dersi",
    completed_dep_credit: "Tamamlanan DEP Kredisi",
    completed_dep_ects_credit: "Tamamlanan DEP AKTS",
    completed_gep_course_count: "Tamamlanan GEP Dersi",
    completed_gep_credit: "Tamamlanan GEP Kredisi",
    completed_gep_ects_credit: "Tamamlanan GEP AKTS",
    completed_non_dep_course_count: "Tamamlanan NOND Dersi",
    completed_non_dep_credit: "Tamamlanan NOND Kredisi",
    completed_non_dep_ects_credit: "Tamamlanan NOND AKTS",
    completed_required_course_count: "Tamamlanan Zorunlu Ders",
    completed_required_credit: "Tamamlanan Zorunlu Ders Kredi",
    completed_required_ects_credit: "Tamamlanan Zorunlu Ders AKTS",
    completed_slots: "Tamamlanan Slot",
    confirm: "Onayla",
    confirmation: "Onay",
    conflict_informations: "Çakışma Bilgileri",
    constants: "Sabitler",
    contact: "İletişim",
    contact_address_information: "Haberleşme Adresi Bilgileri",
    contact_information: "İletişim Bilgileri",
    contact_information_reports: "İletişim Bilgileri Raporlama",
    contact_number: "İletişim Numarası",
    contact_support: "İletişim Desteği",
    contents: "Sertifika İçeriği",
    continue: "Devam",
    contract_type: "Çalışma Tipi",
    coopcertificate: "Coop Sertifika",
    coopstudentcertificate: "Coop Sertifika Öğrenci Ekleme",
    coop_and_public_scholarship: "COOP ve Kamu Bursu",
    coop_scholarship: "COOP Bursu",
    course_selection_credit_limit: "Ders Rezervasyonu Kredi Limiti",
    count: "Adet",
    count_number: "Sıra No",
    countries: "Ülkeler",
    country: "Ülke",
    country_code: "Ülke Kodu",
    country_id: "Ülke",
    course: "Ders",
    course_name_en: "Ders Adı EN",
    certificate_upload: "Lütfen aldığınız sertifikayı aşağıdaki alana yükleyiniz.",
    course_import_text:
        "Kod, İsim, İsim ingilizce,İsim Türkçe, Kredi, Pratik Kredi, Teorik Kredi, Lab Kredi, AKTS Kredi, Tip, Dil, Fakülte Kod, Bölüm Kod, Program Kod, Aktif Durum,Not Skalası",
    course_merge_section: "Ders Birleştirme",
    course_code: "Ders Kodu",
    course_code1: "Kurs Kodu",
    course_coordinator: "Ders Koordinatörü",
    coordinator_approved: "Kordinatör Onayı",
    course_count_to_take: "Alınacak Ders Sayısı",
    course_detail_title: "Ders Bilgileri",
    course_field_is_required: "Dersler alanı zorunludur.",
    course_id: "Ders Id",
    course_language: "Ders Dili",
    course_name: "Ders Adı",
    course_pool_err: "Ders havuzda mevcuttur.",
    course_price: "Ders Ücreti",
    course_registration: "Ders Kayıt",
    course_registration_reservation: "Ders Rezervasyon",
    course_registration_semester: "Ders Seçme Dönemi",
    course_schedule: "Ders Programı",
    course_schedule_format:
        "Ders Kodu, Section, Kullanıcı ID, Derslik, Gün, Başlangıç Saat, Bitiş Saat",
    course_schedule_from_draft: "Ders Programı Otomatik Sınıf Yerleştirme",
    course_schedule_will_be_created_from_draft:
        "Ders programı sınıflara otomatik olarak yerleştirilecek. Emin misiniz?",
    course_status: "Ders Durumu",
    course_transfer: "İntibak",
    course_transfer_guide: "İntibak Kılavuzu",
    course_transfer_guide_filename: "Intibak-kilavuzu.pdf",
    course_transfer_evaluation: "İntibak Değerlendirme",
    course_transfer_success_text: "İntibak talebiniz başarıyla alınmıştır.",
    course_transfer_verify_text:
        "İntibak talebiniz iletilecektir, onaylıyor musunuz?",
    course_transfers_form: "İntibak Başvuru Formu",
    course_type: "Ders Tipi",
    courseprerequisites: "Ders Ön Koşulları",
    courses: "Dersler",
    courses_you_have_taken_so_for: "Almış Olduğunuz Dersler",
    create: "Oluştur",
    create_classes: "Sınıfları Oluştur",
    creating_a_thesis_section: "Tez Section Oluşturma",
    create_document: "Dökümanı Oluştur",
    create_pilotage_fee: "Pilotaj Ücreti",
    create_proforma_invoice: "Proforma Fatura Oluştur",
    create_report: "Raporu Oluştur",
    created_at: "Ekleme Zamanı",
    created_by: "Ekleyen",
    created_at_start: "Oluşturma Zamanı Başlangıç",
    created_at_end: "Oluşturma Zamanı Bitiş",
    creating_fykk_template: "FYKK Şablonu Oluşturma",
    credit: "Kredi",
    credit_card: "Kredi Kartı",
    credit_card_info: "Kredi Kartı Bilgileri",
    credit_card_name_surname: "Kredi Kartı Üzerindeki İsim",
    credit_card_number: "Kredi Kartı No",
    credit_limit: "Kredi Limiti",
    credit_price: "Kredi Ücreti",
    credit_x: "{credit} Kredi",
    course_drop_process: "Ders Düşürme İşlemi",
    credits_attempted: "Alınan Kredi",
    credits_earned: "Tamamlanan Kredi",
    currency: "Para Birimi",
    currency_rate: "Döviz Kuru",
    current_section: "Mevcut Section",
    current_password: "Mevcut Şifre",
    curriculum: "Müfredat",
    curriculums: "Müfredatlar",
    customer_group: "Müşteri Grubu",
    customer_type: "Müşteri Türü",
    cut_rate: "Kesinti Oranı",
    cancel_reason: "Red Sebebi",
    calling_situation: "Arama Statüsü",
    calling_next_date: "Gelecek Arama Tarihi",
    calling_status: "Arama Durumu",
    curriculum_credits: "Müfredat Kredisi",
    curriculum_ects_credit: "Müfredat AKTS Değeri",
    completed_ects_credit: "Tamamlanan AKTS Değeri",
    course_grade_entry_report: "Ders Bazlı Not Giriş Raporu",
    course_registration_is_confirmed: "Ders kaydı onaylandı",
    course_registration_is_rejected: "Ders kaydı reddedildi",
    course_and_slot_logs: "Yapılan Not ve Slot İşlemleri",
    calculate_extra_graduate_courses: "Mezuniyet Ek Dersleri",
    create_student_semesters: "Öğrenci Dönem Durumlarını Oluşturma",
    closed: "Kapalı",
    complete: "Tamamla",
    credit_end_date: "Kredi Bitiş Tarihi",
    credit_info: "Kredi Bilgisi",
    cancel_reason_graduation: "İptal Nedeni ve Mezuniyet/Ayrılma",
    course_faculty_name: "Dersin Fakülte Adı",
    course_department_name: "Dersin Bölüm Adı",
    course_program_code: "Dersin Program Kodu",
    comments: "Yorumlar",
    creditpayments_report: "Kredi Bazlı Ödeme Raporu",
    course_update_option_1: "Tüm Öğrencilere Uygula",
    course_update_option_2: "Devam Eden Öğrencilere Uygula",
    course_update_option_3: "Mezun Öğrencilere Uygula",
    course_update_type: "Güncelleme Hedefi",
    course_registration_without_payment_report: "Ödemesi Olmadığı Halde Dersi Olanlar Raporu",
    card_created: "Kart Oluşturuldu",
    card_not_created: "Kart Oluşturulmadı",
    created_card_info: "Bu öğrenciye {date} tarihinde kimlik oluşturulmuştur!",
    current_city: "İkamet Ettiği İl",
    current_district: "İkamet Ettiği İlçe",
    children_available: "Çocuk Durumu",
    create_password: "Parola Ver",
    contact_confirmations: "İletişim Onayları",
    corporation: "Şirket",
    corp_department: "Departman",
    cancel_reservation: "Rezervasyonu İptal Et",

    // D
    dormitory_application_form: "Unihall Başvuru Formu",
    documents_approved: "Dökümanlar Onaylandı",
    documents_waiting: "Dökümanlar Bekleniyor",
    document_upload: "Döküman Yükle",
    document_select: "Döküman Seçiniz",
    dashboard: "Dashboard",
    data: "Data",
    data_from_system_control: "Sistem Kontrolünden Gelen Data",
    date_info: "Tarih Bilgiler",
    date: "Tarih",
    date_between: "Tarih Aralığı",
    time_between: "Tarih Aralığı",
    date_of_birth: "Doğum Tarihi",
    day: "Gün",
    day_interval: "Gün Aralığı",
    days: "Günler",
    dean: "Dekan",
    dean_name: "Dekan Adı",
    dean_title: "Dekan Ünvanı",
    dean_title_en: "Dekan Ünvanı (EN)",
    debt: "Borç",
    decision: "Karar",

    decision_date: "Karar Tarihi",
    decision_number: "Karar Sayısı",
    decline: "Onaylama",
    decline_reason: "Onaylamama Nedeni",
    declined: "Reddedildi",
    definitive_registration_information: "Kesin Kayıt Bilgileri",
    degree_of_proximity: "Yakınlık Derecesi",
    delay: "Gecikme",
    delay_1: "Erteleme",
    delay_end_date: "Erteleme Bitiş Tarihi",
    delete: "Sil",
    delete_all: "Tümünü sil",
    default_role_changed: "Varsayılan rol değiştirildi",
    discharge_report_for_those_who_have_not_enrolled_in_courses: "Ders Kaydı Yapmayan Çap İlişik Kesme Raporu",
    delete_student_number: "Öğrenci numarası sil",
    delete_all_students: "Tüm Öğrencileri Sil",
    dep_course_count: "Tamamlanması Gereken DEP Dersi",
    dep_course_credit: "Tamamlanması Gereken DEP Kredisi",
    dep_course_ects_credit: "Tamamlanması Gereken DEP AKTS",
    department: "Bölüm",
    department_code: "Bölüm Kodu",
    department_codes: "Bölümler",
    department_name: "Bölüm Adı",
    departments: "Bölümler",
    detail: "Detay",
    details: "Detaylar",
    detailed_monthly_collection_report: "Detaylı Aylık Tahsilat Raporu",
    diploma: "Diploma",
    diploma_annexes: "Diploma Annexes",
    diploma_date: "Diploma Tarihi",
    diploma_information: "Diploma Bilgileri",
    diploma_name: "Diploma Adı",
    diploma_name_en: "Diploma Adı (EN)",
    diploma_number: "Diploma Numarası",
    diploma_number_from_system: "Diploma Numarasını Sitemden Getir",
    diploma_point: "Diploma Notu",
    diploma_point_system: "Diploma Not Sistemi",
    diploma_status: "Diploma Durumu",
    diploma_supplement: "Diploma Eki",
    diploma_supplement_status: "Diploma Eki Durumu",
    diploma_title: "Diploma Ünvanı",
    diploma_title_en: "Diploma Ünvanı (EN)",
    disability_rate: "Engellilik Oranı",
    disability_type: "Engellilik Türü",
    disciplinary_committee_decision: "Disiplin Kurulu Kararı",
    discipline_follow: "Disiplin Takibi",
    discipline_process: "Disiplin İşlemleri",
    discipline_registry: "Disiplin Sicil Belgesi",
    disciplinary_action: "Öğrencinin disiplin cezası vardır.",
    discount: "İndirim",
    discounts: "İndirim",
    discount_amount: "İndirim Miktarı",
    discount_rate: "İndirim Oranı",
    dispatch_postponement_certificate: "Sevk Tehir Belgesi",
    dispatch_postponement_request: "Sevk Tehir Talebi",
    display_systems: "Görüntüleme Sistemleri",
    distance_sales_agreement: "Mesafeli Satış Sözleşmesi",
    district: "İlçe",
    district_code: "İlçe Kodu",
    district_name: "Nüfus İlçe",
    districts: "İlçeler",
    dgs: "DGS",
    dgs_e: "DGS EK",
    make_application: "Başvuru Yap",
    do_cancel: "İptal Et",
    do_cancel_proccess: "Süreci İptal Et",
    do_payment: "Tahsil Et",
    do_withdraw: "Geri Çek",
    do_you_have_language_proficiency: "Hazırlık muafiyeti talebiniz olacak mı?",
    do_you_have_language_proficiency_missing:
        "Hazırlık muafiyeti talebinizi belirtiniz",
    do_you_verify_email: "E-Posta adresinizi güncellemek ister misiniz?",
    do_you_verify_mobile_no:
        "Cep telefon numaranızı güncellemek ister misiniz?",
    do_you_want_to_close_the_form_with_the_selected_interview_result:
        "Seçilen görüşme sonucu ile formu kapatmak istiyor musunuz?",
    do_you_want_to_complete_registration:
        "Kesin kayıt yapmak istediğinize emin misiniz?",
    do_you_want_to_print_receipt: "Makbuzu yazdırmak istiyor musunuz?",
    document: "Belge",
    document_add: "Belge Ekle",
    document_duae_date: "Belgenin Getirileceği Tarih",
    document_due_date: "Eksik Belge Getirme Tarihi",
    document_edit: "Belge Düzenleme",
    document_is_deleted: "Belge Silindi.",
    document_number: "Belge Numarası",
    document_request: "Belge Talep",
    document_request_cancel_statement:
        "Belge Talebiniz İptal Edilecektir! Onaylıyor musunuz?",
    document_request_title: "Belge Talep",
    document_requests_title: "Belge Talepleri",
    document_settings: "Belge Ayarları",
    document_show: "Belge Görüntüle",
    document_status: "Belge Durumu",
    document_type: "Belge Türü",
    document_type_id: "ID",
    document_type_name: "Belge Adı",
    documents: "Belgeler",
    documents_are_required: "Belge yükleyiniz!",
    documents_upload: "Belge yükleme",
    documents_upload_pending: "Başvurunuza belge yükleyerek devam edebilirsiniz",
    documents_university: "Belgelerin ait olduğu üniversite",
    domestic_application: "Yurtiçi Başvuru",
    double_major_application: "Çap Başvurusu",
    double_major_dismissal_report: "Çift Anadal İlişik Kesme Raporu",
    double_major_application_quotas: "Çap Başvuru Kotaları",
    double_major_applications: "Çap Başvuruları",
    double_major_termination_report: "ÇAP İlişik Kesme Raporu",
    double_major_application_start: "Çap Başvurusuna Başla",
    download: "İndir",
    download_data: "Bilgileri İndir",
    photos: "Fotograf",
    document_images: {
        "document-logo": "Belge Resmi",
        favicon: "Uygulama Tarayıcı İconu",
        background: "Giriş Sayfası Resmi",
        logo: "Giriş Sayfası Logosu",
        "menu-logo": "Menü Logosu",
    },

    download_photos: "Fotoğrafları İndir",
    download_report: "Raporu İndir",
    download_result_file: "Sonuç Dosyasını İndir",
    download_fail_log: "Hata Logu İndir",
    draft_info: "Taslak Bilgileri",
    dropped_lesson: "Çekildiğiniz Dersler",
    drop_file: "Dosyayı Buraya Bırakınız...",
    due_date: "Vade Tarihi",
    due_date_end: "Vade Tarihi Sonu",
    due_date_start: "Vade Tarihi Başlangıcı",
    duration: "Süre",
    document_not_found: "Belge Bulunamadı.",
    denizbank_education_credit: "Denizbank Eğitim Kredisi",
    dormitory: "Yurt",
    dormitory_status: "Yurt Barınma Durumu",
    diploma_ebys_send: "Diploma Ebys Gönderim",
    delay_do_date: "Ertelemenin Yapılacağı Tarih",
    document_verify: "Belge Doğrulama",
    document_verified: "belge doğrulandı.",
    document_download: "Belgeyi İndir",
    downloaded: "indirildi.",
    diploma_notebook: "Diploma Defteri",
    delete_file_upload_again: "Dosyayı silip tekrar yükleyiniz",
    data_filter: "Data Filtre",
    double_major_yok_report: "Çap Denetim Raporu",
    death_status: "Vefat Durumu",
    died: "Vefat Etti",
    do_you_think_to_do_master_in_corp: "Kurumda Yüksek Lisans Yapmak İstiyor musunuz?",
    do_you_want_to_transfer_next_installments: "Fazla tutarı sonraki taksitlere aktarmak istiyor musunuz?",

    // E
    except_roles: "Gizlenecek Roller",
    enable_editing: "Düzenlemeyi Etkinleştir",
    close_editing: "Düzenlemeyi Kapat",
    edit_end_date: "Düzenleme Bitiş Tarihi",
    exempt: "Muaf",
    earned_degree: "Kazanılan Derece",
    e_registration_system: "Online Kayıt Sistemi",
    e_registration_system_info:
        "* Eğer kayıt dondurma talebiniz varsa kaydınızı tamamladığınızda talebiniz oluşturulacaktır. Kullanıcı adı ve şifre ile UMİS'e giriş yaparak 'Taleplerim' uygulamasının içindeki 'Kayıt Dondurma' sayfasından talep durumunuzu kontrol edebilirsiniz.",
    early_discount_rate: "Erken Ödeme İndirim Oranı",
    ebys_status: "EBYS Durum",
    ebys_send: "EBYS Gönder",
    ebys_send_statement:
        "Talep EBYS sistemine gönderilecektir! İşlemi onaylıyor musunuz?",
    ebys_type_id: "EBYS Belge Tip ID",
    ects: "AKTS",
    ects_credit: "AKTS",
    ects_credit_of_uncompleted_slots: "Tamamlanmayan Slotların AKTS Kredisi",
    ects_credits_attempted: "Alınan AKTS Kredi",
    ects_credits_earned: "Tamamlanan AKTS Kredi",
    edit: "Düzenle",
    edit_classrooms: "Derslik Listesini Düzenle",
    edit_due_date: "Vade Tarihi Düzenle",
    edit_grade_conversion: "Not Skalasını Düzenle",
    edit_option: "Seçenek Düzenle",
    edit_payment: "Ödeme Düzenle",
    edit_preference: "Tercih Düzenle",
    edit_question: "Soru Düzenle",
    edit_section: "Section Düzenle",
    edit_part: "Bölüm Düzenle",
    edit_slot: "Slot Düzenle",
    education: "Öğrenim",
    education_credits_report: "Öğrenim Kredileri Raporu",
    education_and_exam_info: "Eğitim/Sınav Bilgileri",
    education_and_exam_reqired: "Eğitim Bilgileri alanı zorunludur!",
    education_credit: "Eğitim Kredisi",
    education_delete_info:
        "Seçilen Eğitim Bilgisini silmek üzeresiniz. İşleme devam etmek istiyor musunuz?",
    education_info: "Eğitim Bilgileri",
    education_information_reporting: "Öğrenim Bilgisi Raporlama",
    education_information: "Öğrenim Bilgisi",
    education_line_cancel: "Eğitim Bilgilerinden çıkarmak için tıklayınız.",
    education_status: "Öğrenim Durumu",
    educator_report: "Öğretmen Raporu",
    effect_gpa: "Gno Etkiliyor mu ?",
    effect_ratio: "Etki Oranı",
    elective_pool: "Seçmeli Havuz",
    elective_pools_course_delete_confirm_text:
        "Seçmeli Havuz Ders kaydını silmek üzeresiniz, işlemi onaylıyor musunuz?",
    elective_pools_delete_confirm_text:
        "Seçmeli Havuz kaydını silmek üzeresiniz, işlemi onaylıyor musunuz?",
    elective_pools_form: "Seçmeli Havuz Ekleme",
    elective_pools_info: "Seçmeli Havuz Bilgileri",
    elective_pools_report: "Seçmeli Havuz Raporu",
    elective_pools_store_confirm_text:
        "Seçmeli Havuz kaydını eklemek üzeresiniz, işlemi onaylıyor musunuz?",
    elective_pools_title: "Seçmeli Havuz",
    elective_pools_update: "Seçmeli Havuz Güncelleme",
    email: "E-Posta",
    email_address: "E-Posta Adresi",
    email_of_advisor: "Danışman E-Postası",
    email_placeholder: "E-Posta Adresinizi Giriniz",
    email_update: "E-posta Güncelle",
    email_verify_not: "E-posta adresinizi doğrulamanız gerekmektedir!",
    email_send: "E-posta Gönder",
    emergency_contact_txt: "Acil Durumlarda İletişime Geçilecek Kişi",
    emergency_person: "Acil Durumlarda Aranacak Kişi",
    emergency_person_phone: "Acil Durumlarda Aranacak Kişi No",
    empty_classrooms: "Boş Derslikler",
    empty_data_on_table: "Tablodaki alanları doldurunuz!",
    end: "Bitiş",
    end_date: "Bitiş Tarihi",
    end_time: "Bitiş Saati",
    endorse: "Ciro Et",
    english: "İngilizce",
    enter_attendance: "Yoklama Gir",
    enter_explanation: "Açıklama giriniz",
    entry_document: "Giriş Belgesi",
    entry_score: "Giriş Puanı",
    entry_score_type: "Giriş Puan Türü",
    entry_section: "Section Giriniz",
    enter_value: "Değer Giriniz",
    epayment: "Online Ödeme",
    epayments_report: "Online Ödeme Raporu",
    online_payment_agreement: "Online Ödeme Sözleşmesi",
    error: "Hata",
    error_min_0_max_100: "Girdiğiniz değer 0-100 arasında olmalıdır.",
    equal_weight_score: "Eşit Ağırlık",
    equivalence_status: "Denklik Durumu",
    equivalence_information: "Denklik Bilgileri",
    evaluation: "Değerlendirme",
    evaluation_type: "Sınav",
    event_owner: "Etkinlik Sahibi",
    event_type: "Etkinlik Türü",
    exam: "Sınav",
    exam_report: "Sınav Tutanağı",
    exam_report_all_section: "Sınav Tutanağı (Tüm Sectionlar)",
    exam_report_list: "Sınav Tutanak Listesi",
    exam_application_start_date: "Sınav Başvuru Başlangıç Tarihi",
    exam_application_to_date: "Sınav Başvuru Bitiş Tarihi",
    exam_averages: "Sınav Ortalamaları",
    exam_capacity: "Sınav Kapasitesi",
    exam_date: "Sınav Tarihi",
    exam_delete_info:
        "Seçilen Sınav Bilgisini silmek üzeresiniz. İşleme devam etmek istiyor musunuz?",
    exam_effect_ratio: "Sınav Etki Oranı",
    exam_info: "Sınav Bilgileri",
    exam_line_cancel: "Sınav Bilgilerinden çıkarmak için tıklayınız.",
    exam_method: "Sınav Metodu",
    exam_name: "Sınav Adı",
    exam_parts: "Sınav Bölümleri",
    exam_schedule1: "Sınav Programı",
    exam_schedule: "Sınav Takvimi",
    exam_schedule_format:
        "Ders Kodu, Section, Kullanıcı ID, Derslik, Sınav Tipi Tarih, Başlangıç Saat, Bitiş Saat",
    exam_schedule_id: "Sınav Programı ID",
    exam_schedule_available_time: "Sınav Programı İçin Uygun Saat Bulma",
    exam_score: "Sınav Notu",
    exam_status_report: "Sınav Giriş Durumları",
    exam_type: "Sınav Türü",
    exam_time: "Sınav Süresi",
    exams: "Sınavlar",
    exams_to_take: "Gireceği sınavlar",
    excel: "Excel",
    excel_file: "Excel Dosya",
    excel_format: "Excel Deseni",
    excluded_program_codes: "Hariç Tutulan Programlar",
    excluded_registration_types: "Hariç Tutulan Kayıt Türleri",
    excuse_file: "Mazeret Belgesi",
    excuse_letter: "Mazeret Belgesi",
    expand_all: "Hepsini Genişlet",
    expiry_month: "SKT Ay",
    expiry_year: "SKT Yıl",
    explanation: "Açıklama",
    explanation_en: "İngilizce Açıklama",
    extend: "Uzatmalı",
    external_application: "Kurumlar Arası",
    extra_installment: "Ek Taksit",
    extra_time: "Ekstra Süre",
    exam_overlap_report: "Sınav Çakışma Raporu",
    external_course: "Harici Ders",
    early_payment_report: "Erken Kayıt Raporu",
    equivalence_decision_date: "Denklik Karar Tarihi",
    equivalence_serial_number: "Denklik Seri No",
    ebys_id: "Ebys Id",
    extension: "Uzatma",
    error_student_status_not_contiune:
        "Öğrencilik durumu devam olmadığından bu sayfaya giriş yapamazsınız",
    exchange_university_name: "Değişim Üniversite",
    event_switch: "Etkinlikleri",
    event_show: "göster",
    event_hide: "gösterme",
    exception_logs: "Hata Logları",
    explanation_for_sms: "Açıklama (Bu bilgi sms ile gönderilmeyecek)",
    exam_placement_type: "Yerleşim Tipi",

    // F
    first_password_get: "İlk Şifreni Al",
    faculties: "Fakülteler",
    faculty: "Fakülte",
    faculty_grade_entry_report: "Fakülte Bazlı Not Giriş Raporu",
    faculty_course_grade_entry_report: "Fakülte Ders Bazlı Not Giriş Raporu",
    faculty_code: "Fakülte Kodu",
    faculty_code_name: "Fakülte Kodu / Adı",
    faculty_name: "Fakülte Adı",
    faculty_name_en: "Fakülte Adı EN",
    faculty_of_choice: "Tercih Fakülte",
    faculty_rank: "Fakülte Sıralaması",
    failed_data_count: "Başarısız Sayısı",
    fall: "Güz",
    fall_and_spring: "Güz ve Bahar",
    fall_credit: "Güz Kredisi",
    false: "Yanlış",
    family: "Aile",
    family_info: "Aile Bilgileri",
    family_information: "Aile Bilgileri",
    fast_transactions: "Hızlı İşlemler",
    father: "Baba",
    father_gsm: "Babanın İletişim Telefonu",
    father_job: "Babanın Mesleği",
    father_name: "Baba Adı",
    father_name_placeholder: "Baba Adı Giriniz",
    fathers_education: "Babanın Eğitim Durumu",
    fathers_gsm: "Babanızın İletişim Telefonu",
    fathers_job: "Babanızın Mesleği",
    fees_and_installments: "Ücretler ve Taksitler",
    female: "Kadın",
    fetch_document: "Belge Getir",
    fields_are_reqired: "Alanlar zorunludur!",
    file: "Dosya",
    field: "Alan",
    file_name: "Dosya Adı",
    file_name_course_schedule: "ders-programi",
    file_name_dispatch_postponemen: "sevk-tehir-belgesi",
    file_name_diploma: "Diploma Belgesi",
    file_name_diploma_annexes: "Diploma Ekleri",
    file_name_english_placement_exam: "seviye-belirleme-giris-belgesi",
    file_name_exam_schedule: "sinav-programi",
    file_name_language_proficiency: "dil-yeterlilik-belgesi",
    file_name_military_status: "asketlik-durum-belgesi",
    file_name_missing_documents_report: "eksik-belgeler-raporu",
    file_name_new_password: "yeni-sifre",
    file_name_prep_transcript: "hazirlik-not-belgesi",
    file_name_school_certificate: "karne",
    file_name_student_certificate: "ogrenci-belgesi",
    file_name_student_information_system: "ogrenci-bilgi-sistemi",
    file_name_student_notes_report: "ogrenci-notlari-raporu",
    file_name_transcript: "transcript",
    file_name_user_guide: "kullanici-kılavuzu",
    file_name_welcome_letter: "hosgeldin-mektubu",
    file_upload_not_1:
        "Hazırlık muafiyet belgesine sahip öğrencilerimizin, bu belgesini <a href=mailto:kayit@sa.bau.edu.tr>kayit@sa.bau.edu.tr</a> adresine göndermesi gerekmektedir.",
    file_upload_not_2:
        "Kayıt dondurmak veya isteğe bağlı hazırlık okumak isteyen öğrencilerimizin de <a href=mailto:kayit@sa.bau.edu.tr>kayit@sa.bau.edu.tr</a> adresinden iletişime geçerek bilgi vermesi gerekmektedir.",
    file_upload_not_3:
        "Online kayıt işlemi sırasında teknik bir sorun ile karşılaşmanız durumunda <a href=mailto:ekayit@bau.edu.tr>ekayit@bau.edu.tr</a> adresinden iletişime geçebilirsiniz.",
    files: "Dosyalar",
    fill_at_least_one_field: "En az bir alanı doldurunuz",
    filter1: "Filtre",
    filter: "Filtrele",
    filter_type: "Filtre Türü",
    filter_value: "Filtre Değeri",
    final_letter_grade: "Final Harf Notu",
    finalize_the_application: "Başvuryu Sonlandır",
    finance_offset: "Mahsup",
    finance_reports: "Finans Raporları",
    find: "Bul",
    find_student: "Öğrenci Bul",
    finished_class: "Geldiği programda bitirdiği sınıf?",
    first_installment: "İlk Taksit",
    first_student_count: "İlk Defa Alan <br>Öğrenci Sayısı",
    force_education_price: "Eğitim Ücreti Yükle",
    foreign_document_number: "Yabancı Kimlik No",
    foreign_student_community_internship_assignment: "Yabancı Uyruklu Öğrenci Toplu Staj Ataması",
    foreign_document_type: "Yabancı Kimlik Türü",
    foreign_status: "Yabancı Uyruk",
    foreign_student: "Uluslararası Öğrenci",
    foreign_student_credentials: "Uluslarası Öğrenci Bilgileri",
    foreign_student_identity: "U.Ö. Kimlik",
    forgot_my_password: "Şifremi Unuttum",
    friday: "Cuma",
    from_score: "Başlangıç Puanı",
    from_email: "Aktarılan E-Posta",
    frozen_semesters: "Ceza Uygulanacak Dönemler",
    full_name: "Adı Soyadı",
    full_scholarship_students_enroll: "Ders Kayıt Onayı",
    future_payment: "Vade Öteleme",
    fykk_template: "FYKK Şablonu",
    first_value_big_from_second: "İlk değer ikinci değerden büyük olmamaz!",
    finalize_grades: "Final Notu Kesinleştirme",
    from_where: "Nereden",
    from: "Kimden",
    first_time: "İlk Defa",
    filters: "Filtreler",
    filter_filter: "Kriter Filtre",
    foreign_student_q: "Yabancı Uyruklu mu?",
    forward: "ileri",
    final_grade_entry: "Final Not Girişi",
    foreign_student_report: "Yabancı Öğrenci Raporu",

    // G
    gateway: "Gateway",
    gender: "Cinsiyet",
    general: "Genel",
    general_information: "Genel Bilgiler",
    gep_course_count: "Tamamlanması Gereken GEP Dersi",
    gep_course_credit: "Tamamlanması Gereken GEP Kredisi",
    gep_course_ects_credit: "Tamamlanması Gereken GEP AKTS",
    get_report: "Raporu Getir",
    give_exception_payment_approval: "İstisna Ödeme Onayı Ver",
    giveable_roles: "Verilebilir Roller",
    gna_not_range: "GNO Not Aralığı",
    gno: "Genel Not Ortalaması",
    gno_simulation: "GNO Simülasyon",
    gno_carculate: "Genel Not Ortalaması - Hesapla",
    gpa: "DNO",
    gpa_control_report: "GNO Kontrol Raporu",
    grade: "Not",
    grand_total: "Genel Toplam",
    grade_conversion_define_tabel: "Not Skalası Tanım Tablosu",
    grade_conversion_define_title: "Not Skalası Tanımlama Formu",
    grade_conversion_id: "Skala ID",
    grade_conversion_info: "Not Skalası Bilgileri",
    grade_conversion_name: "Skala Adı",
    grade_conversion_range_end: "Not Aralığı Bitiş",
    grade_conversion_range_start: "Not Aralığı Başlangıç",
    grade_conversion_table_title: "Not Skalası Tablosu",
    grade_conversion_update_title: "Not Skalası Güncelleme Formu",
    grade_conversion_update_verify_text:
        "Not Skalası Tanım Tablosun a ekleme yapmak üzeresiniz, onaylıyor musunuz?",
    grade_conversion_verify_text:
        "Not Skalası Tanım Tablosuna ekleme yapmak üzeresiniz, onaylıyor musunuz?",
    grade_conversion_verify_text_2:
        "Not Skalası Tanım Tablosu veritabanına kaydedilecektir, onaylıyor musunuz?",
    grade_entry: "Not Girişi",
    grade_entry_confirmation_modal:
        "Not Girişleriniz kaydedilecektir, Onaylıyor musunuz?",
    grade_id: "Harf Notu",
    grade_is_deleted_text: "Not skalası tanımı silindi.",
    grade_scala: "Not Skalası",
    grade_table_is_empty: "Not Skalası Tanım Tablosu boş.",
    grade_up_lessons: "Not Yükseltme Dersleri",
    grade_system: "Not Sistemi",
    grade_systems: "Not Sistemleri",
    grades: "Notlar",
    grading_system: "Not Sistemi",
    grading_systems: "Not Sistemleri",
    grading_system_id: "Not Sistemi ID",
    graduate: "Mezun",
    graduate_application_after_verify_info:
        "Başvurunuzun herhangi bir sebeple yarım kalması halinde tarafınıza gönderilen SMS Kodu ile <a href=login>Başvuru Güncelleme</a> adresinden başvurunuza devam edebilirsiniz.",
    graduate_application_form: "Lisansüstü Başvuru Formu",
    graduate_application_form_update_btn: "Başvuru Formunu Güncelle",
    graduate_application_yok_statement:
        "(Yurtdışı üniversitelerinden mezun olan adaylar YÖK Denklik Belgelerini diğer belgeler kısmından yükleyebilirler.)",
    graduate_applications: "Lisansüstü Başvuruları",
    graduate_period: "Mezuniyet Dönem",
    graduate_date: "Mezuniyet Tarihi",
    graduate_semester: "Mezuniyet Sezon",
    graduate_detail_cpga: "Detay GNO",
    graduation_academic_year: "Mezun Olduğu Sezon",
    graduation_academic_year_season: "Mezun Olduğu Sezon",
    graduation_average: "Mezuniyet Ortalaması",
    graduation_date: "Mezuniyet Tarihi",
    graduation_date_between: "Mezun Tarih Aralığı",
    graduation_decision_date: "Mezuniyet Karar Tarihi",
    graduation_decision_number: "Mezuniyet Karar Numarası",
    graduation_degree: "Mezuniyet Derecesi",
    graduation_information: "Mezuniyet Bilgieri",
    graduation_semester: "Mezun Olduğu Dönem",
    graduation_status: "Mezuniyet Durumu Kontrolü",
    graduation_university: "Mezun Olunan Üniversite",
    graduation_year: "Mezuniyet Yılı",
    graduate_full_scholarship_ratio_report:
        "Lisansüstü Tam Burslu Oranları Raporu",
    graduate_number_of_students_and_collection_report: "Lisansüstü Öğrenci Sayıları ve Ciro Raporu",
    gross_price: "Tam Ücret",
    gsm_no: "Cep Telefonu",
    guarantee1: "Ödeyecek",
    guarantee1_address: "Ödeyecek Adres",
    guarantee1_national_id: "Ödeyecek TC Kimlik No",
    guarantee1_phone: "Ödeyecek Telefon",
    guarantee2: "Garantör",
    guarantee2_address: "Garantör Adres",
    guarantee2_national_id: "Garantör TC Kimlik No",
    guarantee2_phone: "Garantör Telefon",
    guarantee: "Kefil",
    guarantee_address: "Kefil Adres",
    guarantee_national_id: "Kefil TC Kimlik No",
    guarantee_phone: "Kefil Telefon",
    graduate_app_regular_student: "Normal Öğrenci",
    graduate_app_private_student: "Özel Öğrenci",
    graduate_app_scientific_preparation_student: "Bilimsel Hazırlık Öğrencisi",
    graduate_information: "Mezuniyet Bilgileri",
    guide_code: "Kılavuz Kodu",
    guide: "Kılavuz",
    graduate_inquiry: "Mezun Sorgulama",
    guardian_national_id: "Veli TC Kimlik No",
    guardian_mobile_tel: "Veli Cep Telefonu",
    gpa_calculate: "Ortalama Hesapla",
    gpa_calculate_info_text: "Öğrencinin not ortalaması yeniden hesaplanacaktır. İşlemi onylıyor musunuz?",
    gpa_calculate_success: "Ortalama başarıyla hesaplanmıştır! Yeni Ortalama: ",
    graduation_requirements: "Mezuniyet Gereksinimleri",
    graduation_ects_credit: "AKTS Kredi",
    graduation_credit: "Kredi",
    graduation_dep: "Bölüm İçi Seçmeli Sayısı",
    dep_credit: "Bölüm İçi Kredi",
    dep_ects_credit: "Bölüm İçi AKTS Kredi",
    graduation_non_dep: "Bölüm Dışı Seçmeli Sayısı",
    non_dep_credit: "Bölüm Dışı Kredi",
    non_dep_ects_credit: "Bölüm Dışı AKTS Kredi",
    graduation_general: "Genel Seçmeli Sayısı",
    general_credit: "Genel Seçmeli Kredi",
    general_ects_credit: "Genel Seçmeli AKTS Kredi",
    graduate_requirement_info: "Mezuniyet Gereksinimleri",
    graduate_requirement_info_short: "Mez. Gereksin.",
    graduates: "Mezunlar",
    graduate_card_create: "Mezun Kimlik Oluşturma",
    graduate_date_in: "Mezun Olduğu Tarih",
    graduation_scholarship_status: "Mezun Bursu Aldı mı?",
    graduate_info: "Mezun Bilgileri",
    graduate_email: "Mezun E-posta",
    graduate_card_status: "Mezun Kart Durumu",
    graduate_decision_date:"Mezun Karar Tarihi",
    graduate_email_status: "Mezun E-posta Durumu",

    // H
    have_student_program: "Öğrenci kaydı var. Buradan işlem yapmayınız.",
    has_english_cert: "Hazırlık muafiyeti talebiniz olacak mı?",
    have_payment_approval: "Ödeme Onayı",
    have_you_leave_of_absence_before: "Daha önce kayıt dondurdu mu?",
    head_of_department: "Bölüm Başkanı",
    head_of_program: "Koordinatör",
    health: "Sağlık",
    health_information: "Sağlık Bilgileri",
    hello: "Merhaba",
    help: "Yardım",
    help_videos: "Yardım Videoları",
    hes_code: "HES Kodu",
    home_address: "Ev Adresi",
    home_number: "Sabit Ev Telefonu",
    home_tel: "Ev Telefonu",
    horizontal_or_vertical_attended_university:
        "Yatay/Dikey Gittiği Üniversite",
    horizontal_or_vertical_attended_university_1:
        "Yatay/Dikey <u>Gittiği</u> Üniversite",
    horizontal_or_vertical_coming_from_university:
        "Yatay/Dikey Geldiği Üniversite",
    horizontal_or_vertical_coming_from_university_1:
        "Yatay/Dikey <u>Geldiği</u> Üniversite",
    hour: "Saat",
    hours: "Saatler",
    hybrid: "Hibrit",
    high_honor: "Yüksek Onur Süreci",
    high_honor_status: "Onur Durumu",

    // I
    insufficient_limit: "Limit yetersiz",
    iban: "IBAN",
    id: "Id",
    identity: "Kimlik",
    identify: "TC ya da Pasaport Numarası",
    identity_information: "Kimlik Bilgileri",
    image_upload: "Resim Yükleme",
    import: "İçeri Aktar",
    import_button: "İçeri Aktar",
    import_course_schedule: "Ders Programı Yükle",
    import_draft: "Taslak İçeri Aktarma",
    import_exam_schedule: "Sınav Programı Yükle",
    import_photos: "Fotoğrafları Aktar",
    in_class: "Yüz yüze",
    in_house_application: "Kurum  İçi",
    included_registration_types: "Dahil Edilen Kayıt Türleri",
    indefinite: "Süresiz",
    info_file: "Bilgi Dosyası",
    info_link: "Bilgi Linki",
    info_text: "Bilgilendirme Metni",
    information: "Bilgilendirme",
    input_type: "Giriş Türü",
    installment: "Taksit",
    installment_amount: "Taksit Tutarı",
    installment_count: "Taksit Sayısı",
    installment_options: "Taksit Sayısı",
    installment_payment: "Taksitli Ödeme",
    installment_payment_type: "Taksitli Ödeme Türü",
    installment_start_date: "Taksit Tarih Başlangıcı",
    installments: "Taksitler",
    installments_total: "Taksit Toplamı",
    installment_number: "Taksit Numarası",
    instructor: "Eğitmen",
    instructor_can_use: "Eğitmen Kullanabilir",
    instructor_courses_title: "Verdiğim Dersler",
    instructor_name: "Eğitmen İsim",
    instructor_surname: "Eğitmen Soyisim",
    instructors: "Eğitmenler",
    instructor_letter_grade_excel_export: "Öğretim Elemanı Not Excel Raporu",
    instructor_attendance: "Öğretim Elemanı Yoklama Takip ",
    intership_information: "Staj Bilgisi",
    invalid_course_coordinator: "Çalışan bulunamadı",
    invalid_student: "Öğrenci Bulunamadı",
    invalid_value: "Hatalı Değer",
    invalid_values: "Hatalı Değerler",
    invalid_national_id: "Geçersiz TC kimlik no",
    invert: "Tersi",
    investigation_report: "Soruşturma Raporu",
    invitation_to_the_defense: "Savunmaya Davet",
    invoice1: "Fatura 1",
    invoice2: "Fatura 2",
    invoice3: "Fatura 3",
    invoice4: "Fatura 4",
    invoices: "Faturalar",
    irregular: "Irregular",
    irregular_status: "Irregular Durumu",
    is_before_undergraduate_transfer: "Daha önce yatay geçiş yaptınız mı?",
    is_before_undergraduate_transfer_type: "Hangi yatay geçiş tipini yaptınız?",
    is_default: "Varsayılan mı?",
    is_graduate: "Mezun mu?",
    is_numeric: "Sayısal mı?",
    is_paid: "Ödendi Mi",
    is_passing: "Geçti mi ?",
    is_rate: "Oran",
    is_required: "Zorunlu Mu?",
    is_revert: "Ters mi?",
    is_there_a_header: "Başlık var mı?",
    is_take_exam: "Sınav Giriş",
    is_exam_time_lesson: "Sınav Zamanı Ders mi",
    is_additional_classroom: "Ek Derslik mi",
    is_one_row: "Tek Satır",
    internship_remaining_count: "Kalan Staj Sayısı",
    i_approve: "Onaylıyorum",
    i_not_approve: "Onaylamıyorum",
    inbox: "Gelen Kutusu",
    instructors_letter_grade: "Kesinleşmeyen Harf Notu",
    import_extra_payments: "Ek Ödemeleri Yükle",
    extra_payment: "Ek Ders Ödeme",
    issue_date: "Veriliş Tarihi",
    info: "Bilgi",
    in_life: "Sağ",
    include_cancelled: "İptalleri Dahil Et",
    installment_options_info: "Bazı ticari banka kartlarına (+) taksit kampanyası uygulanmayabilir. Önce bankanız ile irtibat kurunuz.",
    i_have_read_understood_and_approve: "Okudum, anladım ve onaylıyorum",

    // J
    job: "Meslek",
    job_address: "İş Adresi",
    job_and_contact_info: "İş/İletişim Bilgileri",
    job_corporation: "Çalıştığı Kurum",
    job_department: "Çalıştığı Birim",
    job_experience: "İş Deneyimi",
    job_experience_desc: "(Varsa önceki iş deneyiminiz ile birlikte toplam süre olarak belirtiniz.)",
    job_info: "İş Bilgileri",
    job_other: "Diğer Meslek",
    job_sector: "Çalıştığı Sektör",
    job_tel: "İş Telefonu",
    jobs: "İşler",
    joining_place: "Birleştirme Yeri",
    just_active: "Sadece Aktif Öğrenciler",
    job_prep_student_period_status_create: "Hazırlık Öğrenci Dönem Durumu Oluşturma",
    job_information: "Mesleki Bilgiler",

    // K
    key: "Anahtar Alan",
    kvkk_modal_title: "Kişisel Verilerin İşlenmesine Dair Aydınlatma Metni",
    kyk_information: "KYK Bilgileri",
    kvkk_data_approve:
        "Kişisel verilerin korunumu ve <strong>açık rıza metnini okudum</strong>, anladım kabul ediyorum.",
    kvkk_content_text: `Bu metin, veri sorumlusu Bahçeşehir Konaklama ve Turizm Yatırımları Anonim Şirketi’nin (“Bahçeşehir Konaklama” veya "Şirket") kişisel verilerinizi nasıl işlediği hakkında sizleri bilgilendirmeyi amaçlamaktadır. Kişisel verilerinizin işlenmesine ilişkin konular, aşağıda başlıklar halinde sıralanmıştır. <br>
    	Kişisel verilerim hangi amaçlarla ve hangi hukuki sebeplere dayanılarak işleniyor? <br>
    Kişisel Verilerin Korunması Kanunu’nun (“Kanun”) 5. ve 6. maddeleri, kişisel veri işlemenin hukuki sebeplerini düzenlemektedir. Aşağıda kişisel verilerinizi hangi amaçlarla işlediğimize ve söz konusu amaçlar kapsamında hangi hukuki sebeplere dayandığımıza yer verdik:<br>
    Kişisel verileriniz, Kanunlarda açıkça öngörülmesi ile Şirket’in hukuki yükümlülüklerini yerine getirmesi için kişisel veri işlemenin zorunlu olması hukuki sebeplerine dayalı olarak aşağıdaki amaçlarla işlenmektedir:<br>
    •	Yetkili kurum veya kuruluşların Şirketimizden talepte bulunması ya da bu kurumlara bildirim yapmamızın öngörüldüğü durumlarda, yasal yükümlülüklerimizin yerine getirilmesi (örneğin, Kimlik Bildirme Kanunu kapsamında yurtta kalanlara ilişkin zorunlu kimlik bildirim yükümlülüğünün yerine getirilmesi),
    •	Faturalama dahil mevzuattan kaynaklı yükümlülüklerimizin yerine getirilmesi.<br>
    Kişisel verileriniz, bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması hukuki sebebine dayalı olarak aşağıdaki amaç ile işlenmektedir:<br>
    •	Öğrencilerin yurtlara yerleştirilme ve kabul süreçlerinin yürütülmesi,
    •	Konaklama hizmetlerinin sunumu için kaydınızın oluşturulması ve sözleşmeden doğan yükümlülüklerimizin yerine getirilmesi,
    •	Yurt ödemelerinin ve tahsilat işlemlerinin gerçekleştirilebilmesi.<br>
    Kişisel verileriniz, bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması hukuki sebebine dayalı olarak aşağıdaki amaçlarla işlenmektedir:

    •	Olası bir hukuki uyuşmazlık durumunda, haklarımızın korunabilmesi ve kullanılması ile hukuki süreçlerin ve disiplin işlemlerinin yürütülmesi.<br>
    Kişisel verileriniz, ilgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, Şirket’in meşru menfaatleri için veri işlenmesinin zorunlu olması hukuki sebebine dayalı olarak aşağıdaki amaçlarla işlenmektedir:<br>
    •	İletişim süreçlerinin yürütülmesi; talep ve şikayetlerin alınması ve sonuçlandırılması,
    •	Yurtlarımızın ve hizmetlerimizin güvenliğinin sağlanması; yetkisiz giriş-çıkışların engellenmesi,
    •	Şirket politika ve kurallarına uyumluluğun sağlanması; denetim ve yönetim faaliyetlerinin yürütülmesi.<br>
    Kişisel verileriniz, açık rıza vermiş olmanız halinde aşağıdaki amaçlarla işlenmektedir:<br>
    •	Adli sicil kaydı ve sağlık bilgileri için; konaklama hizmetlerinden yararlanacak kişiler için sağlık ve güvenlik kontrollerinin gerçekleştirilmesi, yurda yerleştirme ve kabul süreçlerinin yönetilmesi ve yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.


    	<strong>Hangi kişisel verilerim işleniyor? </strong><br>
    Bahçeşehir Konaklama olarak, yukarıda belirtilen amaçlar doğrultusunda, kimlik, iletişim, eğitim-öğrenim bilgilerinizi, konaklama bilgilerinizi, finansal bilgilerinizi, yakınlarınıza/acil durumlarda ulaşılacak kişilere ilişkin bilgiler, yabancı öğrenciler için oturum izin bilgileri ile sağlık ve adli sicil kaydı bilgilerinizi işliyoruz. <br>
    <strong>Kişisel verilerim kimlere ve hangi amaçlarla aktarılıyor?</strong><br>
    Kişisel verilerinizi, “Kişisel verilerim hangi amaçlarla ve hangi hukuki sebeplere dayanılarak işleniyor?” başlığındaki amaçların yerine getirilebilmesi için, Kanun’un 5. ve 6. maddelerinde belirtilen hukuki sebeplere dayalı olarak, Kanun’un 8. ve 9. maddelerinde belirtilen kişisel verilerin aktarılmasına ilişkin kurallar dahilinde; iş ortaklarımız, tedarikçiler ve yetkili kişi, kurum veya kuruluşlar ile paylaşıyoruz.<br>
    <strong>Kişisel verilerim nasıl ve hangi hukuki sebeplere dayalı olarak toplanıyor? </strong><br>
    <strong>Kişisel verilerinizi aşağıdaki kanallar ve yöntemler aracılığıyla topluyoruz:</strong> <br>
    <strong>Kişisel verilerinizi toplama kanalları:</strong> Fiziki ya da elektronik ortamlarda form doldurmanız veya bilgilerinizi bizimle paylaşmanız; telefon, e-posta, sosyal medya hesapları üzerinden bizimle iletişime geçmeniz halinde belirtilen kanallar aracılığıyla kişisel verileriniz toplanmaktadır.<br>
    <strong>Kişisel verilerinizi toplama yöntemleri:</strong> Kişisel verileriniz, kısmen otomatik, otomatik veya veri kayıt sisteminin parçası olmak kaydı ile otomatik olmayan yöntemler ile toplanmaktadır.

    Kişisel verilerinizi Kanun’un 5. ve 6. maddelerinde düzenlenen kişisel veri işleme şartlarından;  sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması, bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması, ilgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla veri işlemenin meşru menfaatlerimiz için zorunlu olması, veri işlemenin kanunlarda açıkça öngörülmesi, veri işlemenin veri sorumlusunun hukuki yükümlülüklerini yerine getirebilmesi için zorunlu olması ile açık rızanızı vermeniz halinde açık rızanızın bulunması sebeplerine dayalı olarak topluyoruz.<br>
    	Kişisel veri sahibi olarak haklarımı nasıl kullanabilirim?
    Kanun’un 11. maddesi kişisel verileri işlenen gerçek kişilerin haklarını düzenlemektedir. Bu madde uyarınca aşağıdaki haklara sahipsiniz:<br>
    •	Kişisel verinizin işlenip işlenmediğini öğrenme,
    •	Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
    •	Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
    •	Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,
    •	Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,
    •	Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerinizin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,
    •	İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,
    •	Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme.<br>
    Haklarınız ile ilgili taleplerinizi bize iletmek için, Abbasağa Mah. Ihlamur Yıldız Cad. No. 8/1A Beşiktaş/İstanbul adresine taleplerinizi içeren bir posta gönderebilirsiniz. Ayrıca, Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ’de belirtilen yöntemleri de kullanabilirsiniz.

    <strong>Kişisel Verilerin İşlenmesine Dair Açık Rıza Metni</strong><br>
    Kişisel verilerinizin işlenebilmesi için Kanun’da belirtilen hukuki sebeplerden biri bulunmadığında, Bahçeşehir Konaklama ve Turizm Yatırımları Anonim Şirketi (“Bahçeşehir Konaklama”) olarak kişisel verilerinizi işleyebilmek için açık rızanızın bulunup bulunmadığını soruyoruz.<br>
    Aşağıda belirtilen kişisel veri işleme süreçlerini yürütebilmemiz için açık rıza vermek istiyorsanız metinlerin alt kısmını imzalayabilirsiniz. Kişisel verilerinizin işlenebilmesi için vermiş olduğunuz rızayı, bizimle iletişime geçerek her zaman geri alabilirsiniz.<br>
    <strong>1.</strong>	Sağlık bilgilerimin, Bahçeşehir Konaklama tarafından, konaklama hizmetlerinden yararlanacak kişiler için sağlık ve güvenlik kontrollerinin gerçekleştirilmesi, yurda yerleştirme ve kabul süreçlerinin yönetilmesi ve yetkili kişi, kurum ve kuruluşlara bilgi verilmesi amaçlarıyla işlenmesi ve aynı amaçlarla yetkili kurum ve kuruluşlarla paylaşılmasına izin veriyorum.

    <strong>2.</strong>	Adli sicil kaydı bilgilerimin, Bahçeşehir Konaklama tarafından, konaklama hizmetlerinden yararlanacak kişiler için sağlık ve güvenlik kontrollerinin gerçekleştirilmesi, yurda yerleştirme ve kabul süreçlerinin yönetilmesi ve yetkili kişi, kurum ve kuruluşlara bilgi verilmesi amaçlarıyla işlenmesi ve aynı amaçlarla yetkili kurum ve kuruluşlarla paylaşılmasına izin veriyorum.`,
    kvkk_code: "KVKK Kodu",
    kvkk_code_send: "KVKK Kodu Gönder",

    // L
    login_details: "Giriş Bilgileri",
    last_message: "Son mesaj",
    lab: "Lab",
    lab_credit: "Laboratuvar",
    lab_section: "Lab Section",
    language: "Dil",
    language_text: "Daha önce Türkçe dil sertifikası aldınız mı?",
    language_exam_score: "Dil Sınavı Puanı",
    language_proficiency_certificate_en: "İngilizce Dil Yeterlilik Belgesi",
    language_proficiency_certificate_tr: "Türkçe Dil Yeterlilik Belgesi",
    last_approve_date: "Son Tamamlama Tarihi",
    last_course: "Önceden aldığı Ders",
    last_course_registration_semester: "Son Ders Kayıt Dönemi",
    last_course_registration_year: "Son Ders Kayıt Yılı",
    last_education_status: "Öğrenim Durumu",
    last_lesson_registration_year: "Son Ders Kayıt Yılı",
    last_process_date: "Son İşem Tarihi",
    last_school: "Son Okuduğu Okul",
    last_school_date_of_graduation: "Son Mezuniyet Tarihi",
    last_school_diploma_number: "Son Diploma Numarası",
    last_school_diploma_point: "Son Diploma Notu",
    last_school_registration_date: "Son Okul Kayıt Tarihi",
    last_university: "Son Okuduğu Üniversite",
    last_update_date: "Son İşlem Tarihi",
    leave_absences: "Kayıt Dondurma Talepleri Takibi",
    leave_of_absence: "Kayıt Dondurma",
    leave_of_absence_count: "Kayıt Dondurulan Dönem Sayısı",
    leave_of_absence_fall: "Güz Dönemi Dondurulan Kayıt Sayısı",
    leave_of_absence_period: "Başvuru Yapmak İstenilen Dönem",
    leave_of_absence_reason: "Kayıt Dondurma Sebebi",
    last_publication_date: "Son Basım Tarihi",
    leave_of_city: "Yaşadığınız İl",
    leave_of_district: "Yaşadığınız İlçe",
    leave_of_absence_description: "Kayıt Dondurma Açıklaması",
    leave_of_absence_semesters: "Kayıt Dondurulacak Dönemler",
    leave_of_absence_settings_title: "Kayıt Dondurma Ayarları",
    leave_of_absence_spring: "Bahar Dönemi Dondurulan Kayıt Sayısı",
    leave_of_absence_title: "Kayıt Dondurma Talep Formu",
    leave_of_absence_verify_text:
        "Seçilen dönem için kayıt dondurma talebinizi onaylıyor musunuz? \n\n MADDE 16 – (1) Mazeretleri olan öğrenciler, kayıt dondurmak için mazeretlerini belgelemek koşuluyla en geç yarıyılın/yılın ilk dört haftası içinde Öğrenci İşleri Daire Başkanlığına başvururlar. Öğrencinin kayıt dondurma müracaatı, danışmanının ve bölüm başkanının görüşü alındıktan sonra, ilgili akademik birimin yönetim kurulunca karara bağlanır. \n\n" +
        "(2) Öğrenciler bir defada en çok iki yarıyıl kayıt dondurabilirler. Azami öğrencilik süresince, ön lisans öğrencilerine üç yarıyıl, dört yıllık veya üstü programlarda okuyanlara dört yarıyıl kayıt dondurma hakkı verilebilir. Zorunlu hallerde Yönetim Kurulu kararı ile bu süreler uzatılabilir. Yabancı dil hazırlık veya destek sınıfı öğrencileri en çok iki yarıyıl kayıt dondurabilir ve bu hakkı bir defa kullanabilirler. Kayıt dondurularak geçirilen süreler azami öğrenim süresinden sayılmaz. \n\n" +
        "(3) Öğrencilere aşağıdaki gerekçelerle ve mazeretleri belgelenmek koşulu ile kayıt dondurma hakkı verilebilir: \n\n" +
        "a) Hastalık: Öğrencinin durumunu bir yarıyıl/yıl kayıt dondurmayı gerektirecek süreyi kapsayan bir sağlık raporu ile belgelendirmesi koşulu ile kaydı dondurulabilir. Öğrencinin, kayıt dondurma süresi bitiminde, sağlık probleminin devam ettiğinin sağlık raporları ile belgelenmesi halinde, ilgili akademik birim yönetim kurulu bu süreyi uzatabilir. Bu süre sonunda, öğrencilerden eğitim-öğretime devam etmelerinde bir sakınca olmadığına dair sağlık raporu istenebilir.\n\n" +
        "b) Askerlik: Öğrencinin, tecil veya sevk tehiri işleminin zorunlu nedenlerle yapılamaması sonucu askere alınması halinde, askerlik süresince kayıt dondurma hakkı verilir. \n\n c) İlgili akademik kurullarca kabul edilen belgelendirilecek diğer haklı ve geçerli gerekçeler. \n\n (4) Kayıt donduran öğrencilere uygulanacak kurallar şunlardır:\n\n" +
        "a) Askerlik, hastalık ve doğal afetler nedeni ile kayıt donduran öğrencilerden öğrenim ücreti alınmaz. Başka gerekçelerle kayıt donduran öğrenciler, bu süreye ait öğrenim ücretinin yüzde yirmibeşini (%25) öderler\n\n b) Öğrenci, kayıt dondurmuş olduğu sürede başka bir kurumdan almış olduğu dersleri, Üniversitedeki öğretim programına transfer edemez. \n\nc) Hastalık ve gerekçesi belgelenmiş zorunlu sebepler dışındaki kayıt dondurma istekleri, en geç yarıyılın/yılın ilk dört haftası içinde yapılmalıdır. \n\n" +
        "ç) Genel hükümlere göre kesinleşmiş bir mahkûmiyeti olan veya Yükseköğretim Kurumları Öğrenci Disiplin Yönetmeliğine göre uzaklaştırma cezası alan öğrenciler kayıt donduramaz. Takipsizlik veya beraat kararı ile sona eren tutukluluk süresi, öğrencilerin azami öğrenim süresinden sayılmaz. Bu süre için herhangi bir öğrenim ücreti alınmaz. ",
    leave_of_absence_verify_text_graduate:
        "Seçilen dönem için kayıt dondurma talebinizi onaylıyor musunuz? \n\n MADDE 41 – (3) Haklı ve geçerli mazeretleri olan lisansüstü öğrencileri, kayıt dondurmak için mazeretlerini belgelemek koşuluyla en geç yarıyılın/yılın ilk dört haftası içinde Öğrenci İşleri Daire Başkanlığına başvururlar. Öğrencinin kayıt dondurma müracaatı, danışmanının ve koordinatörünün görüşü alındıktan sonra, ilgili enstitünün yönetim kurulunca karara bağlanır ve aşağıdaki kurallara göre kayıt dondurma ve değerlendirme işlemi yapılır.\n\n" +
        "a) Geçerli sebepler nedeniyle tezsiz yüksek lisans öğrencileri öğrenim süresince en fazla bir yarıyıl, tezli yüksek lisans öğrencileri en fazla iki yarıyıl ve doktora öğrencileri en fazla üç yarıyıl kayıt dondurabilirler. Zorunlu hallerde Üniversite Yönetim Kurulu kararı ile bu süreler uzatılabilir. Kayıt dondurularak geçirilen süreler azami öğrenim süresinden sayılmaz.\n\n" +
        "b) Öğrencinin bir yarıyıl/yıl kayıt dondurmayı gerektirecek süreyi kapsayan bir sağlık raporu ile belgelendirmesi koşulu ile kaydı dondurulabilir. Öğrencinin, kayıt dondurma süresi bitiminde, sağlık probleminin devam ettiğinin sağlık raporları ile belgelenmesi halinde, ilgili yönetim kurulu bu süreyi uzatabilir. Bu süre sonunda, öğrencilerden eğitim-öğretime devam etmelerinde bir sakınca olmadığına dair sağlık raporu istenebilir.\n\n" +
        "c) Öğrencinin, tecil veya sevk tehiri işleminin zorunlu nedenlerle yapılamaması sonucu askere alınması halinde, askerlik süresince kayıt dondurma hakkı verilir.",
    leave_with_consent: "İlişik Kesme",
    leave_with_consent_cap_excel: "CAP-ilisik-kesme",
    leave_with_consent_form: "İlişik Kesme Talep Formu",
    leave_with_consent_reason: "İlişik Kesme Nedeni",
    leave_with_consent_statement1:
        "İlişik kesme ekranına girdiğim bilgilerin doğru olduğunu beyan ederim.",
    leave_with_consent_statement1_content: "",
    leave_with_consent_statement2_content:
        "\n" +
        "\n" +
        "Bu metin, Bahçeşehir Üniversitesi’nin kişisel verilerinizi nasıl işlediği hakkında sizleri bilgilendirmeyi amaçlamaktadır. Kişisel verilerinizin işlenmesine ilişkin konular, aşağıda başlıklar halinde sıralanmıştır.\n" +
        "\n" +
        "Kişisel verilerimin işlenmesinden kim sorumlu?\n" +
        "Bahçeşehir Üniversitesi (“BAU”) olarak, kişisel verilerinizin nasıl işleneceğini belirliyor ve veri kayıt sisteminin yönetimini sağlıyoruz. Diğer bir ifadeyle, kişisel verilerinizi işlerken veri sorumlusu olarak hareket ediyoruz.\n" +
        "\n" +
        "Kişisel verilerim nasıl toplanıyor?\n" +
        "Aşağıda kişisel verilerinizi toplama kanal ve yöntemlerimiz yer almaktadır:\n" +
        "Kişisel verilerinizi toplama kanalları: Fiziki ortamlarda form doldurmanız veya bilgilerinizi bizimle paylaşmanız; internet sitesi ya da dijital ortamlarda yer alan formları doldurmanız; telefon, e-posta, sosyal medya hesapları veya BAU whatsapp hattı üzerinden bizimle iletişime geçmeniz halinde belirtilen kanallar aracılığıyla kişisel verileriniz toplanmaktadır.\n" +
        "Kişisel verilerinizi toplama yöntemleri:Kişisel verileriniz, otomatik, kısmen otomatik veya veri kayıt sisteminin parçası olmak kaydı ile otomatik olmayan yöntemler ile toplanmaktadır.\n" +
        "\n" +
        "Hangi kişisel verilerim işleniyor?\n" +
        "BAU olarak mezunlarımızın aşağıda belirtilen kişisel verilerini işliyoruz:\n" +
        "Kimlik İletişim Diğer Bilgileriniz\n" +
        "\n" +
        "Ad-soyad, kimlik numarası, öğrenci numarası, doğum tarihi, medeni durum. Telefon numarası, e-posta adresi, adres. Fotoğraf, mezuniyet yılı, mezuniyet sezonu, mezun olunan fakülte/enstitü, mezun olunan bölüm/program, çalışma durumu, çalışılan sektör/kurum ve pozisyon, yüksek lisans/doktora bilgileri, BAU Network kullanıcı bilgileri, kulüp etkinlikleri-COOP katılım bilgisi, tavsiyeniz üzerine BAU’lu olan kişi, üniversite çağındaki yakın bilgisi.\n" +
        "\n" +
        "Özgeçmişinizin çeşitli firmalarda uygun pozisyonlarda değerlendirilmesi için BAU tarafından ilgili firmalarla paylaşımını talep etmeniz durumunda ayrıca özgeçmişinizde yer alan bilgiler işlenmektedir.\n" +
        "\n" +
        "Kişisel verilerim hangi amaçlarla işleniyor?\n" +
        "Kişisel verilerinizi, mezun ilişkilerinin yönetilmesi, iletişim süreçlerinin yürütülmesi, kariyer fırsatları, mezunlara özel indirimler ve etkinlikler gibi duyurular konusunda mezunların bilgilendirilmesi, mezun bağlılığının ölçümlenmesi; mezun bağlılığı ve memnuniyetinin artırılmasına yönelik aktivitelerin planlanması, mezunlarımızın akademik ve profesyonel hayat tecrübelerini paylaşabilmeleri için platformların oluşturulması ve yönetilmesi, organizasyon ve etkinliklerin planlanması ve yürütülmesi, organizasyon ve etkinlikler için iş ortakları ile işbirliği gerçekleştirilmesi, talep eden mezunlarımız için kariyer danışmanlığı desteği sunulması ile faaliyetlerimizin raporlanması, incelenmesi ve denetlenmesi amaçlarıyla yurtiçinde veya yurtdışında işliyoruz.\n" +
        "\n" +
        "Kişisel verilerim başkalarına aktarılıyor mu?\n" +
        "Kişisel verilerinizi yurtiçi veya yurtdışındaki üçüncü kişilere aktarıyoruz. Kişisel verilerinizi kimlere, hangi amaçlarla aktardığımız aşağıda belirtilmektedir:\n" +
        "Faaliyetlerimizin mevzuata uygun olarak yürütülmesi, yetkili kişi, kurum veya kuruluşlara bilgi verilmesi, hukuki yükümlülüklerimizin yerine getirilmesi; Yetkili Kişi, Kurum veya Kuruluşlar.\n" +
        "Üçüncü kişilerden mal veya hizmet tedariki kapsamında destek alınması, operasyonlarımızın devamlılığının sağlanması, finans ve muhasebe işlerinin yürütülmesi; Tedarikçiler.\n" +
        "Denetim ve raporlamaların yapılabilmesi, organizasyon ve etkinliklerin düzenlenmesi, iş ortaklarımız ile olan ilişkilerin yönetimi, Bahçeşehir Grup Şirketleri politika ve kurallarına uyumluluğun sağlanması; İş Ortakları.\n" +
        "\n" +
        "Kişisel verilerimi işlerken dayanılan hukuki sebepler neler?\n" +
        "Kişisel verilerinizi işlerken Kişisel Verilerin Korunması Kanunu’nun 5. maddesinde belirtilen “bir hakkın tesisi, kullanılması veya korunması” ile “kişisel veri işlemenin meşru menfaatlerimiz için zorunlu olması” hukuki sebeplerine dayanmaktayız. Bu hukuki sebepler, aşağıda örnek veri işleme faaliyetleri ile açıklanmıştır:\n" +
        "\n" +
        "– Örneğin, talep eden mezunlarımıza kariyer\n" +
        "danışmanlığı sunulması; özgeçmişlerinin talepleri\n" +
        "doğrultusunda firmalar ile paylaşılması.\n" +
        "\n" +
        "– Örneğin, mezunlarımızın üniversite ile bağlılıklarının\n" +
        "ölçümlenmesi, mezun bağlılığı ve memnuniyetinin\n" +
        "artırılmasına yönelik aktivitelerin planlanması.\n" +
        "\n" +
        "Kişisel veri sahibi olarak haklarımı nasıl kullanabilirim?\n" +
        "Kişisel Verilerin Korunması Kanunu’nun 11. maddesinde kişisel veri sahibi ilgili kişilere tanınan bazı haklar bulunmaktadır. Bu haklarınızı kullanmak için Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ’de (kısaca “Tebliğ”) bazı usuller belirlenmiştir. Haklarınızı kullanmak isterseniz, aşağıdaki yöntemlerden biri ile ya da Tebliğ’de belirtilen diğer yöntemlerle talebinizi bize iletebilirsiniz:\n" +
        "İnternet sitesi aracılığıyla; bau.edu.tr adresindeki İlgili Kişi Başvuru Formu’nu doldurarak,\n" +
        "Posta yoluyla; Çırağan Cad. Osmanpaşa Mektebi Sok. No: 4 – 6, 34353, Beşiktaş/İstanbul posta göndererek,\n" +
        "E-posta yoluyla; BAU İrtibat Kişisi’nin kvkk@bau.edu.tr adresine e-posta göndererek.\n" +
        "\n" +
        "Detaylı bilgiye nasıl ulaşabilirim?\n" +
        "BAU’nun kişisel verileri nasıl işlediği hakkında detaylı bilgi almak için;\n" +
        "bau.edu.tr adresindeki “Kişisel Verilerin İşlenmesi ve Korunması Politikası”nı inceleyebilir,\n" +
        "BAU İrtibat Kişisi’nin kvkk@bau.edu.tr adresine e-posta gönderebilirsiniz.\n",
    leave_of_absence_statement:
        "Seçilen görüşme sonucu ile formu kapatmak istiyor musunuz?",
    leave_with_consent_verify_text:
        "Seçilen programlar için ilişik kesme talebinizi onaylıyor musunuz?",
    leaving_academic_year: "Ayrıldığı Sezon",
    leaving_date: "Ayrılma Tarihi",
    leaving_date_between: "Ayrılma Tarih Aralığı",
    leaving_information: "Ayrılma Bilgileri",
    leaving_semester: "Ayrıldığı Dönem",
    lecturer_report: "Ders Raporu",
    lesson_pool: "Ders Havuzu",
    lesson_time: "Ders Saati",
    lesson_timetable: "Ders Programı",
    lessons_only_and_delete_students: "Dersleri Kapat, Öğrencileri Sil",
    lessons_only_and_delete_students_and_sms_send:
        "Dersleri Kapat, Öğrencilerden Sil ve Sms Gönder",
    lessons_we_have_not_taken_before: "Daha Önce Almadığınız Dersler",
    lessons_you_have_taken: "Alıp Kaldığınız Dersler",
    letter_grade: "Harf Notu",
    level: "Seviye",
    line_number: "{number}. satır: ",
    list: "Liste",
    list_of_student_taking_the_course: "Dersi Alan Öğrenci Listesi",
    loading: "Yükleniyor",
    log_detail: "İşlem Detayı",
    log_id: "İşlem ID",
    log_message: "Mesaj",
    log_name: "İşlem Adı",
    log_status: "İşlem Durumu",
    log_time: "İşlem Zamanı",
    log_type: "İşlem Türü",
    log_type_count: "İşlem Türü Sayısı",
    login: "Giriş Yap",
    logout: "Güvenli Çıkış",
    loa_discount_rate: "Kayıt Dondurma İndirim Oranı",
    log_rapors: "Log Raporları",
    leave_of_absence_cancel_proccess_text: "Süreci sonlandırmak üzeresiniz. İşlemi onaylıyor musunuz?",
    logs: "Loglar",
    last_meeting_date_from: "Son Görüşme Başlangıç",
    last_meeting_date_to: "Son Görüşme Bitiş",
    last_updated: "Son Güncelleyen",
    last_update_datetime: "Son Güncelleme Tarihi",
    language_key: {
        tr: "Türkçe",
        en: "İngilizce"
    },

    // M
    money_order: "Havale/EFT",
    mail_order: "Mail Order",
    major_type: "Dal",
    major_types: "Dal Türü",
    make_payment: "Ödeme Yap",
    midterm: "Vize Sınavı",
    final: "Final Sınavı",
    makeup: "Bütünleme Sınavı",
    make_up_exam: "Bütünleme Sınavı",
    make_up_exam_entry: "Bütünleme Sınavı Giriş",
    make_up_additional_student_list: "Bütünlemeye Girecek Ek Öğrenci Listesi",

    male: "Erkek",
    mass_course_closure: "Toplu Ders Kapatma",
    mass_absence_chose: "Toplu Devamsızlık Seçimi",
    mass_absence_update: "Toplu Devamsızlık Güncelleme",

    major_major_dismissal_report: "Anadal ÇAP ilişik kesme raporu",
    max_capacity: "Max Kapasite",
    max_duration: "Max Süre",
    medicine_group_code: "Tıp Grubu Kodu",
    medicine_groups_title: "Tıp Fakültesi Öğrenci Grubu Değiştirme",
    medicinegroup: "Tıp Grupları",
    medicines: "Varsa Sürekli Kullandığı İlaçlar",
    meeting_duration: "Toplantı Süresi",
    meeting_number: "Toplantı Sayısı",
    meeting_place: "Toplantı Yeri",
    meetings: "Görüşmeler",
    member_type: "Üye Tipi",
    menu: "Menü",
    menu_items: "Menü İçeriği",
    message: "Mesaj",
    merge_section_deleted: "Birleştime Bölümü Silindi",
    my_make_up_exams: "Bütünleme Sınavlarım",
    my_observer: "Gözetmenliklerim",
    my_courses: "Derslerim",
    my_invoices: "Faturalarım",
    my_uploaded_documents: "Yüklediğim Belgeler",
    will_take_make_up_exams: "Bütünleme Sınavı Alacaklar",
    midterm_and_final_data_collection: "Ara Sınav ve Final Veri Toplama",
    minor_branch_dismissal_report_for_not_registering_for_courses: "Ders Kaydı Yapmayan Yandal İlişik Kesme Raporu",
    military: "Askerlik",
    military_branch: "Askerlik Şubesi",
    military_branch_number: "Askerlik Şubesi - No",
    military_delay_date: "Askerlik Tecil Tarihi",
    military_information: "Askerlik Bilgileri",
    military_office: "Askerlik Dairesi",
    military_service_city: "Askerlik Şubesi Kodu / Adı ",
    military_service_city_code: "Şehir Kodu",
    military_status: "Askerlik Durumu",
    major_graduate_minor_branch_dismissal_report: "Anadal Mezun Yandal İlişik Kesme Raporu",
    military_status_document: "Askerlik Durum Belgesi",
    min_pass_count: "Minimum Geçiş Sayısı",
    min_point: "Minimum Puan",
    min_rank: "Minimum Sıra",
    minor_application: "Yandal Başvurusu",
    minor_application_leave_with_consent_report_excel: "yandal-ilisik-kesme-raporu",
    minor_application_leave_with_consent_report: "Yandal Ortalamaya Göre ilişik Kesme Raporu",
    minor_application_start: "Yandal Başvurusuna Başla",
    minor_application_quotas: "Yandal Başvuru Kotaları",
    minor_applications: "Yandal Başvuruları",
    minor_programs: "",
    minor_certificate: "Yandal Sertifikası",
    minor_certificate_ebys_send: "Yandal Sertifika EBYS Gönderim",
    minute: "Dakika",
    missing_document: "Eksik Belge",
    missing_documents: "Eksik Belgeler",
    missing_documents_report: "Eksik Belgeler Raporu",
    mobile_number: "Cep Telefonu",
    mobile_number_update: "Cep Telefonu Güncelle",
    mobile_placeholder: "Cep Telefonu Giriniz",
    mobile_verify_not: "Cep telefonu numaranızı doğrulamanız gerekmektedir!",
    mode_of_study: "Öğretim Türü",
    module: "Modül",
    module_id: "Modül ID",
    model: "Model",
    monday: "Pazartesi",
    month: "Ay",
    mother: "Anne",
    mother_gsm: "Annenin İletişim Telefonu",
    mother_job: "Annenin Mesleği",
    mother_name: "Anne Adı",
    mothers_education: "Annenin Eğitim Durumu",
    mothers_gsm: "Annenizin İletişim Telefonu",
    mothers_job: "Annenizin Mesleği",
    move: "Taşı",
    move_here: "Buraya Taşı",
    move_payment: "Ödeme Taşı",
    move_payment_plan: "Ödeme Planı Taşı",
    multiple_choice: "Çoktan Seçmeli",
    multiselect_deselect: "Press enter to remove",
    multiselect_select: "Press enter to select",
    multiselect_selected: "Selected",
    my_jobs: "İşlerim",
    mernis_edit: "Mernis Güncelle",
    mernis_information: "Mernis Bilgileri Getir",
    my_requests: "Taleplerim",
    my_course_schedule: "Ders Programım",
    meeting_date: "Görüşme Tarihi",
    meeting_doing_person: "Görüşme Yapan",
    meeting_planed_date: "Planlanan Görüşme Tarihi",
    meeting_content: "Görüşme İçeriği",
    meeting_add: "Görüşme Ekle",
    meeting_type: "Görüşme Türü",
    meeting_status: "Görüşme Durumu",
    responsiblePerson: "Sorumlu Personel",
    responsible: "Sorumlu",
    meeting_description: "Görüşme Açıklaması",
    meeting_report: "Görüşme Raporu",
    makeup_letter_entry: "Bütünleme Not Girişi",
    makeup_letter_grade_entry_save_msg:
        "Bütünleme Not Giriş Tablosu kaydedilecektir. İşlemi onaylıyor musunuz?",
    messages: "Mesajlar",
    message_new: "Mesaj Yaz",
    message_send: "Mesaj Gönder",
    message_delete_confirm:
        "Mesajı silmek üzeresiniz. İşlemi onaylıyor musunuz?",
    message_lock: "Yanıtlamaya kapalı mesaj",
    message_unlock: "Yanıtlamaya açık mesaj",
    meb_information: "Meb Bilgileri",
    military_status_description: "Askerlik Durum Açıklama",
    military_status_code: "Askerlik Durum Kod",
    military_delay_status_code: "Askerlik Erteleme Durum Kod",
    military_delay_status_description: "Askerlik Erteleme Durum Açıklama",
    military_branch_to: "Askerlik Şubesi Başkanlığına",
    minor_applications_yok_report: "Yandal Denetim Raporu",
    monthly_collection_report: "Aylık Tahsilat Raporu",
    marriage_status: "Medeni Hal",
    meeting_note: "Görüşme Notu",

    // N
    number_of_students_and_collection_report: "Öğrenci Sayıları ve Ciro Raporu",
    number_of_students_and_collection_by_classes_report: "Sınıf Bazlı Öğrenci Sayıları ve Ciro Raporu",
    not_verified: "Doğrulanamadı",
    not_placed: "Yerleşemedi",
    not_completed_undergraduate:
        "Başvurunuzu tamamlamadığınız için değerlendirmeye alınmadınız",
    nationality_type: "Ülke Kodu",
    number_characters_entered: "Girdiğiniz karakter sayısı",
    n_options_selected: "{n} options selected",
    name: "Adı",
    name_en: "Adı (İngilizce)",
    name_placeholder: "Adınızı Giriniz",
    name_surname: "Adı Soyadı",
    national_credit: "National Credit",
    national_credit_of_uncompleted_slots:
        "Tamamlanmayan Slotların Ulusal Kredisi",
    national_id: "TC Kimlik No",
    national_id_passport_no: "T.C. / Pasaport No",
    national_id_placeholder: "T.C. Kimlik Numarası Giriniz",
    national_number: "T.C. Kimlik No",
    nationality: "Uyruk",
    nationality_option: "Uyruk Opsiyonu",
    necessity: "Zorunluluk",
    neighborhood: "Mahalle",
    new: "Yeni",
    new_draft: "Yeni Taslak",
    new_exam: "Yeni Sınav",
    new_option: "Yeni Seçenek",
    new_password: "Yeni Şifre",
    new_password_repeat: "Yeni Şifre - Tekrarı",
    new_payment_collection: "Yeni Tahsilat",
    new_request: "Yeni Talep",
    new_question: "Yeni Soru",
    new_register: "Yeni Öğrenci Kaydı",
    new_section: "Yeni Section",
    new_part: "Yeni Bölüm",
    new_status_radio: "Yeni Durum Ata",
    new_window: "Yeni Sayfa",
    new_students: "Yeni Öğrenciler",
    next: "İleri",
    no: "Hayır",
    no_lessons_to_be_added: "Eklenebilecek Ders Yok",
    no_course: "Ders bulunamamıştır.",
    no_grades_entered: "Not Girilmedi",
    no_installment_message:
        "Girmiş olduğunuz kart için taksitli işlem yapılamamaktadır.",
    no_options: "Seçenek bulunamamıştır.",
    no_payment: "Ödeme yapınız!",
    no_result: "Kayıt Bulunamamıştır",
    no_result_1: "Bulunamadı",
    no_result_2: "Kayıt Bulunamamıştır",
    no_info_found: "Bilgi Bulunamadı",
    no_theoretical_course_selection_has_been_made:
        "Teorik ders seçimi yapılmamıştır",
    no_practical_course_selection_has_been_made:
        "Pratik ders seçimi yapılmamıştır",
    non_dep_course_count: "Tamamlanması Gereken NOND Dersi",
    non_dep_course_credit: "Tamamlanması Gereken NOND Kredisi",
    non_dep_course_ects_credit: "Tamamlanması Gereken NOND AKTS",
    none: "Yok",
    none_of_students: "Hiçbir Öğrenciye",
    not_allow_attendance_updates: "Yoklama Güncellemeye İzin Verme",
    not_selected: "Seçilmedi",
    not_have_payment_approval: "Ödeme Onayınız Bulunmuyor",
    not_include_calculation: "Hesaplamaya Dahil Edilmeyecek Sınav Sayısı",
    not_required: "Zorunlu Değil",
    not_required_documents:
        "(*) İle belirtilen belgelerin yüklenmesi zorunludur.",
    not_studied: "Okumadı",
    not_taken: "Alınmadı",
    not_uploaded: "Yüklenmedi",
    note: "Not",
    note_for_document: "Not",
    note_you_want_to_add: "Eklemek istediğiniz not",
    notes: "Notlar",
    notexcredit: "Not x Kredi",
    note_area: "Not Alanı",
    now_job: "İşlemi Hemen yap",
    number: "Sıra",
    number_of_courses_taken: "Alınan Ders Sayısı",
    number_of_done_exams: "Girilmiş Sınav Sayısı",
    number_of_exams: "Sınav Sayısı",
    number_of_installments: "Taksit Sayısı",
    number_of_leave_of_absence: "Kayıt Dondurulan Dönem Sayısı",
    number_of_observers: "Gözetmen Sayısı",
    number_of_promissory: "Senet Sayısı",
    number_of_records_updated: "Güncellenen Kayıt Sayısı",
    number_of_registered_courses: "Kayıtlı Ders Sayısı",
    number_of_remaining_semesters: "Kalan Dönem Sayısı",
    number_of_semesters: "Okuduğu Dönem Sayısı",
    number_of_semesters_studied: "Okunan Dönem Sayısı",
    number_of_students: "Öğrenci Sayısı",
    number_of_class: "Sınıf Sayısı",
    number_of_students_report: "Öğrenci Sayıları Raporu",
    numerical_score: "Sayısal Puan",
    new_message: "Yeni Mesaj",
    new_advisor_staff: "Yeni Öğretim Elemanı",
    number_of_students_by_status_report:
        "Durumlarına Göre Öğrenci Sayıları Raporu",
    new_event: "Yeni Etkinlik",
    not_attended: "Gelmedi",
    not_working: "Çalışmıyor",
    not_working_reason: "Çalışmama Nedeni",
    not_working_in_military: "Askerde",
    not_working_no_info: "Bilgi verilmedi",
    not_working_other: "Diğer",
    not_working_education: "Eğitim",
    not_working_marriage: "Evlilik/Doğum",
    not_working_searching_job: "İş arıyor",

    // O
    operation: "İşlem",
    online_payment_read_and_approve:
        "<strong> Online ödeme sözleşmesini </strong> okudum, anladım kabul ediyorum.",
    is_online_required: "Online Zorunlumu ?",
    objectives: "Hedefler",
    oauth_confirmation_text: "{client} UMIS hesabınızla giriş yapmak istiyor.",
    observers: "Gözetmenler",
    observer: "Gözetmen",
    office: "Ofis",
    offset_amount: "Mahsup Tutarı",
    offset_date: "Mahsup Tarihi",
    offset_total: "Mahsup Toplamı",
    offsets_detail: "Mahsup Detay",
    offset_balance_report: "Mahsup Bakiye Raporu",
    ok: "Tamam",
    old_name: "Eski Adı",
    old_name_if_exists: "Varsa Eski Adı",
    ombudsman: "Soruşturmacı",
    one_time_payment: "Tek Çekim",
    online: "Online",
    online_credit_card: "Online Kredi Kartı",
    online_reg_not_1:
        "Sadece ÖSYM ile yerleşen öğrenciler için kullanılmaktadır.",
    online_reg_not_2:
        "Online kayıt işlemi sırasında teknik bir sorun ile karşılaşmanız durumunda",
    online_reg_not_3: "adresinden iletişime geçebilirsiniz.",
    online_reg_system: "Online Kayıt Sistemi",
    online_payment_text: `Yurt ücretleri Güz – Bahar Dönemleri olarak ( 9 ay ) alınır.

    1)	Üniversite Akademik takviminde yer alan ders başlama tarihinden önce yurttan ayrılanlara; yurt ücretinin %10’u dışındaki kısmı iade edilir.

    2)	Akademik Yıl Başında yapılan Üniversite Yabancı Diller Yüksekokulu Dil sınavını başarıyla geçerek 1.sınıfa başlayan öğrencilerin, sınavdan önceki süreçte yurda kayıt yaptırması durumunda, parasının tamamı iade edilir.

    3)	Ara dönemde Yabancı Diller Yüksekokulu Dil Sınavını başarıyla geçen öğrencilerin yurttan ayrılmak istemeleri durumunda barınma hizmeti alınan ayların ücreti, içinde bulunan ayın ücretinin tamamı ve kalan aylara ait yıllık barınma ücretinin %30’u kesilerek ücret iadesi yapılır. Hizmet Sunum Taahhütnamesi feshedilir.

    4)	Ders başlama tarihinden sonra ayrılanlara ise; barınma hizmeti alınan ayların ücreti, içinde bulunan ayın ücretinin tamamı ve kalan aylara ait yıllık barınma ücretinin %30’u kesilerek ücret iadesi yapılır.

    5)	Yabancı Diller Yüksekokulu Temmuz ayı 5.Modül (Yaz Okulu) devam edecek olan öğrencilerden 1 aylık ayrıca ücret tahsis edilecektir.

    6)	 Ücret iadeleri öğrencinin iade talebine dair dilekçesini sunduğu tarihten itibaren bir ay içinde beyan edeceği banka hesabına yapılır. İade talebi için onaylanmış Yurt İlişik Kesme Formu ve iade talebine dair dilekçe ile Yurt Müdürlüğü’ne başvuru yaparak Müdürlük onayı aranır.`,
    open_parameter: "Parametre Özellikleri",
    option: "Seçenek",
    or: "ya da",
    order: "Sıralama",
    ordered: "Sıralanmış",
    unordered: "Sıralanmamış",
    order_of_preference: "Tercih Sırası",
    osymDocCode: "Sonuç Belgesi Kontrol Kodu",
    osymExamCode: "Sonuç Belgesi Doğrulama Kodu",
    osym_address_information: "ÖSYM'den Alınan Adres",
    osym_code: "ÖSYM Kodu",
    osym_information: "ÖSYM Bilgileri",
    osym_placement_document: "ÖSYM Yerleştirme Dosyası",
    osym_result_document: "ÖSYM Sonuç Dosyası",
    osym_scholarship: "ÖSYM Bursu",
    osym_score_information: "ÖSYM Puan Bilgisi",
    osymquota: "ÖSYM Kotası",
    osym_data: "ÖSYM Verileri",
    osym: "OSYM",
    other: "Diğer",
    other_identies: "Diğer Kimlikler",
    other_information: "Diğer Bilgiler",
    other_payments: "Diğer Ödemeler",
    other_programs: "Diğer Programlar",
    other_university: "Diğer Üniversite",
    outputs: "Sertifika Çıktıları",
    other_documents: "Diğer Belgeler",
    outbox: "Giden Kutusu",
    open: "Açık",
    only_parent: "Sadece Ana Taksitler",
    only_pdf_files_can_be_uploaded: "Sadece Pdf Dosya Yüklenebilir",
    has_balance: "Bakiyesi Olanlar",
    has_payments: "Tahsilatı Olanlar",

    // Q
    question: "Soru",
    questioning: "Sorgulama",
    questioning_do: "Sorgula",
    questions: "Sorular",
    quota: "Kota",
    quota_management: "Sınıf Kapasitesi Yönetimi",
    quota_type: "Kapasite Türü",
    quota_type2: "Kota Türü",

    // P
    pathname: "Dizin Yolu",
    preference: "Tercih",
    preference_delete: "Tercih silindi",
    preference_add: "Tercih eklendi",
    price_edit: "Fiyatları Düzenle",
    price_add: "Fiyat Ekle",
    placed: "Yerleştirildi",
    place_student: "Öğrenciyi Yerleştir",
    place_student_are_you_sure:
        "Öğrencinin ön kaydını yapmak istediğinize emin misiniz?",
    proficiency_exams: "Yeterlilik Sınavı",
    page: "Sayfa",
    page_not_found: "Sayfa Bulunamadı!",
    paid: "Ödenen",
    parameter: "Parametre",
    placement_exam_scores_excel: "Öğrenci Numarası,Puan,Program Kodu",
    parameter_id: "Parametre Kodu",
    parameter_items: "Parametre Değerleri",
    parent_id: "Parent ID",
    parts: "Bölümler",
    passive: "Pasif",
    passport_country: "Pasaportun Verildiği Ülke",
    passport: "Pasaport",
    passport_number: "Pasaport No",
    password: "Şifre",
    pay: "Ödeme Yap",
    preparation_report_card_publication_date: "Hazırlık Karne Yayın Tarihi",
    payment_waiting: "Ödeme bekleniyor",
    payment_success: "Ödeme Yapıldı",
    pay_type: "Ödeme Türü",
    payer: "Ödeyecek",
    payer_address: "Ödeyecek Adres",
    payer_national_id: "Ödeyecek TC Kimlik No",
    payer_phone: "Ödeyecek Telefon",
    payment: "Ödeme",
    payment_amount: "Ödeme Miktarı",
    payment_approval: "Ödeme Onayı",
    payment_approval_exceptions: "İstisna Ödeme Onayları",
    payment_approval_failed: "Ödeme Onayı Başarısız",
    payment_approval_successful: "Ödeme Onayı Başarılı",
    payment_approvals: "Ödeme Onayları",
    payment_date: "Ödeme Tarihi",
    payment_date_end: "Ödeme Tarihi Sonu",
    payment_date_start: "Ödeme Tarihi Başlangıç",
    payment_info: "Ödeme Bilgileri",
    payment_info_update: "Ödeme Bilgisi Güncelle",
    payment_id: "Ödeme ID",
    payment_method: "Ödeme Yöntemi",
    payment_packages: "Ödeme Paketleri",
    payment_plan: "Ödeme Planı",
    payment_plan_create_btn: "Ödeme Planı Oluştur",
    payment_plan_title: "Ödeme Planları",
    payment_plan_types: "Tip",
    payment_plans: "Ödeme Planları",
    payment_plans_report: "Ödeme Planları Raporu",
    payment_scholarship_information: "Ödeme/Burs Bilgiler",
    payment_status: "Ödeme Durumu",
    payment_status_E: "Var",
    payment_status_H: "Yok",
    payment_total: "Ödenecek Tutar",
    payment_type: "Ödeme Türü",
    payment_type1: "Ödeme Tipi",
    payment_type_group: "Ödeme Türü Grubu",
    payment_types: "Ödeme Türleri",
    payments_report: "Tahsilat Raporu",
    payments_saved: "Ödemeler kaydedildi",
    payment_types_obj: {
        money_order: "Havale/EFT",
        credit_card: "Kredi kartı",
    },
    please_upload_image: "Lütfen aşağıdaki alana fotoğrafınızı yükleyiniz.",
    pending: "Beklemede",
    percentile_certificate: "Yüzelik Dilim Belgesi",
    period: "Dönem",
    period_code: "Dönem Kodu",
    period_id: "Dönem Adı",
    period_status: "Dönem Durumu",
    period_status_not_suitable: "Dönem Durumu Devam Olmadığı İçin Slot Düzenlenemez",
    period_x: "{period}. Dönem",
    periods: "Dönemler",
    permission: "İzinler",
    permission_id: "İşlem",
    permission_information: "İzin Bilgileri",
    personal_email: "Kişisel E-Posta",
    personal_info: "Kişisel Bilgiler",
    personal_information: "Kişisel Bilgiler",
    ph_programs_info: "(*) Doktora Programları içindir.",
    photo: "Resim",
    pilotage_collection_report: "Pilotaj Tahsilat Raporu",
    pilotage_invoices: "Pilotaj Faturalar",
    pin_code: "Pin Kodu",
    pin: "Pin",
    place_added: "Eklenen Yer",
    placement_exam: "Seviye Belirleme",
    placement_exam_classroom_delete_confirm_text:
        "Yerleşim Sınavı Sınıf kaydını silmek üzeresiniz, işlemi onaylıyor musunuz?",
    placement_exam_classroom_store_confirm_text:
        "Yerleşim Sınavı Sınıf kaydını eklemek üzeresiniz, işlemi onaylıyor musunuz?",
    placement_exam_classrooms_define: "Yerleşim Sınavı Sınıf Tanımlama",
    placement_exam_classrooms_info: "Yerleşim Sınavı Sınıf Bilgileri",
    placement_exam_classrooms_ranking: "Yerleşim Sınavı Sınıf Sıralama",
    placement_exam_classrooms_ranking_success:
        "Yerleşim Sınavı Sınıf Sıralama işlemi başarıyla gerçekleştirildi.",
    placement_exam_classrooms_title: "Yerleşme Sınavı Sınıfları",
    placement_exam_part_classroom_delete_confirm_text:
        "Yerleşim Sınavı Bölüm Sınıf kaydını silmek üzeresiniz, işlemi onaylıyor musunuz?",
    placement_exam_part_classroom_store_confirm_text:
        "Yerleşim Sınavı Bölüm Sınıf kaydını eklemek üzeresiniz, işlemi onaylıyor musunuz?",
    placement_exam_part_classrooms_define:
        "Yerleşim Sınavı Bölüm Sınıf Tanımlama",
    placement_exam_student_not_fount: 'Yerleştirme Sınıfı Öğrencisi Bulunamadı',
    placement_exam_part_classrooms_ranking:
        "Yerleşim Sınavı Bölüm Sınıf Sıralama",
    placement_exam_part_classrooms_ranking_success:
        "Yerleşim Sınavı Bölüm Sınıf Sıralama işlemi başarıyla gerçekleştirildi.",
    placement_exam_part_classrooms_title: "Yerleşim Sınavı Bölüm Sınıfları",
    placement_exam_part_classrooms_update:
        "Yerleşim Sınavı Bölüm Sınıfı Güncelleme",
    placement_exam_part_classrooms_update_info:
        "Yerleşim Sınavı Bölüm Sınıf Bilgileri",
    placement_exam_score_upload_info: "Yerleşme Sınavı Sonuç Yükleme Bilgileri",
    placement_exam_score_upload_title: "Yerleşme Sınavı Sonuç Yükleme",
    placement_exam_score_upload: "Sınav Sonucu Yükle",
    placement_exams: "Seviye Belirleme Sınavları",
    placement_rank: "Yerleşme Sırası",
    placement_student: "Yerleştirme",
    program_accepted: "Programa kabul edildiniz",
    program_spare: "Yedek",
    program_other_placed: "Diğer tercihinize yerleştiniz",
    placement_student_result: "Yerleştirme Sonuçları",
    placement_score: "Yerleşme Puanı",
    placement_success_order: "Yerleşme Başarı Sırası",
    please_try_again: "Lütfen tekrar deneyiniz.",
    point: "Not Ağırlığı",
    point_type: "Puan Türü",
    points: "Puan",
    pool: "Havuz",
    pool_name: "Havuz Adı",
    pool_name_en: "Havuz Adı (EN)",
    pool_type: "Havuz Türü",
    postal_code: "Posta Kodu",
    practical: "Pratik",
    practical_credit: "Pratik",
    precondition: "Ön Koşul",
    preference_information: "Tercih Bilgileri",
    preference_procedures: "Tercih İşlemleri",
    preference_restore_success: "Tercih Sıfırlandı",
    preferences: "Tercihler",
    preference_please_add: "Tercihlerinizi ekleyiniz",
    preferences_delete: "Tercihi Sil",
    preferences_edit: "Tercihleri Düzenle",
    preference_restore: "Tercihi Sıfırla",
    preferences_order_change: "Tercih sırasını değiştir",
    preferred_program: "Tercih Program",
    prefers: "Tercih Ettiği",
    preliminary_information_form: "Ön Bilgilendirme Formu",
    prep_academic_year: "Hazırlık Akademik Yılı",
    prep_attendance: "Devamsızlık Girişi",
    prep_attendance_confirmation_modal:
        "Devamsızlık girişleriniz kaydedilecektir, Onaylıyor musunuz?",
    prep_attendance_publish_modal:
        "Devamsızlık girişleriniz yayınlanacaktır, Onaylıyor musunuz?",
    prep_classes_define_form: "Hazırlık Sınıfları Tanımlama Formu",
    prep_classes_delete_confirm_text:
        "Hazırlık Sınıfı kaydını silmek üzeresiniz, işlemi onaylıyor musunuz?",
    prep_classes_store_confirm_text:
        "Hazırlık Sınıfı kaydını eklemek üzeresiniz, işlemi onaylıyor musunuz?",
    prep_classes_title: "Hazırlık Sınıfları",
    prep_classes_update_confirm_text:
        "Hazırlık Sınıfı kaydını güncellemek üzeresiniz, işlemi onaylıyor musunuz?",
    prep_classes_update_form: "Hazırlık Sınıfları Güncelleme Formu",
    prep_classes_update_info: "Hazırlık Sınıfları Bilgileri",
    prep_exam_confirmation_modal:
        "Notları kaydetmek istediğinize emin misiniz?",
    prep_exam_definitions: "Hazırlık Sınavı Tanımları",
    prep_exam_publish_modal: "Notları yayınlamak istediğinize emin misiniz?",
    prep_exam_publish_check_modal: "Notları yayınlayabilmek için öncelikle kaydetmeniz gerekmektedir!",
    prep_grade_entry: "Hazırlık Not Girişi",
    prep_levels: "Level Kodu",
    prep_modules: "Hazırlık Modülleri",
    prep_reports: "Hazırlık Raporları",
    prep_schedule: "Hazırlık Programı",
    prep_schedule_draft_delete_confirm_text:
        "Hazırlık Programı Taslak kaydını silmek üzeresiniz, işlemi onaylıyor musunuz?",
    prep_schedule_draft_store_confirm_text:
        "Hazırlık Programı Taslağı kaydını eklemek üzeresiniz, işlemi onaylıyor musunuz?",
    prep_schedule_draft_title: "Hazırlık Programı Taslakları",
    prep_schedule_import_data_excel_format:
        "Derslik Adı, Seviye, Sınıf, Gün, Başlangıç Saati, Bitiş Saati, Başlangıç Saati, Bitiş Saati, Başlangıç Saati, Bitiş Saati, Başlangıç Saati, Bitiş Saati, Başlangıç Saati, Bitiş Saati, Eğitmen ID, Eğitmen Çakışması, Sınıf Çakışması",
    prep_school: "Hazırlık Okulu",
    prep_school_approval: "Hazırlık okulu onayı?",
    prep_status: "Hazırlık Durumu",
    prep_students: "Hazırlık Öğrencileri",
    prep_students_import_excel_format: "Student Number, Level, Sınıf(Section)",
    preparation_note_entry: "Hazırlık Not Girişi",
    preparatory_information: "Hazırlık Bilgisi",
    preparatory_notes: "Hazırlık Karnesi",
    prerequisites: "Ders Ön Koşulu",
    present: "Var",
    preview: "Önizleme",
    preview_btn_info:
        "Başvurunun tamamlanması için aşağıda bulunan kaydet butonuna basınız!",
    preview_save_btn_info:
        "Evraklarınızı yüklemeden başvurunuzu kaydedebilirsiniz. Kaydettikten sonra size bir takip linki gelecektir. Takip linkinden giriş yaparak evraklarınızı yükleyebilirsiniz.",
    price: "Ücret",
    price_type: "Ücret Tipi",
    priceless: "Ücretsiz",
    prices: "Ücretler",
    print: "Yazdır",
    print_mail_order_form: "Mail Order Formu Yazdır",
    print_promissory_note: "Senet Yazdır",
    print_receipt: "Makbuz Yazdır",
    print_roster: "Yoklama Listesi",
    print_weekly_roste: "Haftalık Yoklama Listesi",
    proccess_success: "İşlem başarılı",
    proccess_will_be_started:
        "Proses başlatılacaktır. İşlemi onaylıyormusunuz?",
    process: "İşlem",
    process_select: "İşlem Seçiniz",
    process_type: "İşlem Tipi",
    process_can: 'İşlem Yap',
    processes: "İşlemler",
    processing: "İşlem yapılıyor",
    processing_information: "İşleniş Bilgileri",
    processing_model: "İşleniş Modeli",
    product: "Ürün",
    product_code: "Ürün Kodu",
    products: "Ürünler",
    profession_job: "Meslek",
    proficiency_certificate_type: "Yeterlilik Belge Türü",
    proficiency_document: "Yeterlilik Belgesi",
    proficiency_exam: "Yeterlilik Belirleme",
    proficiency_requests: "İngilizce Yeterlilik Talepleri",
    proficiency_status: "Hazırlık Durumu",
    proforma_invoice: "Proforma Fatura",
    proforma_invoices: "Proforma Faturalar",
    program: "Program",
    program_code: "Program Kodu",
    program_type:"Program Tipi",
    program_code_name: "Program Kodu / Adı",
    program_codes: "Program Kodları",
    program_coordinator: "Program Koordinatörü",
    program_days: "Program Günleri",
    program_decrease: "Seçili programlardan kaldırmak için tıklayınız.",
    program_delete_info:
        "Seçilen Programı silmek üzeresiniz. İşleme devam etmek istiyor musunuz?",
    program_info: "Program Bilgileri",
    program_information: "Program Bilgileri",
    program_language: "Program Dili",
    program_level: "Program Seviyesi",
    program_level_1: "Öğretim Seviyesi",
    program_level_d: "Doktora",
    program_level_l: "Lisans",
    program_level_ol: "Ön Lisans",
    program_level_yl: "Yüksek Lisans",
    program_name: "Program Adı",
    program_name_en: "Program Adı EN",
    program_select: "Program Seçimi",
    program_select_min: "En az bir program seçiniz!",
    program_selected: "Program daha önceden seçildi.",
    programs: "Programlar",
    programs_applied: "Başvurduğu Programlar",
    programs_selected: "Seçilen Programlar",
    programs_to_choose: "Tercih Edebileceğiniz Programlar",
    programs_you_are_applying: "Başvurduğunuz Programlar",
    program_groups: "Program Grupları",
    project_advisory: "Proje Danışmanlığı",
    promissory_no: "Senet No",
    promissory_note: "Senet",
    promissory_payment: "Senet Tahsilatı",
    provision: "Provizyon",
    public_scholarship: "Kamu Bursu",
    publish: "Yayınla",
    publish_at: "Yayınlama Tarihi",
    published: "Yayınlandı",
    punishment: "Ceza",
    punishment_end_date: "Ceza Bitiş Tarihi",
    punishment_number: "Ceza Maddesi",
    punishment_start_date: "Ceza Başlangıç Tarihi",
    ph_documents_info:
        'Belgelerinizi "Dosya Seçiniz" ibaresine tıklayarak seçebilirsiniz. Yükleme işlemini tamamlamak için "+" ikonuna tıklamalısınız.',
    program_groups_lte: "Lisansüstü Program Grupları",
    prep_score: "Hazırlık Puanı",
    prep_attendance_status: "Hazırlık Katılım Durumu",
    prep_exam_status: "Hazırlık Sınav Durumu",
    person: "kişi",
    product_prices_import_format:
        "Fakülte Kodu, Program Kodu, Kayıt Sezonu, Kayıt Dönemi, Öğrenci Grubu, Ücret, Para Birimi, Vade Tarihi, Taksit Adedi, Başlangıç Tarihi, Bitiş Tarihi",
    program_period: "Öğretim Süresi",
    prep_class_studying: "Hazırlık Sınıfı Okudu mu",
    prep_class_studying_semesters: "Kaç Dönem Hazırlık Sınıfı Okudu",
    program_id: "Program ID",
    proficiency_status_title: "Hazırlık Muafiyeti",
    proficiency_point: "Hazırlık Sınavı Puanı",
    passed_exams_report: "Öğr. Gör.-Girilen Sınavlar Raporu",
    passed_absence_report: "Öğr. Gör.-Girilen Devamsızlık Raporu",
    proficiency_exam_entry_status: "Yeterlik Sınavı Giriş Durumu",
    positional_status: "Pozisyon",
    position_detail: "Pozisyon Detay",
    password_sent: "Parola Göndelidi.",
    prep_number_of_semesters: "Hazırlıkta Okumuş Olduğu Dönem Sayısı",
    payment_or_refund: "Tahsilat/İade",
    price_simulation: "Ücret Simülasyonu",
    previous_course_code: "Daha Önce Alınan Ders Kodu",
    previous_course_name: "Daha Önce Alınan Ders Adı",
    pay_with_vakifbank_vinov: "Vakifbank Vinov İle Ödeme",
    prep_module_success_point: "Hazırlık Modül Puanı",

    // R
    record_type: {
        manual: "Manuel",
        online: "Online",
    },
    readonly: "Görebilir",
    requirements: "Gereksinimler",
    random: "Rastgele",
    rank: "Sıra",
    ranking: "Sırala",
    read_and_accept: "Okudum, Anladım",
    reason: "Sebep",
    reason_for_leaving: "Ayrılma Nedeni",
    reason_for_return: "İade Nedeni",
    reason_for_rejection: "Red Nedeni",
    reason_for_postponement: "Erteleme Nedeni",
    reason_to_increase_max_duration: "MAX SÜREYİ ARTIRMA NEDENİ",
    receipt_number: "Makbuz Numarası",
    receipt_number_format: "Makbuz Numarası Formatı",
    receipt_number_not_created: "Makbuz numarası oluşturulamadı",
    receipt_search: "Makbuz Listesi",
    receipts: "Makbuzlar",
    refund: "İade",
    refund_amount: "İade Tutarı",
    refund_date: "İade Tarihi",
    refund_form: "İade Formu",
    refund_reason: "İade Nedeni",
    refund_request: "Ücret İade Talebi",
    refund_requests: "Ücret İade Talebleri",
    refund_request_list: "Ücret İade Talebi Listesi",
    refund_type: "İade Türü",
    registration: "Kayıt",
    registration_academic_year: "Kayıt Olduğu Sezon",
    registration_data: "Kayıt Verileri",
    registration_date: "Kayıt Olduğu Tarihi",
    registration_date_between: "Kayıt Tarih Aralığı",
    registration_delete_text:
        "Kaydı silmek üzeresiniz. İşlemi onaylıyor musunuz?",
    registration_document_types: "Kayıt Belge Türleri",
    registration_guide: "Kayıt Kılavuzu",
    registration_hour: "Kayıt Saati",
    registration_information: "Kayıt Bilgileri",
    registration_number: "Sicil No",
    registration_season: "Kayıt Olduğu Dönem",
    registration_semester: "Kayıt Dönemi",
    registration_statement: "Kayıt Beyanı",
    registration_status: "Kayıt Durumu",
    registration_type: "Kayıt Türü",
    registration_type_1: "Giriş Türü",
    registration_type_2: "Kayıt Tipi",
    registration_type_registration: "Kayıt",
    registration_type_student: "Öğrenci",
    registration_types: "Kayıt Türleri",
    registration_year: "Kayıt Yılı",
    registrations: "Kayıtlar",
    rejected: "Reddedildi",
    reject: "Reddet",
    reject_why: "Reddetme Nedeni",
    remaining_amount: "Kalan Tutar",
    remaining_credit: "Kalan Kredi",
    remaining_payment_amount: "Kalan Ödeme Tutarı",
    remaining_quota: "Kalan Kapasite",
    remaining_students_radio: "Kalan Öğrenciler",
    remove_exception_payment_approval: "İstisna Ödeme Onayı Kaldır",
    remind_password: "Parola Hatırlat",
    repeat: "Tekrar",
    repeat_count: "Tekrar Sayısı",
    repeat_student_count: "Tekrar Alan <br>Öğrenci Sayısı",
    repeat_type: "Tekrar Türü",
    repetition_status: "Tekrar Durumu",
    report: "Rapor",
    report_type: "Rapor Tipi",
    reporter_analysis_report: "Muhabir Analiz Raporu",
    reporter_assignment_letter: "Muhabir Atama Mektubu",
    request_data_count: "İstek Sayısı",
    request_date: "Talep Tarihi",
    request_information: "Talep Bilgileri",
    request_number: "Talep No",
    request_reason: "Talep Nedeni",
    request_type: "Talep Türü",
    requests_info: "Talepler",
    requests_of_leave_with_consent: "İlişik Kesme Talepleri Takibi",
    request_result: "Talep Sonucu",
    declined_reason: "Red Nedeni",
    required: "Zorunlu",
    required_course_count: "Tamamlanması Gereken Zorunlu Ders",
    required_course_credit: "Tamamlanması Gereken Zorunlu Ders Kredisi",
    required_course_ects_credit: "Tamamlanması Gereken Zorunlu Ders AKTS",
    reservation_add: "Rezervasyon Ekleme",
    reservation_system: "Rezervasyon Sistemi",
    reservation_type: "Rezervasyon Türü",
    reservations: "Rezervasyonlar",
    reset: "Sıfırla",
    residence_address: "İkametgah Adresi",
    residence_city: "İkametgah İli",
    residence_permit: "İkametgah Tezkeresi",
    residence_permit_end_date: "İkamet Tezkeresi Bitiş Tarihi",
    residence_permit_no: "İkamet Tezkeresi No",
    residence_permit_start_date: "İkamet Tezkeresi Veriliş Tarihi",
    residence_permit_validity_dates: "İkametgah Tezkeresi Geçerlilik Tarihleri",
    residence_permit_validity_end_date: "Geçerlilik Bitiş Tarihi",
    residence_permit_validity_start_date: "Geçerlilik Başlangıç Tarihi",
    result: "Sonuç",
    results: "Sonuçlar",
    role: "Rol",
    role_permissions: "Rol Yetkileri",
    roles: "Roller",
    role_filter: "Rol Filtreleme",
    room: "Oda",
    registration_delete_payment_status_error:
        "Ödeme Durumu var olduğundan dolayı, silme işlemi yapılamaz!",
    remove_approve: "Onay Kaldır",
    remove: "Kaldır",
    refund_date_start: "İade Tarihi Başlangıç",
    refund_date_end: "İade Tarihi Bitiş",
    refunds_report: "İade Raporu",
    registration_documents_confirm_text:
        "Kayıt Belge Türü kaydını eklmek üzeresiniz. İşlemi onaylıyor musunuz?",
    registration_documents_confirm_delete_text:
        "Kayıt Belge Türü kaydını silmek üzeresiniz. İşlemi onaylıyor musunuz?",
    receivers: "Alıcılar",
    receivers_id_added_before:
        "Seçtiğiniz kriterlere uygun alıcılar daha önceden eklenmiştir!",
    registration_date_just: "Kayıt Tarihi",
    rows_selected: "satır seçildi",
    reason_of_extension: "Uzatma Nedeni",
    responsible_person: "Sorumlu Personel",
    responsible_person_update: "Sorumlu Personel Güncelle",
    release_application: "Başvuruyu Bırak",
    raise_rate: "Zam Oranı",
    registrations_bulk_delete: "Toplu Öğrenci Silme",
    recalculate: "Yeniden Hesapla",

    // S
    select_image_upload: "Yüklemek istediğiniz resmi seçin",
    merged_sections: "Birleştirilmiş Sectionlar",
    student_transcript: "Transcript",
    student_overlap_report: "Öğrenci Ders Çakışma Raporu",
    student_overlap_report_excel: "ÖğrenciDersÇakısmaRaporu",
    student_english_language_proficiency_document: "Dil Yeterlilik Belgesi",
    surveyReportJob: "Anket Raporu İşlemi",
    s_grade_conversion: "S Not Dönüşümü",
    saturday: "Cumartesi",
    save: "Kaydet",
    save_and_continue: "Kaydet ve Devam Et",
    save_preferences: "Tercihleri Kaydet",
    send_preferences: "Tercihleri Kaydet",
    saved: "Kaydedildi",
    scala_id: "Skala Id",
    scala_name: "Skala Adı",
    scholarship_detail: "Burs Detay",
    scholarship_rate: "Burs Oranı",
    scholarship_status: "Burs Durumu",
    scholarship_type: "Burs Tipi",
    scholarship_report: "Burs Raporu",
    scholarships: "Burslar",
    scholarshiptypes: "Burs Türü",
    scholarship_analysis_report: "Burs Analiz Raporu",
    scholor_ship_rate_ration: "Burssuz",
    school: "Okul",
    school_name: "Okul Adı",
    school_type: "Okul Türü",
    school_scope: "Okul Alan",
    school_certificate: "Karne",
    scientific_preparation: "Bilimsel Hazırlık",
    scientific_preparation_courses: "Bilimsel Hazırlık Dersleri",
    score: "Puan",
    score_information: "Puan Bilgisi",
    score_type: "Puan Türü",
    score_with_the_selected_program: "Seçilen Programla İlgili Puanı",
    search: "Ara",
    search_course: "Ders Arama",
    seating_types: "Oturma Türü",
    section: "Section",
    section_number: "Section Numarası",
    section_message: "Lütfen Bir Section Seçiniz",
    section_status: "Section Durumu",
    section_detail: "Section Detay",
    section_not_found: "Section Bulunamadı",
    section_count: "Section Sayısı",
    section_id: "Section Id",
    section_information: "Section Bilgileri",
    section_operations: "SECTION İşlemleri",
    section_type: "Section Türü",
    sections: "Sections",
    section_import_excel:
        "Ders Kodu, Section, Eğitmen Id, Kota, Type, Section Dili",
    section_detail_import_excel:
        "Ders Kodu, Section, Detay Türü, Detay Kodu, Detay, Tersi",
    select: "Seçiniz",
    select_all: "Tümünü Seç",
    select_btn: "Seç",
    select_date: "Tarih Seç",
    select_file: "Dosya Seçiniz",
    select_is_cash_or_installment: "Peşin mi taksitli mi olduğunu seçiniz",
    select_it: "Seç",
    select_report_type: "Rapor Türü Seçiniz",
    select_program: "Program Seçiniz",
    selected_field_is_invalid: "Seçili olan %{numberof} alan geçersiz",
    semester: "Dönem",
    semester_name: "Dönem İsmi",
    semester_name_en: "Dönem İsmi EN",
    semester_1: "Akademik Yıl/Dönem",
    semester_count: "Dönem Sayısı",
    semester_registration_information: "Dönem/Kayıt Bilgileri",
    semester_settings: "Dönem Ayarları",
    semester_status: "Dönem Durumu",
    semester_text: "Yarıyıl",
    semester_text_en: "Yarıyıl (EN)",
    semester_title: "Dönemler",
    semester_x: "{semester}. Yarıyıl",
    semester_status_analysis: "Dönem Durumu Analizi",
    semesterdate: "Dönem Tarihleri",
    semesters: "Yarıyıl",
    semester_info: "Dönem Bilgiler",
    semester_status_warning: "Dönem durumunuz  <strong>{semester_status}</strong>  olarak belirlenmiştir. Yanlışlık olduğunuz düşünüyorsanız fakülte sekreterliğiniz ile iletişime geçebilirsiniz.",
    send: "Gönder",
    send_again: "Tekrar Gönder",
    send_again_btn: "Tekrar Gönder",
    send_again_not: "Tekrar Gönder'e tıklayarak yeniden deneyiniz.",
    send_approve: "Onay'a Gönder",
    send_application: "Başvuruyu Gönder",
    send_password: "Parola Gönder",
    send_request: "Talebi Gönder",
    send_sms: "Sms Gönder",
    send_verification_code: "Doğrulama Kodu Gönder",
    send_yoksis_transcript: "Yöksis Transkrip Gönder",
    services: "Servisler",
    session: "Sezon",
    set_as_active_step: "Aktif Adım Yap",
    settlement_scholarship: "Yerleşme Bursu",
    short_name: "Kısa Adı",
    short_name_en: "Kısa Adı (EN)",
    show: "Görüntüle",
    show_application: "Başvuru Görüntüle",
    signature: "İmza",
    signature_name: "İmzalayan",
    signature_type: "İmza Türü",
    signer: "İmzalayıcı",
    signer_name_surname: "İmzalayan",
    signer_national_id: "İmzalayan Kimlik Numarası",
    signer_title: "İmzalayıcı Ünvan",
    signer_title_en: "İmzalayıcı Ünvan (EN)",
    slot: "Slot",
    slot_type: "Slot Tipi",
    slot_type_code: "Slot Tipi Kodu",
    slot_types: "Slot Tipleri",
    slot_semester: "Müfredat Dönemi",
    slots: "Slotlar",
    something_went_wrong:
        "Bir Hata Oluştu. Lütfen daha sonra tekrar deneyiniz.",
    sorry_we_could_not_find_the_page_you_were_looking_for:
        "Üzgünüz, aradığınız sayfayı bulamadık.",
    sort_by_graduation_grade_point_average:
        "Mezuniyet Not Ortalaması göre sıralama",
    sort_by_semester_grade_point_average:
        "Dönem Not Ortalamasına Göre Sıralama",
    sound_systems: "Ses Sistemleri",
    speaking_exam: "Konuşma Sınavı",
    spring: "Bahar",
    spring_credit: "Bahar Kredisi",
    staff: "Personel",
    staff_information: "Personel Bilgileri",
    start: "Başlangıç",
    start_date: "Başlangıç Tarihi",
    start_process: "Başlat",
    start_time: "Başlangıç Saati",
    statement1_error:
        "Kayıt ekranına girilen bilgilerin doğruluğunu teyit ediniz!",
    statement2_error:
        "Kişisel verilerin işlenmesine dair Aydınlatma Metni'ni onaylayınız!",
    statement3_error:
        "Kişisel verilerin işlenmesine dair Rıza Metni'ni onaylayınız!",
    statement4_error: "Online ödeme sözleşmesini onaylayınız!",
    statement5_error: "Taksitli seçeneği havale ile ödeyemezsiniz",
    statement_1:
        "Kayıt ekranına girdiğim bilgilerin doğru olduğunu beyan ederim.",
    statement_2:
        "Kişisel verilerin işlenmesine dair <a href=#>Aydınlatma Metni</a>'ni okudum onaylıyorum.",
    statement_3: "Kişisel Verilerin İşlenmesine Dair Rıza Metni",
    statement_4: "Kişisel Verilerin İşlenmesine Dair Rıza Metni(Pilotaj)",
    status: "Durum",
    stay_of_execution_decision: "Yürütmeyi Durdurma Kararı",
    step_name: "Onay Adımı",
    status_approval_awaited: "Onay beklenmektedir",
    status_approved: "Onaylandı",
    status_declined: "Reddedildi",
    status_update: "Durum Güncelle",
    status_waiting_payment: "Ödeme Bekleniyor",
    status_uploaded: "Yüklendi",
    status_waiting: "Bekleniyor",
    status_waiting_publish: "Yerleştirme Bekleniyor",
    street: "Cadde",
    street_and_no: "Sokak/No",
    student: "Öğrenci",
    student_unihall_info: "{0} dönemi için kayıt yaptırmış olmak",
    student_unihall_info1: "İstanbul dışında ikamet etmek",
    student_unihall_info2: "BAU hazırlık sınıfı öğrencisi olmak",
    student_bea_bau: "BAU Öğrencisi Olmak",
    student_document_create1: "Toplu Belge Oluşturma",
    student_affairs: "Öğrenci İşleri",
    student_certificate: "Öğrenci Belgesi",
    student_collection_report: "Öğrenci Tahsilat-Ciro Raporu",
    student_count: "Öğrenci Sayısı",
    student_courses_title: "Ders İnceleme Raporu",
    student_document_create: "Öğrenci Dökümanı Oluştur",
    student_email: "Öğrenci E-Posta",
    student_gno_simulation: "Öğrenci Genel Not Ortalaması Simülasyonu",
    student_gno_report: "Öğrenci GNO Raporu",
    student_graduation_requirement_report:
        "Öğrenci Mezuniyet Gereksinim Raporu",
    student_group: "Öğrenci Grubu",
    student_grade_entry_failed:
        "{number} numaralı {name} {surname} öğrenci için not girişi başarısız",
    student_information: "Öğrenci Bilgileri",
    sending_sms_with_mobile_phone_information:"Cep Telefonu Bilgisi ile SMS gönderimi",
    sending_sms_to_staff:"Personellere SMS Gönderimi",
    send_sms_excel:"Tc Kimlik , Öğrenci No, Cep No",

    sending_sms:"SMS Gönderimi",
    sending_sms_student_search:"Öğrenci Arama İle SMS Gönderimi",
    student_information_system: "Öğrenci Bilgi Sistemi",
    student_list: "Öğrenci Listesi",
    student_list_report: "Yerleşen Öğrenci Raporu",
    student_list_with_photo: "Öğrenci Listesi(Resimli)",
    student_mobile_number: "Öğrenci Cep Telefonu",
    student_name: "Öğrenci Adı",
    student_notes: "Öğrenci Notları",
    student_notes_report: "Öğrenci Notları Raporu",
    student_number: "Öğrenci No",
    student_number_format: "Öğrenci Numarası Formatı",
    student_number_long: "Öğrenci Numarası",
    student_number_filter: "Öğrenci Numarasına Göre Sırala",
    student_name_filter: "Öğrenci İsmine Göre Sırala",
    student_numbers: "Öğrenci Numaraları",
    student_program_class_success_title: "Dönem Başarı Sıralamaları",
    student_program_graduation_success_title: "Mezuniyet Başarı Sıralamaları",
    student_report: "Öğrenci Raporu",
    student_reports: "Öğrenci Raporları",
    student_section_change: "Öğrenci Section Değişikliği",
    student_sections: "Öğrenci Sectionları",
    student_semester_count: "Okuduğu Dönem Sayısı",
    student_semester_report: "Dönem Durum Raporu",
    student_slots: "Öğrenci Slotları",
    student_status: "Öğrencilik Durumu",
    student_status_btn: "Öğrenci Durumu",
    student_surname: "Öğrenci Soyadı",
    students: "Öğrenciler",
    student_program_id: "Öğrenci Program ID",
    studied: "Okudu",
    student_preference_reset_info:
        "Öğrencinin tercihini sıfırlamak ilk durumuna getirmek üzeresiniz emin misiniz?",
    student_preferences: "Öğrencinin tercihleri",
    subject: "Konu",
    subject_en: "Konu (İngilizce)",
    success: "Başarılı",
    success_data_count: "Başarılı Sayısı",
    success_rate: "Başarı Oranları",
    success_rate1: "Başarı Oranı",
    success_status: "Başarı Durumu",
    success_status_report: "Başarı Durum Raporu",
    successfully_added: "Başarıyla eklenmiştir.",
    successfully_changed: "Başarıya değiştirilmiştir",
    successfully_deleted: "Başarıyla silinmiştir.",
    successfully_saved: "Başarıyla kaydedilmiştir.",
    successfully_updated: "Başarıyla güncellenmiştir.",
    sum_of_credits: "Kredi Toplamı",
    sum_of_ects_credits: "Akts Kredi Toplamı",
    summary: "Özet",
    summary_of_project: "Proje Özeti",
    summary_of_thesis: "Tez Özeti",
    summary_statement_content:
        "Formda yer alan bilgilerin doğru olduğunu beyan ederim.",
    summer: "Yaz",
    summer_school_report: "Yaz Okulu Raporu",
    sunday: "Pazar",
    surgery_illness_history:
        "Varsa Geçirdiği Bir Ameliyat Ya da Kalıcı Rahatsızlık",
    surgery_or_discomfort:
        "Varsa Geçirdiğiniz Bir Ameliyat Ya da Kalıcı Rahatsızlık",
    surname: "Soyadı",
    surname_placeholder: "Soyadınızı Giriniz",
    survey: "Anket",
    survey_questions: "Anket Soruları",
    survey_result: "Anket Sonucu",
    survey_result_download: "Anket Sonucu İndir",
    survey_result_show: "Anket Sonucunu Görüntüle",
    surveys: "Anketler",
    switch_user: "Kullanıcı Değiştir",
    synchronous: "Senkron",
    system_name: "Sistem Adı",
    speacial_student_error: "Özel öğrenciler Ders Seçemez",
    scholarship_coop: "Coop Bursu",
    scholarship_public: "Kamu Bursu",
    student_registration_form: "Öğrenci Kayıt Formu",
    student_card: "Öğrenci Kart",
    section_definition: "Section Tanımı",
    section_update_success:
        "{name} {surname} öğrencinin sectionı {section} olarak güncellenmiştir.",
    section_update_unsuccess:
        "{name} {surname} öğrencinin sectionı {section} olarak güncellenememiştir! {add_info} <br> <br> Ders kodu: {course_code} <br> Section:{section} <br> Gün:{day_name}<br> Başlangiç saati: {start_time}<br> Bitiş saati: {end_time}   ",
    schedule_overlap_message: "Almak istediğiniz ders #course dersi ile çakışıyor",
    success_send_message: "Mesaj başrıyla gönderilmiştir.",
    section_update_overlap: "Öğrencinin ders programındaki çakışmadan dolayı güncellenememiştir.",
    sender: "Gönderen",
    semester_add: "Dönem Ekle",
    student_right: "Öğrencilik Hakkı",
    student_statutility: "Öğrencilik Statusu",
    student_right_having: "Öğrencilik Hakkı Var mı",
    student_right_having_end_date: "Öğrencilik Hakkının Bittiği Tarih",
    student_diploma_name: "Diploma Ad",
    student_diploma_surname: "Diploma Soyad",
    system_maintance:
        "Bu sayfayı görüntülüyorsanız lütfen aşağıdaki yönergelerde belirtilen DNS bilgisi güncelleme adımlarını takip ediniz.",
    system_maintance_2: "Anlayışınız için teşekkür ederiz.",
    student_absence_report: "Öğrenci Devamsızlık Raporu",
    students_should_take_course: "Dersi Alacaklar Raporu",
    students_should_take_courses: "Dersi Alacaklar Raporu (Tümü)",
    students_should_take_elective: "Seçmeli Alacaklar Raporu",
    survey_results: "Anket Sonuçları",
    survey_results_loading: "Anket Sonuçları Hesaplanıyor",
    survey_instructor_results: "Eğitmen Anket Sonuçları",
    select_theoretical_section: "Teorik section seçiniz",
    select_practical_section: "Pratik section seçiniz",
    survey_question_about_course: "Ders ile İlgili Sorular",
    survey_staff_avg: "Öğretim Görevlisi Ortalaması",
    survey_faculty_avg: "Fakülte Ortalaması",
    survey_university_avg: "Üniversite Ortalaması",
    survey_comment_question_select: "Yorum sorusu seçiniz.",
    sms_sending_title: "Sms Gönderimi",
    sms_sending_with_number_info: "Cep Telefonu Bilgisi ile SMS Gönderimi",
    sms_sending_with_student_search: "Öğrenci Arama ile SMS Gönderimi",
    sms_to_will_send: "Gönderileceği",
    student_selected: "{numberOf} Öğrenci seçildi",
    student_status_changes: "Öğrenci Durum Değişiklikleri",
    show_programs: "Program Görüntüle",
    scholarship_status_form: "Burs Durumu Belgesi",
    student_affairs_grade_import: "Toplu Not Girişi Import",
    sample_template_excel: "EXCEL - ÖRNEK ŞABLON",
    student_affairs_academic_year: "Akademik Yıl (YYYY-YYYY)",
    student_affairs_period: "Dönem (1,2,3,4)",
    student_affairs_exam_type: "Sınav Türü (F yada M)",
    session_timeout_warning: "Oturumunuz Sonlanmak Üzere",
    session_timeout_warning_text: "Oturum süreniz dolmak üzere. Devam etmek istiyor musunuz?",
    session_timeout_continue: "Devam Et",
    session_timeout_logout: "Sonlandır",
    session_timer_title: "Kalan oturum süreniz",
    second_language: "İkinci Dil",
    second_prep_module_success_point: "İkinci Dil Modül Puanı",
    second_prep_proficiency_success_point: "İkinci Dil Hazırlık Sınav Puanı",

    // T
    two_factor_authentication: "Two Factor",
    take_the_lesson: "Dersi Rezerve Et",
    date_took_the_course: "Dersi Aldığı Tarih",
    taking_during_active_semester: "Aktif dönemde alıyor",
    turkmer_page_title: "TÜRKMER PROGRAMLARI BAŞVURU FORMU",
    turkmer_admin_page_title: "TÜRKMER PROGRAMLARI BAŞVURU LİSTESİ",
    tc_no: "T.C. Kimlik No",
    tc_or_passport: "T.C. Kimlik/Pasaport No",
    teaching_staff: "Öğretim Elemanı",
    teaching_staff_no: "Öğretim Elemanı Kodu",
    temporary_graduation: "Geçici Mezuniyet Belgesi",
    telephone: "Telefon",
    terminal: "Terminal",
    the_class_finished_in_the_program_came: "Tez Başlığı",
    the_person_who_person: "Ekleyen Kişi",
    the_values_you_entered_have_already_been_added:
        "Girmiş olduğunuz değerler daha önce eklenmiştir.",
    theoretical: "Teorik",
    theoretical_credit: "Teorik",
    theoric: "Teorik",
    theoric_section: "Teorik Section",
    there_are_no_records: "Kayıt bulunmamaktadır.",
    there_is: "Var",
    there_is_any_course_in_active_semester: "Aktif dönemde ders bulunamadı",
    there_is_no_authorization: "Yetki bulunmamaktadır.",
    thesis_advisor: "Tez Danışmanı",
    thesis_advisory: "Tez Danışmanlığı",
    thesis_and_project: "Tez",
    thesis_and_topic_determination: "Tez Konu Belirleme",
    thesis_and_project_advisor_approve_title: "Danışman Başvuru Görüntüleme",
    thesis_and_project_application: "Tez Başvuru",
    thesis_and_project_coordinator_approve_title:
        "Koordinatör Başvuru Görüntüleme",
    thesis_and_project_info: "Tez / Proje Bilgisi",
    thesis_and_projects_admin_title: "Tez Admin Başvuru Görüntüleme",
    thesis_and_projects_admin_update: "Tez / Proje Admin Başvuru Güncelleme",
    thesis_students: "Tez/Proje Öğrencileri",
    thesis_title: "Tez Başlığı",
    this_field_is_required: "Bu alan zorunludur.",
    thursday: "Perşembe",
    tr_name: "TR Adı",
    en_name: "EN Adı",
    title1: "Ünvan",
    title: "Başlık",
    title_of_project: "Proje Başlığı",
    title_of_thesis: "Tez Başlığı",
    title_title: "BaşlıK",
    to_score: "Bitiş Puanı",
    to_email: "Aktarılacak E-Posta",
    toefl_password: "TOEFL Şifre",
    toefl_username: "TOEFL Kullanıcı Adı",
    total_advisory: "Toplam Danışmanlık",
    total_amount: "Toplam Tutar",
    total_course_count: "Toplam Ders Sayısı",
    total_credits: "Toplam Kredi",
    total: "Toplam",
    total_credits_attempted: "Toplam Alınan Kredi",
    total_credits_earned: "Toplam Tamamlanan Kredi",
    total_debt: "Toplam Borç",
    total_discount: "Toplam İndirim",
    total_discount_amount: "Toplam Burs Tutarı",
    total_discount_rate: "Toplam Burs Oranı",
    total_ects_credits: "Toplam AKTS Kredisi",
    total_ects_credits_attempted: "Toplam Alınan AKTS Kredi",
    total_ects_credits_earned: "Toplam Tamamlanan AKTS Kredi",
    total_installment: "Toplam Taksit",
    total_paid: "Toplam Ödenen",
    total_points: "Toplam Puan",
    total_refund: "Toplam İade",
    total_ects_range_completed: "Tamamladığı Toplam AKTS Aralığı",
    total_credit_range_completed: "Tamamladığı Toplam Kredi Aralığı",
    total_slots: "Toplam Slot",
    total_capacity: "Toplam Kapasite",
    total_student_count: "Toplam Öğrenci Sayısı",
    transaction_date: "İşlem Tarihi",
    transaction_end_date: "İşlem Tarihi Bitiş",
    transaction_reference: "Referans Numarası",
    transaction_start_date: "İşlem Tarihi Başlangıç",
    transcript: "Transkript",
    transitions: "Çeviriler",
    transfer: "Aktar",
    transfer_applications: "Basvuruları Aktar",
    true: "Doğru",
    tuesday: "Salı",
    tuition: "Eğitim Ücreti",
    turkey: "Türkiye",
    turkish: "Türkçe",
    turn_back: "Geri Dön",
    type: "Tür",
    type_detail: "Tür Detayı",
    type_of_delivery: "Teslimat Şekli",
    type_of_program_score: "Program Puan Türü",
    type_of_program: "Program  Türü",
    there_are_missing_responses: "Cevap vermediğiniz sorular mevcut",
    this_university_course: "Üniversite Dersi",
    to: "Kime",
    two_copies: "İkinci Nüsha",
    two_copies_wet_signature: "Islak İmza İkinci Nüsha",
    two_copies_wet_signature_PDF: "ıslak-imza-ikinci-Nüsha",
    two_copiesPdf: "İkinci-Nüsha",
    teaching_staff_faculty: "Öğretim Elemanı Fakülte",
    teaching_staff_department: "Öğretim Elemanı Bölüm",
    teaching_staff_program: "Öğretim Elemanı Program",
    taken_at: "Alındığı Tarih",
    transfer_next_period: "Fazla tutarı sonraki döneme aktar",
    transfer_next_installments: "Fazla tutarı sonraki taksitlere aktar",
    take_on_application: "Başvuruyu Üstlen",
    tax_explanation: "Yukarıda yazılı öğrenim ücretlerine KDV dahil değildir. Ödeme yöntemini seçmeniz akabinde yönlendirileceğiniz ekranda KDV dahil ücretleri görmeniz mümkündür. Kamu otoritesi tarafından hizmet süresi boyunca vergi oranlarının değiştirilmesinin mümkün olduğunu hatırlatmak isteriz. Bu kapsamda, KDV oranın yükseltilmesi veya azaltılması halinde bu değişikliğin öğrenim ücretine yansıması mümkündür. Eğitim ücretlerinde KDV oranı %10'dur.",

    // U
    unread: "Okunmamış",
    read: "Okunmuş",
    unihall_country_code: {
        TR: "Türkiye",
        YU: "Yurt Dışı",
    },
    user: "Kullanıcı",
    unihall_approvet_payment: "Ödemeniz onaylandı",
    unihall_applications_title: "Yurt Oda Başvuruları",
    unihall_payment_note:
        "Not: Havale yapılırken 'Açıklama' alanına Öğrenci Adı Soyadı / Oda Tipi /T.C. Kimlik No yazılmalıdır",
    unihall_payment_note1:
        "Sevgili öğrencilerimiz, Güz-Bahar Dönemi yurt ödemeleri 9 ay (Ekim-Haziran) üzerinden yapılmaktadır. Eksik Yapılan ödemeler kesin kayıt olarak kabul edilmeyip, tarafınıza iade edilecektir. Havale ödemenizin 1 saat (60 dakika) içerisinde sisteme düşmemesi durumunda başvurunuz iptal edilecektir.",
    unihall_remaining_quota: "Kalan Kota",
    unihall_quota: "Yurt Odaları",
    unihall_quota_select: "Oda Seçimi",
    unihall_quota_type: "Oda Tipi",
    unihall_quota_payment_type_select: "Ödeme",
    unihall_cash_price: "Peşin Ücret",
    unihall_installment_price: "Taksitli Ücret",
    unihall_quotas_title: "Yurt Oda Kapasiteleri",
    unihall_quotas_price_title: "Yurt Oda Fiyatları",
    unihall_make_order_info_text:
        "Başvuru işleminiz ONAYA gönderilmiştir, 1 saat içerisinde ücreti yatırmanız halinde başvurunuz & ödemeniz onaylanacaktır, süre aşımında başvurunuz iptal edilecektir.",
    unihall_approved_message:
        "Ödeme işleminiz ve kaydınız başarıyla tamamlanmıştır. Aşağıdaki evraklarla birlikte 30 Eylül 2023 tarihinden itibaren yurda giriş yapabileceklerdir.<br><br>1) T.C. vatandaşları için kimlik kartı fotokopisi (Yabancı uyruklu öğrencilerden pasaport fotokopisi),<br>2) Öğrencinin Üniversite’ye kayıt durumunu gösteren belge (Yabancı uyruklu öğrencilerden öğrenim izni belgesi),<br>3) Dört adet vesikalık fotoğraf,<br>4) Sağlık durumunun yurtta kalmasına elverişli olduğunu gösteren sağlık raporu, (Sağlık Ocağı, Devlet veya Özel Hastanelerden alınmış),<br>5) Adli sicil ve arşiv kaydı belgesi,<br>6) Yabancı uyruklu öğrenciler için emniyet makamlarından alınacak oturum izin (ikamet) belgesi.",
    unihall_quota_full:
        "Yurt kontenjanlarımız dolmuştur. İlginiz için teşekkür ederiz. Yedek Listesine isim yazdırmak isteyen öğrencilerimiz <a href='mailto:info@unihall.org'>info@unihall.org</a> adresine Öğrenci Adı Soyadı - okul numarası - iletişim bilgisi ve ikamet adresini yazarak iletebilirler.",
    unihall_room_info_update: "Oda Bilgisi Güncelle",
    unauthorized_operation: "Yetkisiz İşlem",
    undergraduate_transfer: "Yatay Geçiş",
    undergraduate_transfer_applications: "Yatay Geçiş Başvuruları",
    undergraduate_transfer_quotas: "Yatay Geçiş Kotaları",
    undergraduate_vertical: "Yatay/Dikey Geçiş",
    undergraduate_vertical_transfer: "Yatay/Dikey Geçiş",
    undergraduate_check_date_message:
        "Başvurular henüz aktif olmadığı için başvuru yapamazsınız.",
    undergraduate_transfer_yok_report: 'Yatay Geçiş Yök Raporu',
    unit: "Birim",
    unit_id: "Birim Id",
    units: "Birimler",
    universities: "Üniversiteler",
    university: "Üniversite",
    university_not_list: "Üniversitem Listede Yok",
    university_courses: "Üniversite Dersleri",
    university_grade_scale_matching: "Üniversite Not Skalası Eşleştirme",
    university_informations: "Üniversite Bilgileri",
    university_rank: "Üniversite Sıralaması",
    university_status: "Yurt İçi / Yurt Dışı",
    university_type: "Üniversite Türü",
    university_add: "Üniversite Ekle",
    university_where_the_course_was_taken: "Dersin Alındığı Üniversite",
    unpublished: "Yayınlanmadı",
    unsuccessful: "Başarısız",
    update: "Güncelle",
    update_classes_title: "Sınıf İlerletme İşlemi",
    update_draft: "Taslak Güncelleme",
    update_info_btn: "İletişim Bilgilerini Güncelle",
    update_register: "Öğrenci Kaydı Güncelleme",
    updated: "Güncellendi",
    updated_at: "Güncelleme Zamanı",
    upload: "Yükle",
    upload_file: "Dosya Yükleme",
    upload_your_documents: "Belgelerinizi yükleyiniz",
    uploaded_files: "Yüklenmiş Dosyalar",
    url: "URL",
    used_drugs: "Varsa Sürekli Kullandığınız İlaçlar",
    user_quide: "Kullanım Kılavuzu",
    user_type: "Kullanıcı Türü",
    username: "Kullanıcı Adı",
    user_transfer: "Kullanıcı Aktarma",
    unselect_all: "Tümünü Kaldır",
    user_model_filters: "Kullanıcı Model Filtreleri",
    user_model: "Model",
    user_filter_type: "Filtre Tipi",
    user_filter_value: "Filtre Değeri",
    update_contact_info: "İletişim Bilgisi Güncelleme",
    update_mobile_tel: "Cep Telefonu Güncelleme",
    update_email: "E-posta Adresi Güncelleme",
    university_id: "Üniversite ID",
    unit_name: "Birim Ad",
    unit_long_name: "Uzun Ad",
    unit_type: "Birim Türü",
    unit_teach_lang: "Öğretim Dili",
    unit_teach_type: "Öğretim Türü",
    update_units: "Birimleri GÜncelle",
    username_info_update: "Kullanıcı Bilgilerini Değiştir",
    username_update_manuel_switch: "Kullanıcı adını elle gireceğim",
    university_graduate_inquiry: "Üniversite Mezun Sorgulama",
    undergraduate_start_date: "Lisansüstü Eğitime Başladığı Tarih",
    undergraduate_leave_date: "Lisansüstü Eğitimin Bittiği Tarih",
    upload_bank_receipt: "Dekont Yükle",

    // V
    validity_dates: "Geçerlilik Süreleri",
    validity_season_end: "Geçerlilik Dönem Sonu",
    validity_season_start: "Geçerlilik Dönem Başlangıcı",
    validity_date_start: "Geçerlilik Başlangıç Tarihi",
    validity_date_end: "Geçerlilik Bitiş Tarihi",
    value: "Değer",
    verbal_score: "Sözel Puan",
    verification: "Doğrulama",
    verification_code: "Doğrulama Kodu",
    verification_method: "Doğrulama Yöntemi",
    verification_method_not_found: "Doğrulama Yöntemi bulunamamıştır.",
    verification_personal_info: "Kimlik Bilgilerim ile Doğrulama",
    verification_sms_code: "Sms ile Doğrulama",
    verified: "Doğrulandı",
    verify: "Doğrula",
    verify_code_again_not: "Doğrulama kodu tekrar gönderildi.",
    vertical_transfer: "Dikey Geçiş",
    vertical_transition_and_vertical_transition_supplementary: "Dikey Geçiş ve Dikey Geçiş-Ek",
    view: "Görüntüle",
    view_and_edit: "Görüntüle/Düzenle",
    village: "Köy",
    virman: "Virman",
    virtual_pos: "Sanal Pos",
    view_score_again: "Hayır, Notlara Gözat",
    veteran_martyr_relatives: "Gazi/Şehit Yakını",

    // W
    waiting_payment: "Ödeme bekleniyor",
    waiting_prep_school: "Hazırlık Okulu Bekleniyor",
    waiting_academic_unit_office: "Akademik Birim Bekleniyor",
    waiters_approved: "Onaylananlar",
    waiters_for_approve: "Onay Bekleyenler",
    waiters_rejected: "Reddedilenler",
    want_leave_of_absence: "Kayıt dondurma talebi olacak mı?",
    want_leave_of_absence_missing: "Kayıt dondurma talebinizi belirtiniz",
    want_optional_prep: "İsteğe bağlı hazırlık talebiniz olacak mı?",
    want_optional_prep_missing: "İsteğe bağlı hazırlık talebinizi belirtiniz",
    warning: "Uyarı",
    wednesday: "Çarşamba",
    week: "Hafta",
    weekend_in: "Haftasonu",
    weekend_out: "Haftaiçi",
    welcome: "Hoşgeldin",
    welcome_letter: "Hoşgeldin Mektubu",
    welcome_to: "Hoşgeldiniz",
    whole_price: "Tüm Ücret",
    wire: "Havale/EFT",
    wire_info: "Havale Bilgileri Aşağıdaki gibidir",
    with_installment: "Taksitli",
    with_mobile_number: "Cep Telefonu ile",
    with_personal_information: "Kişisel Bilgiler ile",
    with_thesis: "Tezli",
    with_without_thesis: "Tezli/Tezsiz",
    withdraw_requests: "Withdraw Talepleri",
    without_thesis: "Tezsiz",
    witness_complainant_statement: "Tanık Şikayet İfadesi",
    working_end_date: "Ayrılma Tarihi",
    working_start_date: "İşe Başlama Tarihi",
    working_status: "Devam Durumu",
    write_verify_code: "Cep telefonunuza gönderilen doğrulama kodunu giriniz.",
    written_and_oral_defense_report: "Yazılı Ve Sözlü Savunma Raporu",
    written_exam: "Yazılı Sınav",
    work_status: "Çalışma Durumu",
    working: "Çalışıyor",
    work_history: "Çalışma Geçmişi",
    work_start_date: "İşe Giriş Tarihi",
    work_leave_date: "İşten Çıkış Tarihi",
    working_type: "Çalışma Şekli",
    working_tract: "Alan Durumu",

    //X
    x_field_is_required: " alanı zorunludur!",

    // Y
    you_will_directed: "Birazdan yönlendirileceksiniz",
    year: "Yıl",
    year_code: "Yıl Kodu",
    year_count: "Yıl Sayısı",
    year_of_birth: "Doğum Yılı",
    yearly: "Yıllık",
    yes: "Evet",
    yl_payment_plan: "YL Ödeme Planı",
    yoksis: "Yöksis",
    yoksis_code: "Yöksis Kod",
    yoksis_import_title: "Yöksis Kayıt Çekme",
    yoksis_information_cancel: "Yöksis Bilgilerini Sil",
    yoksis_information_import: "Yöksis Bilgilerini Yükle",
    yoksis_information_update: "Yöksis Bilgilerini Güncelle",
    yoksis_number_of_semesters: "Yöksis Okuduğu Dönem Sayısı",
    yoksis_unit_id: "Yöksis Birim ID",
    yoksis_units: "Yöksis Birimleri",
    yok_registration_status_report: "YÖK Kayıt Durum Raporu",
    you_can_combine_up_to_five_lessons: "Beşten fazla dersi Birleştiremezsiniz",
    you_are_not_authorized_for_this_operation:
        "Bu işlem için yetkiniz bulunmamaktadır.",
    you_can_try_again_after_1_minute: "1 dakika sonra tekrar deneyebilirsiniz.",
    you_dont_have_a_payment_plan: "Ödeme planınız bulunmamaktadır.",
    you_have_to_upload_the_files_first: "Önce Dosyaları yüklemelisiniz!",
    you_have_tried_too_many: "Çok fazla deneme yaptınız.",
    you_must_fill_in_the_fields_indicated_in_red:
        "Kırmızı ile belirtilen alanları doldurmanız gerekmektedir.",
    you_must_do_change: "Hiç değişiklik yapmadınız",
    your_chosen_courses: "Rezerve Ettiğiniz Dersler",
    your_payment_transaction_has_been_successfully_completed:
        "Ödeme işleminiz başarıyla tamamlanmıştır.",
    your_preferences: "Tercihleriniz",
    your_preferences_waiting: "Tercihleriniz bekleniyor",
    your_programs: "Programlarınız",
    your_selected_permission: "Seçtiniz Yetkiler",
    your_selected_program: "Seçtiniz Program",
    your_selected_classrooms: "Seçilen Derslik Listesi",
    yoksis_operations: "YÖKSİS İşlemleri",
    yoksis_search_student: "Yöksis Öğrenci Arama",
    yoksis_undergraduate_transfer_list: "Yatay Geçiş Listeleme",
    yoksis_multiaction: "Toplu İşlem",
    yoksis_student_questioning: "Öğrenci Sorgulama",
    yoksis_meb_graduate_questioning: "Meb Mezun Sorgulama",
    yoksis_lists: "Listeler",
    yoksis_universities: "Yöksis Üniversiteler",
    yok_notifications: "Yök Duyurular",
    yoksis_send_information: "Yöksis Gönderim Bilgileri",
    yoksis_student_id: "Yöksis Öğrenci ID",
    yoksis_report_type: "Yöksis Rapor Türü",
    yoksis_list: "Yöksis Liste",
    yoksis_punish_list: "Ceza Liste",
    yoksis_day_of_list: "İzin Liste",
    yoksis_prep_detail_list: "Hazırlık Detay Liste",
    yoksis_pedagogic_formation_list: "Pedagojik Formasyon Liste",
    yoksis_other_universities_students_list:
        "Diğer Üniversitelerde Kayıtlı Olan Öğrenciler",
    yoksis_other_universities_active_register_students_list:
        "Diğer Üniversitelerde Aktif Kaydı Olan Öğrenciler",
    yoksis_dgs_register_students_universities_list:
        "DGS Kayıtlı Öğrencilerin Tüm Üniversite Kayıtları",
    yoksis_graduated_and_continuing_student_list:
        "Mezun Olup Öğrenimine Devam Eden Öğrenciler",
    yoksis_yok_references: "Yöksis Referanslar",
    yoksis_university_units: "Yöksis Birimler",
    you_dont_have_any_invoice: "Faturan bulunmuyor",
    yks: "YKS",
    exam_schedules_double_course_control: "Çift Ders Kontrolü",
    double_course_control: "Çift Ders Kontrolü",
    group_name: "Grup Adı",
    group_name_en: "Grup Adı (EN)",
    exam_schedule_report: "Sınav Programında Yer Almayan Dersler",
    isThereExamSchedule: "Sınav Programında Var",
    bulk_message: "Toplu Mesaj",
    bulk_address_update: "Toplu Adres Güncelleme",
    bulk_import: "Toplu import işlemleri",
    // Z

    message_sent_student: "Mesaj {number} öğrenciye gönderildi",
    application_information_is_awaited: "Başvuru bilgileri beklenmektedir.",
    preference_information_is_awaited: "Tercih bilgileri beklenmektedir.",
    required_documents_is_awaited:
        "Gerekli dokümanların gönderilmesi beklenmektedir.",
    required_documents_approval_is_awaited:
        "Gerekli dökümanların onaylanması beklenmektedir.",
    your_application_has_been_accepted: "Başvurunuz kabul edilmiştir.",
    your_application_has_been_rejected: "Başvurunuz reddedilmiştir.",
    your_application_publish: "Başvurunuz sonuçlanmıştır.",
    your_application_has_been_evaluated:
        "Başvurunuz değerlendirmeye alınmıştır.",
    yoksis_status: "Yöksis Durumu",
    yok_full_scholarship_control_report: "YÖK Tam Burs Kontrol Raporu",
    undergraduate_transfer_start:
        "Yatay geçiş başvuru sürecinize aşağıdaki butona basarak başlayabilirsiniz",
    vinov_instructions: "Vinov Talepleri",
    vinov_kvkk: "KİŞİSEL VERİLERİN İŞLENMESİ HAKKINDA AYDINLATMA METNİ (Vakıfbank Vinov Başvurusu Nedeniyle)",
    vinov_acik_riza: "KİŞİSEL VERİLERİN AKTARILMASINA İLİŞKİN RIZA METNİ (Vakıfbank Vinov Başvurusu Nedeniyle)",
    new_vinov_instruction: "Yeni Vinov Talebi",
    unidentified_payments: "Kimliği Belirsiz Ödemeler",
    user_id: "Kullanıcı ID",
    you_choose_courses_to_improve_your_grades_are_you_sure:
        "Not yükseltmek için ders seçimi yapıyorsunuz. Emin misiniz?",

    minor_status_obj: {
        waiting: "Başvuru bekleniyor",
        waiting_preference: "Tercihler bekleniyor",
        waiting_prep_school: "Hazırlık okulu bekleniyor",
        waiting_academic_unit_office: "Akademik birim bekleniyor",
    },
    virman_date: 'Virman Tarihi',
    total_weekly_hours: "Haftalık Ders Saati",
    undergraduate_hours: "Lisans/Ön Lisans Saat",
    graduate_hours: "Lisansüstü Saat",
    position: "Görev",
    from_position: "Görevden Gelen",
    doctorate_thesis: "Doktora Tez",
    graduate_project: "Lisansüstü Proje",
    undergraduate_project: "Lisans Proje",
    overflow_expected_load: "Beklenen Ders Yükünü Aşan Ders Saati",
    extra_course_load_to_pay: "Ödemeye Esas Ek Ders Saati ",
    ktys: "Unvan Bazında Ders Ücreti",
    total_extra_course_fee: "Toplam Ek Ders Ücreti",
    monthly_fee: "Aylık Ödeme",
    total_course_hours_load: "Toplam Ders Yükü Saati",
    uploaded_student_documents: "Öğrencilerin Yüklediği Belgeler",
    short_name_desc: "Z-A Sırala",
    short_name_asc: "A-Z Sırala",
    short_number_desc: "9-0 Sırala",
    short_number_asc: "0-9 Sırala",
    verification_via_sms: "Sms ile Doğrulama",
    verification_with_authenticator_app: "Authenticator Uygulaması İle Doğrulama",
    two_factor_app_info: "Authenticator uygulaması ile QR kodu okutunuz ve uygulamanın verdiği kodu alttaki alana yazıp Onayla butonuna tıklayınız.",
};

export default translations;
