<template>
    <div>
        <b-modal ref="commonModal"
                  :id="id"
                  :size="size"
                  centered
                 :hide-footer="!footer"
                 :static="is_static"
                 no-close-on-esc
                 @hide="onHide"
                 @hidden="onHidden">
            <template #modal-header="{ close }" class="container">
                <div class="modal-title">
                    <slot name="CommonModalTitle"></slot>
                </div>
                <button v-if="closeBtn" type="button" aria-label="Close" class="close text-uppercase small" @click="close()">{{ $t('close') }}</button>
            </template>
            <template #default>
                <slot name="CommonModalContent"></slot>
            </template>
            <template #modal-footer>
                <slot name="CommonModalFooter"></slot>
            </template>
        </b-modal>
    </div>
</template>
<script>
    export default {
        name: 'CommonModal',
        props: {
            id: {
                default: 'common-modal',
                type: String
            },
            size: {
                default: null
            },
            footer: {
                type: Boolean,
                default: false
            },
            onHideOnlyX: {
                type: Boolean,
                default: false
            },
            is_static: {
                type: Boolean,
                default: true,
            },
            closeBtn: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            onHide(event) {
                if (!this.onHideOnlyX) {
                    this.$emit('bHideModal', this.id)
                }
                if (event.trigger === 'backdrop' && this.onHideOnlyX == true) {
                    event.preventDefault();
                    this.$emit('bHideModalBackDrop', this.id);
                }
                if (event.trigger === 'headerclose') {
                    this.$emit('bHideModalHeaderClose', this.id);
                }
            },
            onHidden(){
              this.$emit('onHidden');
            }
        }
    }
</script>
